import React from 'react'
import Slider from '../slider/Slider'
import { Grid } from '@material-ui/core';
import './Home.css';
import ButtonAccess from '../buttons/ButtonAccess';
import { Redirect } from 'react-router';
import ButtonSearch from '../buttons/ButtonSearch';
import ConstantText from '../../config/ConstantText';

const Home = () => {

    const [login, setLogin] = React.useState(false);

    const goLogin = () =>{
        setLogin(true);
    }
    if(login){
        return <Redirect to="/login" />
    }
    return (
        <div >
            <Grid container justify="center" alignItems="center" spacing={1}>
                <Grid item xs={12} style={{textAlign: "left"}}> 
                    <Grid container>

                        {/**logo VISTA DESKTOP*/}
                        <Grid item md={6} xs={12} className="view-desktop" style={{textAlign: "center"}}>
                            <img 
                                src={process.env.PUBLIC_URL + '/images/logo_SD_new.png'} 
                                className="widthLogoStoDomHome"
                                alt="login"
                            />
                        </Grid>

                        {/** RRSS */}
                        <Grid item md={3} xs={6} id="iconsGrid" >
                            
                            {/**<div>
                                <a href={ConstantText.LINK_HOME_WHATSAPP}  target="_blank">
                                    <img 
                                        src={process.env.PUBLIC_URL + '/images/i_ico_whatsap.png'} 
                                        className="widthLogoIcons"
                                        alt="login"
                                    />
                                </a>
                                <a href={ConstantText.LINK_HOME_YOUTUBE} target="_blank">
                                    <img 
                                        src={process.env.PUBLIC_URL + '/images/i_ico_youtube.png'} 
                                        className="widthLogoIcons"
                                        alt="login"
                                        style={{marginLeft:5,marginRight:5}}
                                    />
                                </a>
                                <a href={ConstantText.LINK_HOME_FACEBOOK} target="_blank">
                                    <img 
                                        src={process.env.PUBLIC_URL + '/images/i_ico_facebook.png'} 
                                        className="widthLogoIcons"
                                        alt="login"
                                    />
                                </a>
                            </div>*/}
                            
                        </Grid>

                        {/**logo VISTA RESPONSIVE*/}
                        <Grid item md={6} xs={12} className="view-responsive" style={{textAlign: "center"}}>
                            <img 
                                src={process.env.PUBLIC_URL + '/images/logo_SD_new.png'} 
                                className="widthLogoStoDomHome"
                                alt="login"
                            />
                        </Grid>

                        {/**btn ACCEDER */}
                        <Grid item md={3} xs={6} className="buttonHomeAccess styleAlignResponsive">
                            <ButtonSearch
                                id={'idBtnAccess'}
                                name={'Acceder'}
                                handleClick={goLogin}                            
                            />
                        </Grid>
                
                    </Grid>                          
                    
                </Grid>
                <Grid item xs={12} id="gridSlider">
                    <div style={{height:'80%'}}> 
                        <Slider />
                    </div>
                </Grid>
                <Grid item xs={12} style={{marginLeft:15}}>
                    <Grid container>
                        <Grid item md={12} style={{textAlign: "left"}}>
                            <h6 style={{color:'#1A1A1A',marginBottom:0, fontFamily:'Montserrat-SemiBold'}}>Contáctanos a:</h6>
                        </Grid>
                        <br/>
                        <Grid item md={8} xs={12}>
                            <Grid container >
                                {/* <Grid item  xs={2} style={{textAlign: "left"}}>
                                    <p style={{color:'#808080',marginBottom:0, fontFamily:'Montserrat-Regular'}}>Telefono:</p>
                                </Grid>
                                <Grid item  xs={10} style={{textAlign: "left"}}>
                                    <p style={{color:'#808080',marginBottom:0, fontFamily:'Montserrat-Regular'}}>01 402 2668 / +51 962 705 732</p>
                                </Grid> */}
                                <Grid item  xs={2} style={{textAlign: "left"}}>                                
                                    <p style={{color:'#808080',marginBottom:0, fontFamily:'Montserrat-Regular'}}>Email:</p>
                                </Grid>
                                <Grid item  xs={10} style={{textAlign: "left"}}>
                                    <a href="mailto:plataformadigitalbh@gmail.com" style={{color:'#808080',marginBottom:0, fontFamily:'Montserrat-Regular'}} target="_blank">plataformadigitalbh@gmail.com</a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12} >
                            {/* <p style={{color:'#666666', fontFamily:'Montserrat-Medium'}} className="txtGroupEditorial">©Grupo Editorial Santo Domingo</p> */}
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </div>
    )
}

export default Home
