import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './Button.css';
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));
const ButtonDesign = (props) => {
  const classes = useStyles();

  return (    
        <Button
            //id='btnSearch'
            variant="contained" 
            onClick={props.handleClick}
            className={classes.button + " colorButtonDesign letterCapitalize"}
            style={{top:-29,cursor:'unset'}}
        >
        {props.name}
      </Button>
  );
}

export default ButtonDesign
