import React from 'react';
//import { withStyles } from '@material-ui/core/styles';
//import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './Inputs.css';
//import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@material-ui/icons/CheckBox';
//import Favorite from '@material-ui/icons/Favorite';
//import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

/*
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);*/

export default function CheckCustom(props) {
  //const [stateCheck, setStateCheck] = React.useState(false);
  /*
  const handleChange = (event) => {
    setStateCheck(!stateCheck)
  };*/

  return (
    <FormGroup row className={'containerCenter checkcustomTerms checkResponsiveMargin'}>      
      <FormControlLabel
        control={
          <Checkbox
            checked={props.stateCheck}
            onChange={props.handleChange}
            name="checkedB"
            color="primary"
          />
        }
        label={props.name}
        style={{textAlign:'left', width:'100%', paddingLeft:10, marginRight:'unset'}}
      />      
    </FormGroup>
  );
}
