import React from 'react';
import { Grid } from '@material-ui/core';
import ButtonSearch from '../buttons/ButtonSearch';
import InputSearch from '../inputs/InputSearch'
import Book from './Book';
//import { Redirect } from 'react-router';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import UrlBase from '../../config/UrlBase';
import KeyValue from '../../apis/KeyValue';
import Endpoint from '../../apis/Endpoint';
import Token from '../../config/Token';
import ActivateCodeBookDialog from '../dialogs/ActivateCodeBookDialog';
import ValidateNroBookDialog from '../dialogs/ValidateNroBookDialog';
import MessageWelcomeDialog from '../dialogs/MessageWelcomeDialog';
import { makeStyles } from '@material-ui/core/styles';
import ButtonAdd from '../buttons/ButtonAdd';
import Divider from '@material-ui/core/Divider';
import BookDetail from './BookDetail';
import {ToolbarContext} from '../../context/ToolbarProvider';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';
import ButtonSearchBook from '../buttons/ButtonSearchBook';
import UrlBase_Prod from '../../config/UrlBase_Prod'
import PromotionDialogs from '../dialogs/PromotionDialog';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    heightListBook:{
        height: `calc(100% - ${149}px)`,
        width: '94%',
        position: 'fixed'
    },
}));

const MyBooks = (props) => {
    const classes = useStyles();

    const [detailBook, setDetailBook] = React.useState(false);
    const [codeBook, setCodeBook] = React.useState('');
    const [nameBook, setNameBook] = React.useState('');
    const [arrListBooks, setArrListBooks] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState(0);
    const [messageWelcome, setMessageWelcome] = React.useState('');
    //----loader----
    const [loading, setLoading] = React.useState(false);
    //---message error-----
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);    
    //---------
    //----- popup validate nro book ------
    const [showListBooks, setShowListBooks] = React.useState(false)
    const [showValidate,setShowValidate] = React.useState(false);
    const [showActivate,setShowActivate] = React.useState(false);
      //------------popup welcome message ------------------------
    const [showWelcomeMessage, setShowWelcomeMessage] = React.useState(false)
    //-----
    const [flagError,setFlagError] = React.useState(false);
    const [messageErrorActiveValid, setMessageErrorActiveValid] = React.useState('')

    //---------------- context-----
    const {flagCode,handleReturnFlagCode} = React.useContext(ToolbarContext);
    //------------------------flag vencimiento de token------------------------------
    const [showUnauthorized, setShowUnauthorized] = React.useState(false);
    //-------------------FLAG SHOW PROMOCION O SORTEO -----------
    const [showPromotion, setShowPromotion] = React.useState(false);
    const [promotion, setPromotion] = React.useState(null);

    const handleCloseValidate = () => {
        setShowValidate(false);
        setInitOptions(1);
        setShowActivate(true);
    }
    const handleCloseActivate = () => {
        setInitOptions(0);
        setShowActivate(false);
        setShowListBooks(true);
        GetListBooks(' ')
    }
    //------------
    const handleClose = () => {
        setShow(false)       
    };
    const ShowDialogMessage = (message) =>{
        setShow(true);
        setMessageError(message);
    }
    //----------- impl method async ------------------------------------
    const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
        //const URL_BASE = UrlBase.URL_BASE_PORTAL_SERVICE;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_PORTAL_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_PORTAL_SERVICE;
        }
        const tokenLocalStorage = localStorage.getItem(Token.TOKEN)
        const paramsHeadersPost = {
        method:typeCall,
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        },
        body: JSON.stringify(obj)
        }
        const paramsHeadersGet = {
        method:typeCall,
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        }
        }
        const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
        const URL = URL_BASE+methodAction;    
        setLoading(true);
        try{
            fetch(URL,header).then(function(res){
                if(res.status === 401){
                    return 401;
                  }
                return res.json();
            }).then(function(response){    
                setLoading(false);
                if(response === 401){
                    setShowUnauthorized(true);

                }else if(response.Code === 0){
                    SetConfigResponse(response,typeAction);                    

                }else if (response.Code === 1){
                    errorCode1(response);
            
                }else if (response.Code === 2){
                    errorCode2(response);        
                }
            }).catch((error) =>{
                setLoading(false);
                console.log(error);
            })
        }catch(e){
            setLoading(false);
            console.log(e);
        }                 
    }
    const SetConfigResponse = (response,typeAction)=>{
        if(typeAction === KeyValue.ACTION_GET_LIST_BOOKS){
            ResponseListBooks(response);
        } else if (typeAction === KeyValue.ACTION_GET_MESSAGE_WELCOME) {
            ResponseMessageWelcome(response);
        } else if (typeAction === KeyValue.ACTION_UPDATE_FLAG_WELCOME) {
            ResponseUpdateFlagMessage(response);
        } else if (typeAction === KeyValue.ACTION_GET_LOTTERY) {
            ResponseShowPromotion(response);
        }
    }
    const errorCode1 = (response) =>{
        var message = "";
        if(response.Data.StatusCode === 500){
            ShowDialogMessage(response.Data.Message);
        }
        else if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element}.`
            });
            ShowDialogMessage(message);                        
        }
    }
    const errorCode2 = (response) =>{
        var message = "";
        if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element.FieldName}. ${element.Message}. `
            });
            ShowDialogMessage(message);                        
        }
    }
    //------------------------------------------------------------------
    const eventListner = React.useCallback(() => {
        //console.log(state); // use the stateful variable in event listener
    }, [props.codeBookFromClassroom,props.param]);

    React.useEffect(() => {
        console.log('use effect my books: param');
        console.log(props.param)
        if(props.codeBookFromClassroom !== "0"){
            setCodeBook(props.codeBookFromClassroom)
            setDetailBook(true);
        }else if (props.param === 1){
            console.log("get message welcome 1")
            GetMessageWelcome();
        }else if (props.param === 11){
            console.log("get message welcome 11")
            GetMessageWelcome();
        }

    },[eventListner])
    //----------------------mensaje de bienvenida-----------------------
    const GetMessageWelcome = () =>{
        const methodAction = Endpoint.GET_MESSAGE_WELCOME;
        asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_MESSAGE_WELCOME);
    }
    const ResponseMessageWelcome = (response) =>{        
        if (response.Data.IsWelcome){
            setMessageWelcome(response.Data);
            setShowWelcomeMessage(true);
        }else{
            GetShowPromotion();                       
        }        
    }
    //--------------TRAER OFERTA PROMOCIONAL-------------------
    const GetShowPromotion = () => {
        const methodAction = Endpoint.GET_LOTTERY;
        asyncCallMethod("get",null,methodAction,KeyValue.ACTION_GET_LOTTERY);
    }
    const ResponseShowPromotion = (response) =>{
        if (localStorage.getItem(Token.TYPE_USER) === KeyValue.TypeStudent){
            if (response.Data.FlagShowPromotion){
                localStorage.setItem("flag-show-promotion",response.Data.FlagShowPromotion);
                localStorage.setItem("url-footer-promotion",response.Data.UrlFooterPromotion);
                setPromotion(response.Data)
                setShowPromotion(true);
            }else{
                GetListBooks(' '); 
            } 
        }else{
            GetListBooks(' '); 
        }
    }

    const handleCloseWelcomeMessage = () => {
        const methodAction = Endpoint.UPDATE_FLAG_WELCOME;
        asyncCallMethod('get',null,methodAction,KeyValue.ACTION_UPDATE_FLAG_WELCOME);        
    }
    const ResponseUpdateFlagMessage = (response) => {
        setShowWelcomeMessage(false)
        GetShowPromotion();
    }
    //----------------------- listar libros --------------------------------------
    const GetListBooks = (nameBook) => {        
        setArrListBooks([]);
        const methodAction = Endpoint.GET_LIST_BOOKS + nameBook;
        asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_LIST_BOOKS);
    }
    const ResponseListBooks = (response) => {    
        if(response.Data.length > 0){ 
            setArrListBooks(response.Data)
            setShowListBooks(true);
            setShowValidate(false);
        }else{
            setShowListBooks(false);
            setShowValidate(true);
        }        
    }
    //--------------HANDLE EVENTS --------------------
    const detailByBook = (code) =>{
        setCodeBook(code)
        localStorage.setItem(KeyValue.CODE_BOOK,code);
        localStorage.setItem('toolbar',2);
        setDetailBook(true)
    }
    const handleSearchBook =()=>{        
        GetListBooks(nameBook);               
    }
    const handleAddBook =()=>{
        setInitOptions(1);
        setShowActivate(true);        
    }
    const handleNameBook = (e) =>{
        setNameBook(e.target.value);
    }
    const handleReturn = () =>{
        handleReturnFlagCode(false);
        setDetailBook(false);
        if(arrListBooks.length === 0){
            GetMessageWelcome();
        }        
    }
    const handleOut = () =>{
        console.log('click')
        setShowValidate(false);
    }
    const handleClosePromotion = () =>{
        setShowPromotion(false)
        GetListBooks(' ');
    }
    //-------------------------------------------------------------------
    //console.log('initOptions')
    //console.log(initOptions)
    return (
        <Grid container >
            
            <Grid item md={8} xs={12}  className={detailBook ? 'hideDiv' : 'showDiv'}>
                <Grid container >
                    <Grid item xs={12} md={3}  className="button-add-responsive">
                        <ButtonAdd 
                            onClick = {handleAddBook}
                        />
                    </Grid>
                    
                    <Grid item xs={12} md={6} style={{marginTop:'auto',marginBottom:'auto'}} >
                        <InputSearch
                            //className={searchDniError? 'error-empty':''} 
                            label="Ingrese nombre del libro"
                            type={'text'}
                            //value={'searchDni'}
                            onChange={handleNameBook}  
                            //onKeyPress={handleKeyPressInputDNI}
                        />                                                                
                    </Grid>
                    
                    <Grid item xs={12} md={2}>
                        <ButtonSearchBook
                            id={'idBtnSearch22'}
                            name={'Buscar'}
                            style={{display:'inlineBlock !important'}}
                            handleClick = {handleSearchBook}
                        />  
                    </Grid>
                </Grid>
            </Grid>
            
            
            
            <Grid item xs={12} className={detailBook ? 'hideDiv' : 'showDiv'}>
                <div className={" height-containerBooks"} style={{overflowX:'hidden',overflowY:'auto'}}>
                    <Grid container>
                        <Grid item xs={12} className={(detailBook ? 'hideDiv' : 'showDiv') + " mtop-divider"}>
                            <Divider style={{height:'1.5pt'}}/>                                                             
                        </Grid>
                        {
                            arrListBooks.length > 0 ? (
                                arrListBooks.map(item =>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <h5 className="styleNameCollection" >{item.NombreColeccion}</h5>
                                            </Grid>
                                            {
                                                item.LibroColeccion.map(it =>
                                                    <Grid key={item.IdColeccion} item xs={6} md={2} >
                                                        <div style={{padding:10,cursor:'pointer'}} onClick={e => detailByBook(it.Code)}>
                                                            <Book detail={it}/>
                                                        </div>
                                                    </Grid>
                                                    )
                                            }
                                            <Grid item xs={12}>
                                                <Divider style={{height:'2pt'}}/>                                                             
                                            </Grid> 
                                        </Grid>
                                    </Grid>                                    
                                )
                            ) : ( '' )
                        }
                    </Grid>
                </div>
            </Grid>

            <Grid item xs={12} className={detailBook ? 'showDiv' : 'hideDiv'}>
                <BookDetail 
                handleReturn={handleReturn} 
                codeBook={codeBook}
                handleShowListBooksAgaint={props.handleShowListBooksAgaint}
                />
            </Grid>
            
            <ValidateNroBookDialog open={showValidate} handleClose={handleCloseValidate} handleOut={handleOut}/>
            <ActivateCodeBookDialog messageError={messageErrorActiveValid} flagError={flagError} open={showActivate} handleClose={handleCloseActivate} init={initOptions} />
            <MessageWelcomeDialog message={messageWelcome} open={showWelcomeMessage} handleClose={handleCloseWelcomeMessage} />
            
            <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
            <Progress open={loading} />
            <UnauthorizedDialog open={showUnauthorized} />
            <PromotionDialogs open={showPromotion} handleClose={handleClosePromotion} promotion={promotion} handleRedirectToProfile={props.handleRedirectToProfile}/>
        </Grid>
    )
}

export default MyBooks
