import React, {useRef} from 'react';
import WebViewer from '@pdftron/webviewer'
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import { Redirect } from 'react-router';
import UrlBase from '../config/UrlBase';
import Key from '../apis/Key';
import Endpoint from '../apis/Endpoint';
//import Token from '../config/Token';
import KeyValue from '../apis/KeyValue';
import Progress from '../components/progress/Progress';
import AlertDialog from '../components/dialogs/AlertDialog';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import './WebViewer.css';
import UnauthorizedDialog from '../components/dialogs/UnauthorizedDialog';

    const useStylesButton = makeStyles((theme) => ({
        button: {
        margin: theme.spacing(1),
        },
    }));

    const useStyles = makeStyles((theme) => ({
        root: {
        display: 'flex',
        },
        appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        },
    }));

    const StyledMenu = withStyles({
        paper: {
          border: '1px solid #d3d4d5',
        },
      })((props) => (
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          {...props}
        />
      ));
    
    const StyledMenuItem = withStyles((theme) => ({
        root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: theme.palette.common.white,
            },
        },
        },
    }))(MenuItem);

    //---- estilos para los menus flotantes -----------------
    const StyledMenuFloat = withStyles({
        paper: {
        border: '5px solid #3f51b5',
        width:180,
        minHeight:200,
        backgroundImage: `url(${process.env.PUBLIC_URL + "./images/fondo_audios.png"})`,
        backgroundRepeatX:'no-repeat',
        borderRadius: 30,
        },
    })((props) => (
        <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
        }}
        {...props}
        />
    ));
  
    const StyledMenuFloatVideo = withStyles({
        paper: {
        border: '5px solid #3f51b5',
        width:180,
        minHeight:200,
        backgroundImage: `url(${process.env.PUBLIC_URL + "./images/fondo_enlaces.png"})`,
        backgroundRepeatX:'no-repeat',
        borderRadius: 30,
        },
    })((props) => (
        <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
        }}
        {...props}
        />
    ));

    const StyledMenuItemFloat = withStyles((theme) => ({
        root: {
        '&:focus': {
            backgroundColor: 'unset',
    
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: 'black',
            borderBottom: '1px solid black',
            },
        },
        },
    }))(MenuItem);

const VisualizeContentType = (props) => {
    const classes = useStyles();
    const classesButton = useStylesButton();

    //const [anchorEl, setAnchorEl] = React.useState(null);
    const viewer = React.useRef(null);
    //const [returnView, setReturnView] = React.useState(false);
    const [showPopupAudio, setShowPopupAudio] = React.useState(false);
    const [showPopupVideo, setShowPopupVideo] =  React.useState(false);
    const [urlAudio, setUrlAudio] = React.useState("https://stodmg-files-dev.s3.us-east-2.amazonaws.com/AudioBook/hangouts_incoming_call.mp3")
    const [urlVideo, setUrlVideo] = React.useState('https://stodmg-files-dev.s3.us-east-2.amazonaws.com/VideoBook/Alight.mp4')    
    const audioRef = useRef();
    const videoRef = useRef();
    //const [returnBook, setReturnBook] = React.useState(false);
    //const [codeFile, setCodeFile] = React.useState('');
    const [instance, setInstance] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    //const [flag, setFlag] = React.useState(false);
    //--------------------menus flotantes -------------
    const [menuAudio, setMenuAudio] = React.useState(null);
    const [menuVideo, setMenuVideo] = React.useState(null);
    
    //----loader----
    const [loading, setLoading] = React.useState(false);
    //---message error-----
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);

    //------------------------flag vencimiento de token------------------------------
    const [showUnauthorized, setShowUnauthorized] = React.useState(false);
    //---------
    const handleClose = () => {
        setShow(false)       
    };
    const ShowDialogMessage = (message) =>{
        setShow(true);
        setMessageError(message);
    }
    //----------- impl method async ------------------------------------
    const asyncCallMethod = (typeCall,obj,methodAction,typeAction,url) =>{
    const URL_BASE = url;
    //const tokenLocalStorage = localStorage.getItem(Token.TOKEN);

    let headers = {};
    if (url === UrlBase.URL_BASE_SECURITY_SERVICE){
        headers = {
            'Accept':'application/json',
            //'Authorization': 'Bearer ' + tokenLocalStorage,
            'ApiKey': Key.API_KEY_SECURITY_SERVICE,
            'Content-type': 'application/json'
        }
    }else if (url === UrlBase.URL_BASE_FILES_SERVICE){
        headers = {
            'Accept':'application/json',
            //'Authorization': 'Bearer ' + tokenLocalStorage,
            //'ApiKey': Key.API_KEY_SECURITY_SERVICE,
            'Content-type': 'application/json'
        }
    }
    const paramsHeadersPost = {
        method:typeCall,
        mode: 'cors',
        headers: headers,
        body: JSON.stringify(obj)
    }    
    /*
    const paramsHeadersGet = {
        method:typeCall,
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            //'Authorization': 'Bearer ' + tokenLocalStorage,
            'ApiKey': Key.API_KEY_SECURITY_SERVICE,
            'Content-type': 'application/json'
        }
    }*/
    const header = paramsHeadersPost;//obj !== null ? paramsHeadersPost : paramsHeadersGet ;
    const URL = URL_BASE+methodAction;    
    setLoading(true);
        try{
            fetch(URL,header).then(function(res){
                if(res.status === 401){
                    return 401;
                  }
                return res.json();
            }).then(function(response){    
                setLoading(false);
                
                if(response === 401){
                    setShowUnauthorized(true);
                    
                }else if(response.Code === 0){
                    SetConfigResponse(response,typeAction);                    

                }else if (response.Code === 1){
                    errorCode1(response);
            
                }else if (response.Code === 2){
                    errorCode2(response);        
                }
            }).catch((error) =>{
            setLoading(false);
            console.log(error);
            })
        }catch(e){
        setLoading(false);
        console.log(e);
        }                 
    }
    const SetConfigResponse = (response,typeAction)=>{
        if(typeAction === KeyValue.ACTION_GET_RESOURCES_AUDIO_VIDEO){
            ResponseResourceAudioVideo(response);
        }
    }
    const errorCode1 = (response) =>{
        var message = "";
        if(response.Data.StatusCode === 500){
            ShowDialogMessage(response.Data.Message);
        }
        else if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = message + `${element}.`
            });
            ShowDialogMessage(message);                        
        }
    }
    const errorCode2 = (response) =>{
        var message = "";
        if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = message + `${element.FieldName}. ${element.Message}. `
            });
            ShowDialogMessage(message);                        
        }
    }
    //----------------INICIANDO VISTA ---------------------------
    /*
    const eventListner = React.useCallback(() => {
        //console.log(state); // use the stateful variable in event listener
    }, [props.pdf]); */

    React.useEffect(() => {
        WebViewer(
            {
                licenseKey: 'Company info::The PDFTron license info::Testing::More info::123',
                path: '/lib', 
                initialDoc: props.pdf,//'/files/NGS3-CON-AUDIO.pdf',//'http://18.191.148.38:81/api/File/GetBook',   
            },
            viewer.current,
            ).then((instance) => {
                setInstance(instance)
                instance.setTheme('dark');
                instance.setLanguage('es');
                instance.disableFeatures([instance.Feature.Download]);
                instance.disableFeatures([instance.Feature.Print]);
                instance.disableFeatures([instance.Feature.FilePicker]);
                instance.disableFeatures([instance.Feature.NotesPanel]);

                //instance.disableElements(['toolbarGroup-Shapes']);
                instance.disableElements(['toolbarGroup-Edit']);
                instance.disableElements(['toolbarGroup-Insert']);
                instance.disableElements([ 'highlightToolGroupButton', 'tool-group-button' ]);
                instance.disableElements([ 'leftPanel', 'viewControls' ]);
                
                //instance.disableElements(["viewControlsButton"]); //dentro está la opcion de rotar pagina
                instance.disableElements(["searchButton"]);
                instance.disableElements([ 'underlineToolGroupButton', 'tool-group-button' ]);
                instance.disableElements([ 'strikeoutToolGroupButton', 'tool-group-button' ]);
                instance.disableElements([ 'squigglyToolGroupButton', 'tool-group-button' ]);
                instance.disableElements([ 'toolsOverlay', 'CustomElement' ]);
                instance.disableElements(['menuButton','Button'])
                instance.disableElements(['leftPanelButton','Button']);
                instance.disableElements(['pageNavOverlay']); //ocultar navegacion de paginas

                //const { docViewer } = instance;                
                //console.log(docViewer)
                // you can now call WebViewer APIs here...
            }
        );
     }, [])

    React.useEffect(() => {
        if(instance && props.pdf) {
            instance.loadDocument(props.pdf)
            handleClosePopupAudio();
            
        }    
    }, [props.pdf,instance]);

    //----------------OBTENER LISTA DDE RECURSOS---------------------------------
    const GetResourceAudioVideo = () =>{
        const methodAction = Endpoint.GET_RESOURCES_AUDIO_VIDEO;
        asyncCallMethod('post',null,methodAction,KeyValue.ACTION_GET_RESOURCES_AUDIO_VIDEO,UrlBase.URL_BASE_SECURITY_SERVICE);
    }
    const ResponseResourceAudioVideo = (response) =>{
        
    }
    //----------- eventos popup audio y video ----------------------------
    const handleClosePopupAudio = () => {
        setShowPopupAudio(false);
        setUrlAudio('');
        audioRef.current.pause();
        
    }
    const handleClosePopupVideo = () => {
        setShowPopupVideo(false);
        setUrlVideo('');
        videoRef.current.pause();
        
    }
    //-----------------------boton recursos ----------------------- */
    const handleResource = (itemMenu) => {
        var a = document.getElementById("idEnlaceV");
        a.setAttribute('target', "_blank");

        if(validateUrl(itemMenu.UrlEnlace)){            
            a.setAttribute('href', itemMenu.UrlEnlace);            
        }else{
            a.setAttribute('href', "http://"+itemMenu.UrlEnlace);
        }
        a.click();
        handleClose2();
        //setUrlAudio(itemMenu.UrlRecurso);
        //setShowPopupAudio(true)
    }
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };
    //--------------------botones flotantes audio y video ------------*//
    const handleClickMenuAudio = (event) => {
        setMenuAudio(event.currentTarget);
    };
    const handleCloseMenuAudio = () => {
        setMenuAudio(null);
    };
    const handleClickMenuVideo = (event) => {
        setMenuVideo(event.currentTarget);
    };
    const handleCloseMenuVideo = () => {
        setMenuVideo(null);
    };    
    /** evento click en recursos, validacion si es mp3 */
    const handleMenuItemAudio = (obj) =>{
        var last = obj.UrlRecurso.split(".").pop();
        //console.log(obj.UrlRecurso);        
        //console.log(last)
        if(last.toString() === "mp3"){
            var audio = document.getElementById("audioContenidoId");
            setUrlAudio(obj.UrlRecurso.toString())
            setShowPopupAudio(true);
            audio.play();
            handleCloseMenuAudio();

        }else{
            ShowDialogMessage('No tiene formato mp3 de audio.');
        }
    }
    const handleClickMenuItemContenido = (obj) =>{
        var a = document.getElementById("idEnlaceV");
        a.setAttribute('target', "_blank");

        if(validateUrl(obj.UrlEnlace)){            
            a.setAttribute('href', obj.UrlEnlace);            
        }else{
            a.setAttribute('href', "http://"+obj.UrlEnlace);
        }
        a.click();
        //handleCloseMenuVideo();
    }
    const validateUrl = (str) =>{
        var tarea = str;
        if (tarea.indexOf("http://") == 0 || tarea.indexOf("https://") == 0) {
            return true;
        }
        return false;
    }
    //--------------------------RENDERS---------------------------------------------------
    
    return (
        <div className={classes.root + " body2"}>

            <Grid container> 

                {/** header */}
                <Grid item xs={12}>
                    <Grid container >

                        {/**boton atras( solo aparece en el responsive) */}
                        <Grid item xs={12} className="back-button-visor-pdf">
                            {/**boton volver a vista anterior */}
                            <div className="style-back-button">
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={props.handleReturn}
                                    label="hola"
                                    //color="inherit"
                                    style={{padding:0}}
                                >             
                                    <ArrowBackIcon style={{color:'black'}} />                                
                                </IconButton>
                                <p 
                                    id="pback"
                                    style={{cursor:'pointer',marginRight:10}}
                                    onClick={props.handleReturn}
                                >
                                    Volver
                                </p>  
                            </div>
                        </Grid>
                        
                        {/**logo */}
                        <Grid item xs={4} style={{textAlign:'left'}} className="style-logo-visor-pdf">
                            <img 
                                src={process.env.PUBLIC_URL + '/images/logo_SD_new.png'} 
                                className={"widthLogoStoDomingo-visor"}
                                alt="login"
                                style={{marginLeft:10,cursor:'pointer'}}
                                onClick={props.handleShowListBooksAgaint}
                                />
                        </Grid>
                        
                        {/** boton de nivel de grado */}
                        {
                            props.hideMaterialDocente ? (
                                <Grid item md={4} xs={12} style={{textAlign:'center',marginTop:'auto',marginBottom:'auto'}}>
                                    <div>
                                        <Button
                                            variant="contained" 
                                            onClick={props.handleClick}
                                            className={classesButton.button + " colorButtonDesign letterCapitalize"}                                
                                        >
                                            {props.degreeCourse}
                                        </Button>
                                    </div>                            
                                </Grid>
                        
                            ): (
                                <Grid item md={4} xs={7} style={{textAlign:'center',marginTop:'auto',marginBottom:'auto'}}>
                                    <div>
                                        <Button
                                            variant="contained" 
                                            onClick={props.handleClick}
                                            className={classesButton.button + " colorButtonDesign letterCapitalize"}                                
                                        >
                                            {props.degreeCourse}
                                        </Button>
                                    </div>                            
                                </Grid>                        
                            )
                        }
                        
                        {/**boton de recursos */}
                        <Grid item md={4} xs={5} style={{marginTop:'auto',marginBottom:'auto'}}>                            
                            
                            {/**boton recursos */}
                            <div id="idmenu2" className={props.hideMaterialDocente ? 'hideDiv': 'showDiv'}  style={{float:'right',marginRight:10}}>
                                <Button
                                    aria-controls="customized-menu3"
                                    aria-haspopup="true"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClick2}
                                >
                                    Recursos
                                </Button>
                                <StyledMenu
                                    id="customized-menu3"
                                    anchorEl={anchorEl2}
                                    keepMounted
                                    open={Boolean(anchorEl2)}
                                    onClose={handleClose2}
                                >
                                    {
                                        props.listaMaterialDocente !== undefined ? (
                                            props.listaMaterialDocente !== null ? (
                                                props.listaMaterialDocente.map(item =>
                                                    <StyledMenuItem onClick={e=>handleResource(item)}>
                                                        <ListItemText primary={item.NombreContenido} />
                                                    </StyledMenuItem>
                                                )
                                            ):("")                                            
                                        ) : ("")
                                    }
                                </StyledMenu>
                            </div>            
                            
                            {/**boton volver a vista anterior */}
                            <div className="back-button-visor-pdf-2">
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={props.handleReturn}
                                    //color="inherit"
                                    style={{padding:0}}
                                >             
                                    <ArrowBackIcon style={{color:'black'}} />                                
                                </IconButton>
                                <p 
                                    id="pback"
                                    style={{cursor:'pointer',marginRight:30}}
                                    onClick={props.handleReturn}
                                >
                                    Volver
                                </p>  
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                {/** web viewer */}
                <Grid item xs={12}>
                    <div className="webviewer height-viewer " ref={viewer} /*style={{height: "100vh"}}*/></div>
                </Grid>
            
            </Grid>

            {/** popup audio */}
            <div 
                id="spopup" 
                style={{background:'rgb(223 232 241 / 87%)',textAlign:'right'}}
                className={showPopupAudio ? 'showDiv' : 'hideDiv'}
                >
                <p style={{padding:0,margin:0,cursor:'pointer'}} onClick={() => handleClosePopupAudio()}>X</p>
                <audio
                    id="audioContenidoId"                    
                    src={urlAudio}
                    ref={audioRef}
                    controls="controls" 
                    controlsList="nodownload"
                    style={{paddingRight:25}}>
                </audio>
            </div>
            
            {/** popup video */}
            <div
                id="spopupV"
                style={{background:'rgb(223 232 241 / 87%)',textAlign:'center'}}
                className={showPopupVideo ? 'showDiv' : 'hideDiv'}
            >
                <p style={{padding:0,margin:0,cursor:'pointer'}} onClick={() => handleClosePopupVideo()}>X</p>
                <video
                    src={urlVideo}  
                    ref={videoRef}                  
                    width="320" height="240" controls="controls" >
                    
                </video>
            </div>
            
            {/** boton flotante audio (lista recursos) */}
            <div id="idmenuAudio" className={props.hideRecursos ? 'hideDiv': 'showDiv'}>
                <div>
                    <Button
                        aria-controls="customized-menu1"
                        aria-haspopup="true"
                        variant="contained"
                        onClick={handleClickMenuAudio}
                        endIcon = {<img
                                        src={process.env.PUBLIC_URL +"/images/ico_button_float_audio.png"}
                                        alt="audio"
                                        style={{width:'78%',cursor:'pointer'}}
                                    />}
                        style={{padding:0}}
                    >
                    </Button>
                    <StyledMenuFloat
                        id="customized-menu1"
                        anchorEl={menuAudio}
                        keepMounted
                        open={Boolean(menuAudio)}
                        onClose={handleCloseMenuAudio}
                    >
                        {
                            props.listaRecursos !== null ? (
                                props.listaRecursos !== undefined ?  (
                                    props.listaRecursos.length > 0 ? (
                                        props.listaRecursos.map(item => 
                                            <StyledMenuItemFloat onClick={()=>handleMenuItemAudio(item)}>
                                                <ListItemText primary={item.NombreRecurso} />
                                            </StyledMenuItemFloat>)
                                    ):('')
                                ):('')
                            ):('')                            
                        }
                        
                    </StyledMenuFloat>                    
                </div>
            </div>
            
            {/** boton flotante video (lista enlaces) */}
            <div id="idmenuVideo" className={props.hideContenido ? 'hideDiv': 'showDiv'}>
                <div>
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        onClick={handleClickMenuVideo}
                        endIcon={<img
                            src={process.env.PUBLIC_URL +"/images/ico_button_float_video.png"}
                            alt="audio"
                            style={{width:'78%',cursor:'pointer'}}
                            />}
                        style={{padding:0}}
                    >
                    </Button>
                    <StyledMenuFloatVideo
                        id="customized-menu"
                        anchorEl={menuVideo}
                        keepMounted
                        open={Boolean(menuVideo)}
                        onClose={handleCloseMenuVideo}
                    >
                        {
                            props.listContenidos !== null ? (
                                props.listContenidos !== undefined ? (
                                    props.listContenidos.length > 0 ? (
                                        props.listContenidos.map(item =>
                                        <StyledMenuItemFloat onClick={e => handleClickMenuItemContenido(item)}>
                                            <ListItemText primary={item.NombreRecurso} />
                                        </StyledMenuItemFloat>
                                        )
                                    ):('')
                                ):('')
                            ):('')
                        }
                    </StyledMenuFloatVideo>
                    
                </div>                
            </div>
            <a href={'uriEnlace'} target="_blank" id="idEnlaceV" style={{display:'none'}} />
            <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
            <Progress open={loading} />
        </div>
    )
}

export default VisualizeContentType
