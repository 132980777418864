import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import './Inputs.css';

const TextFieldCustom = withStyles({
    root: {
      '& label': {
        color: '#808080',
        fontFamily:'Montserrat-Medium',
      },
      '& label.Mui-focused': {
        color: '#808080',
      },
      '& .MuiInput-underline': {
        borderBottomColor: '#808080',
        fontFamily:'Montserrat-Medium',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#808080',
        fontFamily:'Montserrat-Medium',
      }, 
      '& .MuiInput-underline:after': {  
        borderBottomColor: '#808080',
        fontFamily:'Montserrat-Medium',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#808080',
          borderRadius:18,
        },
        '&:hover fieldset': {
          borderColor: '#808080',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#808080',
        }
      },
    },
  })(TextField);

export default function InputText(props) {
  //const classes = useStyles();

  return (
        <TextFieldCustom
          error={props.flagError}
          id={props.label +"-standard-error-helper-text"}
          label={props.label}
          //defaultValue="Hello World"
          onChange={props.onChange}
          helperText={props.flagError ? props.helperText : ""}
          //variant="outlined"
          autoComplete="off"
          size="small"
          onInput = {(e) =>{
              e.target.value = e.target.value.toString().slice(0,props.maxLength)//Math.max(0, parseInt(e.target.value) ).toString().slice(0,8)//este codigo no permite poner ceros delante del numero
            }
          }
        />
  );
}
