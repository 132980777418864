import React from 'react'
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
import './Inputs.css';

const TextFieldCustom = withStyles({
    root: {
      '& label': {
        color: '#868686',
        fontFamily:'Montserrat-Medium',
      },
      '& label.Mui-focused': {
        color: '#868686',
      },
      '& .MuiInput-underline': {
        borderBottomColor: '#868686',
        fontFamily:'Montserrat-Medium',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#868686',
        fontFamily:'Montserrat-Medium',
      }, 
      '& .MuiInput-underline:after': {  
        borderBottomColor: '#868686',
        fontFamily:'Montserrat-Medium',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#868686',
          borderRadius:18,
        },
        '&:hover fieldset': {
          borderColor: '#868686',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#868686',
        }
      },
    },
  })(TextField);

const InputTextCustom = (props) => {
  return (
    <TextFieldCustom
      id='inputLogin'
      type={props.type}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      className={props.className + " widthCustom"} 
      autoComplete="off"
      //style={{marginLeft:30, marginRight: 30}}
    /> 
  );
}
export default InputTextCustom
