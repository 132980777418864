import React from 'react'

export const ToolbarContext = React.createContext();

const ToolbarProvider = (props) => {

    const toolbars = {
        hide: false
    }
    const flagCodes = {
        hide: false
    }
    const [toolbar, setToolbar] = React.useState(toolbars);
    const [flagCode, setFlagCode] = React.useState(flagCodes);

    const handleHideToolbar = (valor) =>{
        setToolbar({...toolbars,hide:valor});
    }
    const handleReturnFlagCode = (valor) => {
        setFlagCode({...flagCodes, hide: valor})
    }

    return (
        <ToolbarContext.Provider value={{toolbar,handleHideToolbar,flagCode,handleReturnFlagCode}}>
            {props.children}
        </ToolbarContext.Provider>
    )
}

export default ToolbarProvider
