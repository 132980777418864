import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
//import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './Inputs.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
}));

export default function InputPassword(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    console.log(prop);
    console.log(event.target.value)
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
        <div className="changeTypeTextPassword" style={{width:'100%'}}>
            <FormControl className={props.className} /*className={clsx(classes.margin, classes.textField)}*/>
              <InputLabel id="labelname"  htmlFor={props.id + "-standard-adornment-password"} className={props.passwordError ? 'txtColorError': ''}>{props.label}</InputLabel>
              <Input
                  id={props.id + "-standard-adornment-password"}
                  type={values.showPassword ? 'text' : 'password'}
                  value={props.value}//{values.password}
                  onChange={props.onChange}//{handleChange('password')}
                  error ={props.passwordError}                  
                  endAdornment={
                  <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {
                          values.showPassword ? <Visibility /> : <VisibilityOff />
                        }
                      </IconButton>
                  </InputAdornment>
                  }
              />
              <p  
                className={(props.passwordError? "showDiv txtColorError" : "hideDiv")+" MuiFormHelperText-root Mui-error MuiFormHelperText-marginDense"} 
                
                id="Usuario-standard-error-helper-text-helper-text"
              >
                {
                  props.passwordHelperText
                }
              </p>
            </FormControl>
        </div>
       </div>
  );
}
