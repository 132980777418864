import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
import './Inputs.css';

const TextFieldCustom = withStyles({
  root: {
      '& label': {
          color: '#212222',
      },
      '& label.Mui-focused': {
          color: '#212222',
      },
      '& .MuiInput-underline': {
          borderColor: 'rgba(0, 0, 0, 0.12)',
          fontFamily:'Montserrat-Medium !important',
      },
      '& .MuiInput-underline:before': {
          borderColor: 'rgba(0, 0, 0, 0.12)',
          fontFamily:'Montserrat-Medium !important',
      },
      '& .MuiInput-underline:after': {
          borderColor: '#rgba(0, 0, 0, 0.12)',
          fontFamily:'Montserrat-Medium !important',
      },
      '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
            borderRadius:18,
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.12)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.12)',
      }
    },
  },
})(TextField);

export default function InputTextArea(props) {

    return (          
        <TextFieldCustom
          //id=""
          type={props.type}
          placeholder={props.placeholder}
          multiline
          rows={4}
          //inputProps={{ maxLength: 2 }}
          value={props.value}
          onChange={props.onChange}
          //onKeyPress={props.onKeyPress}
          className={(props.className + " widthCustom2 textNotification")}
          helperText={props.flagError ? props.helperText : ""}
          error={props.flagError}
          variant="outlined"
          autoComplete="off"
          size="small"
        />        
    );
}