import React from 'react';
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
//import ButtonSearch from '../buttons/ButtonSearch';
//import Token from '../../config/Token';
//import KeyValue from '../../apis/KeyValue';
//import ConstantText from '../../config/ConstantText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function StreamingCallDialog(props) {
  //const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  /*
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleActive = () =>{
    console.log('active')
  }
  */

  return (
    <Dialog
      open={props.open} 
      //fullScreen={fullScreen}
      onClose={props.handleClose} 
      aria-labelledby="form-dialog-titleeee"
      //style={{background:'#313132'}}
      >
      <DialogTitle 
        id="idDialogWelcomessss"
        style={{textAlign:'center'}}        
      >
        Videoconferencia
      </DialogTitle>
      <DialogContent >
        <Grid container >
            <Grid item xs={12} className="itemRedStreaming"  >
              <p style={{fontFamily:'Montserrat-Regular'}}>Seleccione con qué plataforma desea programar o iniciar videoconferencia</p>
            </Grid >

            <Grid item xs={4} className="itemRedStreaming" q>
                <a href="https://meet.jit.si/" target="_blank">
                    <img src={process.env.PUBLIC_URL + "/images/logo-jitsi.png"} alt="gitsi" style={{width:'57%'}}/>   
                </a>               
            </Grid>

            <Grid item xs={4} className="itemRedStreaming"  >
                <a href="https://zoom.us/meeting/schedule" target="_blank">
                    <img src={process.env.PUBLIC_URL + "/images/logo-zoom.png"} alt="zoom" style={{width:'84%'}}/>
                </a>
                
            </Grid>
            <Grid item xs={4} className="itemRedStreaming" >
                <a href="https://meet.google.com/?hs=197&pli=1&authuser=0" target="_blank">
                    <img src={process.env.PUBLIC_URL + "/images/logo-meet.png"} alt="meet" style={{width:'100%'}}/>
                </a>
                
            </Grid>
            
        </Grid>
      </DialogContent>
    </Dialog>    
  );
}

