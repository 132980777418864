import React from 'react'
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import './Button.css';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ButtonEdit = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fab 
        color="primary" 
        aria-label="add"
        onClick = {props.onClick}
        className='changeWithHeight'
        style={{margin:0}}
      >
        <EditIcon />
      </Fab>
    </div>
  )
}

export default ButtonEdit