import React from 'react';
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import ButtonSearch from '../buttons/ButtonSearch';
import InputSearch from '../inputs/InputSearch';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import './Dialog.css';
import Endpoint from '../../apis/Endpoint';
import KeyValue from '../../apis/KeyValue';
import Token from '../../config/Token';
import UrlBase from '../../config/UrlBase';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import InformationIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import UrlBase_Prod from '../../config/UrlBase_Prod'
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';
import InputCodeValidate from '../inputs/InputCodeValidate';
import ButtonAddPopup from '../buttons/ButtonAddPopup';
import ButtonOutPopup from '../buttons/ButtonOutPopup';
/*
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));
*/
export default function ActivateCodeBookDialog(props) {

  //const classes = useStyles();
  //const [open, setOpen] = React.useState(false);
  const [codeActive, setCodeActive] = React.useState('');
  const [codeValid, setCodeValid] = React.useState('');
  const [codeValid_prefix, setCodeValid_prefix] = React.useState('');

  const [hideBlock_1, setHideBlock_1] = React.useState(false)//estado inicial: false
  const [hideBlock_2, setHideBlock_2] = React.useState(false)//estado inicial: false
  const [hideBlock_3, setHideBlock_3] = React.useState(true)//estado inicial: false
  const [hideBlock_4, setHideBlock_4] = React.useState(true)//estado inicial: false
  const [hideBlock_5, setHideBlock_5] = React.useState(false)//estado inicial: false
  const [flagError,setFlagError] = React.useState(false);
  const [messageErrorActiveValid, setMessageErrorActiveValid] = React.useState('')
  //------------------------flag vencimiento de token------------------------------
  const [showUnauthorized, setShowUnauthorized] = React.useState(false);
  //---------------------
  const [codeActiveError, setCodeActiveError] = React.useState(false);
  const [codeValidError, setCodeValidError] = React.useState(false);
  const [codeValidError_prefix, setCodeValidError_prefix] = React.useState(false);
  //----loader----
  const [loading, setLoading] = React.useState(false);
  //---message error-----
  const [show, setShow] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);    
  //------------------
  const handleCloseD = () => {
    setShow(false)       
  };
  const ShowDialogMessage = (message) =>{
      setShow(true);
      setMessageError(message);
  }
  //----------- impl method async ------------------------------------
  const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
      //const URL_BASE = UrlBase.URL_BASE_PORTAL_SERVICE;

      let URL_BASE  = "";
      if(process.env.NODE_ENV === "development"){
          URL_BASE  = UrlBase.URL_BASE_PORTAL_SERVICE;
      }
      if(process.env.NODE_ENV === "production"){
          URL_BASE  = UrlBase_Prod.URL_BASE_PORTAL_SERVICE;
      }

      const tokenLocalStorage = localStorage.getItem(Token.TOKEN)
      const paramsHeadersPost = {
      method:typeCall,
      mode: 'cors',
      headers: {
          'Accept':'application/json',
          'Authorization': 'Bearer ' + tokenLocalStorage,
          'Content-type': 'application/json'
      },
      body: JSON.stringify(obj)
      }
      const paramsHeadersGet = {
      method:typeCall,
      mode: 'cors',
      headers: {
          'Accept':'application/json',
          'Authorization': 'Bearer ' + tokenLocalStorage,
          'Content-type': 'application/json'
      }
      }
      const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
      const URL = URL_BASE+methodAction;    
      setLoading(true);
      try{
          fetch(URL,header).then(function(res){
            if(res.status === 401){
              return 401;
            }
              return res.json();
          }).then(function(response){    
              setLoading(false);       

              if(response === 401){
                setShowUnauthorized(true);
                
              }else if(response.Code === 0){
                  SetConfigResponse(response,typeAction);                    

              }else if (response.Code === 1){
                  errorCode1(response);
          
              }else if (response.Code === 2){
                  errorCode2(response);        
              }
          }).catch((error) =>{
              setLoading(false);
              console.log(error);
          })
      }catch(e){
          setLoading(false);
          console.log(e);
      }                 
  }
  const SetConfigResponse = (response,typeAction)=>{
    if(typeAction === KeyValue.ACTION_GET_CODE_ACTIVE){
      ResponseCodeActive(response);
    } else if (typeAction === KeyValue.ACTION_GET_CODE_VALIDATE){
      ResponseValidateCode(response);
    }
  }
  const errorCode1 = (response) =>{
      var message = "";
      if(response.Data.StatusCode === 500){
          ShowDialogMessage(response.Data.Message);
      }
      else if(response.Data.Errors.length > 0){
          response.Data.Errors.forEach(element => {
              //console.log(element);
              message = `${element}.`
          });
          ShowDialogMessage(message);                        
      }
  }
  const errorCode2 = (response) =>{
      var message = "";
      if(response.Data.Errors.length > 0){
          response.Data.Errors.forEach(element => {
              //console.log(element);
              message = `${element.FieldName}. ${element.Message}. `
          });
          ShowDialogMessage(message);                        
      }
  }
  //-----------------------------------------------------------------
  const eventListner = React.useCallback(() => {
    //console.log(state); // use the stateful variable in event listener
  }, [props.init]);

  React.useEffect(()=>{
    if(props.init === 1){
      initFields();
    }
  },[eventListner]);

  const initFields = () =>{
    setCodeActive('');
    setCodeValid('');
    setCodeValid_prefix('');
    
    setHideBlock_1(false)
    setHideBlock_2(false)
    setHideBlock_3(false)
    setHideBlock_4(false)
    setHideBlock_5(false)
    setFlagError(false);
    setCodeActiveError(false)
    setCodeValidError(false)
    setCodeValidError_prefix(false)
    setMessageErrorActiveValid('');
    
  }
  const handleNextValidateCode = () =>{
    setHideBlock_4(true)
    setHideBlock_2(false)
  }  

  const handleCloseAndOpenActive = () => {
    /*
    setCodeActive('')
    setCodeValid('')
    setHideBlock_1(false)
    setHideBlock_2(false)
    setHideBlock_3(false)
    setHideBlock_4(false)
    setHideBlock_5(false)*/
    initFields();
  }

  //----------------------- eventos on change-----------------------------
  const handleCodeActive = (e) =>{
    setCodeActive(e.target.value);
    setFlagError(false);
    setMessageErrorActiveValid('');
    if(e.target.value === ''){
      setCodeActiveError(true);
    }else{
      setCodeActiveError(false);
    }    
  }
  const handleCodeValid = (e) =>{
    let text = e.target.value;
    setCodeValid(text);
    setFlagError(false);
    setMessageErrorActiveValid('');
    if(text === ''){
      setCodeValidError(true);
    }else if (text.length < 3){
      setCodeValidError(true);
      setMessageErrorActiveValid(KeyValue.ERROR_CODE_VALID_SIZE_MINIMUM);
    }else{
      setMessageErrorActiveValid("");
      setCodeValidError(false);
    }
  }
  const handleCodeValid_prefix = (e) =>{
    let text = e.target.value;
    
    setFlagError(false);
    setMessageErrorActiveValid('');

    if(text === ''){
      setCodeValid_prefix('');
      setCodeValidError_prefix(true);
      setMessageErrorActiveValid(KeyValue.ERROR_EMPTY);

    }else if (text.length < 1){
      setCodeValid_prefix(text.toUpperCase());
      setCodeValidError_prefix(true);
      setMessageErrorActiveValid(KeyValue.ERROR_CODE_VALID_SIZE_MINIMUM);

    }else{
      setCodeValid_prefix(text.toUpperCase());
      setMessageErrorActiveValid("");
      setCodeValidError_prefix(false);

    }
  }
  //---------------------------activar codigos---------------------------
  const handleActive = () => {
    //validando el codigo de activación...
    var countEmpty = 0;
    if(codeActive === ""){
      setCodeActiveError(true);
      countEmpty++;
    }
    if(countEmpty === 0){
      setHideBlock_1(true)
      setHideBlock_3(true)
      GetActiveCode(codeActive);
    }
  }
  const GetActiveCode = (code) =>{
    const methodAction = Endpoint.GET_ACTIVE_CODE + code;
    asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_CODE_ACTIVE);
  }
  const ResponseCodeActive = (response) => {
    setHideBlock_3(false)

    if(response.Data.IsCorrect){
      if (response.Data.IsTeacher){
        //setHideBlock_1(true);
        //setHideBlock_4(false);
        setHideBlock_5(true);
      }else{
        //setHideBlock_1(true)
        setHideBlock_4(true)
      }      
    }else{
      setHideBlock_1(false)
      //setHideBlock_3(false)
      setFlagError(true);
      setMessageErrorActiveValid(response.Data.Message);
    }
  }
  //---------------------------validar codigos --------------------------
  const handleValidate = () =>{
    let countEmpty = 0;
    const lenght_code_valid = codeValid_prefix.length + codeValid.length;
    const join_code = codeValid_prefix + "-" + codeValid;    

    if(codeValid_prefix === ""){
      setCodeValidError_prefix(true);
      //setMessageErrorActiveValid(KeyValue.ERROR_EMPTY);
      countEmpty++;
    }

    console.log(codeActive.substr(0,1));
    {/*if (codeValid_prefix.substr(0,1) !== codeActive.substr(0,1)){
      setCodeValidError_prefix(true);
      countEmpty++;
    }*/}

    if(codeValid === ""){
      setCodeValidError(true);
      //setMessageErrorActiveValid(KeyValue.ERROR_EMPTY);
      countEmpty++;
    }
    
    if (lenght_code_valid < 5){
      setCodeValidError(true);
      setMessageErrorActiveValid(KeyValue.ERROR_CODE_VALID_SIZE_MINIMUM);
      countEmpty++;

    }

    if(countEmpty === 0){
      setHideBlock_4(false)
      setHideBlock_3(true)
      GetValidCode(join_code.toUpperCase());
    }
  }
  const GetValidCode = (code) =>{
    const methodAction = Endpoint.GET_VALIDATE_CODE + code + "&CodeActivation=" + codeActive;
    asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_CODE_VALIDATE);
  }
  const ResponseValidateCode = (response) => {
    if(response.Data.IsCorrect) {
      setHideBlock_3(false)
      setHideBlock_5(true)
    }else{
      setHideBlock_4(true)
      setHideBlock_3(false)
      setFlagError(true);
      setMessageErrorActiveValid(response.Data.Message);
    }
  }
  //---------------------------------------------------------------------
  //|console.log(codeActive)
  return (
    <div>
      <Dialog 
        open={props.open} 
        onClose={props.handleClose} 
        aria-labelledby="form-dialog-title">
        <DialogTitle 
          id="form-dialog-title"
          style={{textAlign:'center',fontFamily:'Montserrat-SemiBold',color:'#333333'}}
          //className={hideBlock_5? 'hideButton' : 'showButton'}
          >
            { 
              hideBlock_5 ? 'Libro ingresado' : 'Ingreso de libro'
            }          
        </DialogTitle>
        <DialogContent>

          {/** ingresando codigo de activación ** */} 
          <div className={(hideBlock_1? 'hideDiv' : 'showDiv')+" styleScreenPopup"}>
            <Grid container  /* style={{height:130,width:320}}*/>
            <Grid item xs={12} >
              <Grid container >
                <Grid item xs={12} style={{paddingLeft:5}} >
                  <p style={{fontFamily:'Montserrat-Regular'}}>
                    Código de Activación
                    <Tooltip 
                      title="Código ubicado en el parte posterior de tu tarjeta adquirida en el kit." 
                      placement="right"
                      style={{fontFamily:'Montserrat-Regular'}}
                    >
                      <InformationIcon style={{color:'#e67474'}}/>
                    </Tooltip >
                  </p>  
                </Grid>
                <Grid item md={8} xs={12} style={{marginTop:'auto',marginBottom:'auto'}} className={codeActiveError? 'error-empty' : ''}>
                  <InputSearch
                    label={'Ingrese código'}
                    value={codeActive}
                    onChange={handleCodeActive}
                    type={'text'}
                  />  
                                                
                </Grid>
                <Grid item md={4} xs={12} style={{textAlign:'center'}}>
                  <ButtonSearch
                    name={'Activar'}
                    style={{display:'inlineBlock !important'}}
                    handleClick={handleActive}
                  />  
                </Grid> 
                <Grid item md={8} xs={12} style={{textAlign:'center', marginTop:10}} className={flagError ? 'showDiv' : 'hideDiv'}>
                  <p style={{color: 'red'}}>{messageErrorActiveValid}</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          </div>

          {/**popup de validando */}       
          <div className={(hideBlock_3? 'showButton' : 'hideButton')+" styleScreenPopup"}>            
            <Grid container  /* style={{height:130,width:320}}*/>
              <Grid item xs={12} style={{textAlign:'center'}}>
                <CircularProgress />
              </Grid>
              <Grid item xs={12} style={{textAlign:'center'}}>
                <p>Validando código</p>              
              </Grid>
            </Grid> 
          </div>

          {/**ingresando codigo de validacion */}
          <div className={(hideBlock_4? 'showButton' : 'hideButton')+" styleScreenPopup"}>          
            <Grid container  /* style={{height:130,width:320}}*/>
              <Grid item xs={12} style={{paddingLeft:5}} >
                <p style={{fontFamily:'Montserrat-Regular'}}>
                  Código de Validación
                  <Tooltip 
                    title="Código ubicado en el lomo de tu libro de actividad." 
                    placement="right"
                    style={{fontFamily:'Montserrat-Regular'}}
                  >
                    <InformationIcon style={{color:'#e67474'}} />
                  </Tooltip>
                </p>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item md={3} xs={5} style={{marginTop:'auto',marginBottom:'auto',padding:5}} className={codeValidError_prefix? 'error-empty' : ''} >
                    <InputCodeValidate
                      label={'Prefijo'}
                      type={'text'}
                      value={codeValid_prefix}
                      onChange={handleCodeValid_prefix}
                      maxLength={3}
                    />
                  </Grid>
                  <Grid item md={5} xs={7} style={{marginTop:'auto',marginBottom:'auto',padding:5}} className={codeValidError? 'error-empty' : ''} >
                    <InputCodeValidate
                      label={'Código'}
                      type={'number'}
                      value={codeValid}
                      onChange={handleCodeValid}
                      maxLength={5}
                    />
                  </Grid>
                  <Grid item md={4} xs={12} style={{textAlign:'center'}}>
                    <ButtonSearch
                      name={'Agregar'}
                      handleClick={handleValidate}
                    />  
                  </Grid> 
                  <Grid item xs={8} style={{textAlign:'center', marginTop:10}} className={flagError ? 'showDiv' : 'hideDiv'}>
                    <p style={{color: 'red'}}>{messageErrorActiveValid}</p>
                  </Grid>
                </Grid>
              </Grid>              
            </Grid>
          </div>

          {/**mensaje de libro agregado correctamente */}
          <div className={(hideBlock_5? 'showButton' : 'hideButton')+" styleScreenPopup"}>
            <Grid container /*  style={{height:169,width:320}}*/>
              <Grid item xs={12} >
                <Grid container >
                  <Grid item xs={12} style={{textAlign:'center'}}>
                    <img
                      src={process.env.PUBLIC_URL + '/images/ico_check_activo-21.png'} 
                      className="widthLogoCheck"
                      alt="check" />
                    <p style={{marginBottom:0,fontFamily:'Montserrat-Regular'}}>Tu libro fue activado satisfactoriamente.</p>        
                    
                  </Grid>
                  <Grid item xs={6} >
                    <ButtonAddPopup 
                      onClick = {handleCloseAndOpenActive}
                    />                  
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonOutPopup
                      id={'idButtonOut'}
                      name={'Salir'}
                      handleClick={props.handleClose}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>        
          </div>
        
        </DialogContent>

      </Dialog>
      <UnauthorizedDialog open={showUnauthorized} />
      <AlertDialog open={show} message={messageError} handleClose={handleCloseD}/>
      <Progress open={loading} />
    </div>
  );
}

