import React from 'react'
import MailIcon from '@material-ui/icons/Mail';
import './Icon.css'; 
 
const IconMail = (props) => {
    return (
        <div>
            {
                props.isFocus ? (
                    <MailIcon id="idIconUser" />
                ):(
                    <MailIcon id="idIconUser2" />
                )
            }
            
        </div>
    )
}

export default IconMail
