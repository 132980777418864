import React from 'react';
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import ButtonSearch from '../buttons/ButtonSearch';
import Token from '../../config/Token';
import KeyValue from '../../apis/KeyValue';
import ConstantText from '../../config/ConstantText';

export default function MessageWelcomeDialog(props) {
  /*
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleActive = () =>{
    console.log('active')
  }
*/
  return (
    <Dialog 
      open={props.open} 
      //onClose={props.handleClose} 
      aria-labelledby="form-dialog-title">
      <DialogTitle 
        id="idDialogWelcome"        
      >
        {
          localStorage.getItem(Token.TYPE_USER) === KeyValue.TypeTeacher ? (
            `Bienvenido(a) docente ${localStorage.getItem(Token.GIVEN_NAME)}`
          ):(
            `Bienvenido(a) ${localStorage.getItem(Token.GIVEN_NAME)}`
          )
        }
      </DialogTitle>
      <DialogContent>
        <Grid container className={'heightDialogWelcome'}>
          <Grid item xs={12} style={{textAlign:'left'}}>            
              {
                localStorage.getItem(Token.TYPE_USER) === KeyValue.TypeTeacher ? (
                  <div>
                    <p className="justify">{ConstantText.MESSAGE_WELCOME_TEACHER_1}</p>
                    <p className="justify">{ConstantText.MESSAGE_WELCOME_TEACHER_2}</p>
                    <p className="justify">{ConstantText.MESSAGE_WELCOME_TEACHER_3}</p>
                    <p className="justify">{ConstantText.MESSAGE_WELCOME_TEACHER_4}</p>
                  </div>
                ) : (
                  <div>
                    <p className="justify">{ConstantText.MESSAGE_WELCOME_STUDENT_1}</p>
                    <p className="justify">{ConstantText.MESSAGE_WELCOME_STUDENT_2}</p>
                    <p className="justify">{ConstantText.MESSAGE_WELCOME_STUDENT_3}</p>
                    <p className="justify">{ConstantText.MESSAGE_WELCOME_STUDENT_4}</p>
                  </div>) 
              }
              {
                localStorage.getItem(Token.TYPE_USER) === KeyValue.TypeTeacher ? (
                  <h5 style={{fontFamily:"Montserrat-Semibold"}}>{ConstantText.TITLE_TO_ACCESS_BOOK_TEACHER}</h5>
                ) : (<h5 style={{fontFamily:"Montserrat-Semibold"}}>{ConstantText.TITLE_TO_ACCESS_BOOK_STUDENT}</h5>)
              }
              {
                localStorage.getItem(Token.TYPE_USER) === KeyValue.TypeTeacher ? (
                  <p className="justify">{ConstantText.DETAIL_ACCESS_BOOK_TEACHER}</p>
                ) : (<p className="justify">{ConstantText.DETAIL_ACCESS_BOOK_STUDENT}</p>)
              }            
          </Grid>
          <Grid item xs={12} className="centerElement" style={{marginTop:-19}}>
            <ButtonSearch
              name={'Aceptar'}
              handleClick={props.handleClose}
            /> 
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>    
  );
}