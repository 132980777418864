import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './Button.css';
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
})); 
const ButtonProfile = (props) => {
  const classes = useStyles();

    return (
        <label 
            className="custom-file-upload font-size-family style-width-responsive-button-continue-1"
            onClick={props.handleClick}                
            style={{cursor:'pointer'}}>
            {props.name}
        </label>
    );
}

export default ButtonProfile

