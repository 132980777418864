const KeyValue = {
    TypeStudent: 'AL',
    TypeTeacher: 'DO',
    //--------------
    ACTION_LOGOUT: 1,
    ACTION_GET_LIST_BOOKS: 2,
    ACTION_GET_LIST_CHAPTERS_BY_BOOK: 3,
    ACTION_GET_LIST_THEMES_BY_CHAPTER: 4,
    ACTION_GENERATE_KEY: 5,
    ACTION_GET_CONTENT_BOOK: 6,
    ACTION_GET_NOTIFICATIONS: 7,
    ACTION_GET_CODE_ACTIVE: 8,
    ACTION_GET_CODE_VALIDATE: 9,
    ACTION_GET_CLASSROOM: 10,
    ACTION_GET_CLASSROOM_BY_SECTION: 11,
    ACTION_GET_MESSAGE_WELCOME: 12,
    ACTION_INSERT_NOTIFICATION: 13,
    ACTION_UPDATE_FLAG_WELCOME: 14,
    ACTION_UPDATE_STUDENTS_SECTION: 15,
    ACTION_GET_ALL_SECTIONS: 16,
    ACTION_GET_RESOURCES_BY_THEMES: 21,
    ACTION_GET_RESOURCES_MATERIAL_TEACHER: 22,
    ACTION_GET_DATA_PROFILE: 24,
    ACTION_UPDATE_PROFILE: 25,
    ACTION_UPDATE_PASSWORD: 26,
    ACTION_REGISTER_PROGRESS: 99,
    ACTION_FORGET_PASSWORD: 98,
    ACTION_GET_LOMO_CODE: 99,
    ACTION_POST_LOMO_CODE: 100,
    ACTION_GET_LOTTERY: 101,
    //------------------------------
    CODE_BOOK: 'CODE-BOOK',
    CODE_CONTENT_TYPE: 'CODE-CONTENT-TYPE',
    CHOICE_PROFILE: 'CHOICE-PROFILE',
    //--------------------------------
    ACTION_LIST_SCHOOLS: 17,
    ACTION_LIST_DEPARTMENTS: 18,
    ACTION_LIST_PROVINCES: 19,
    ACTION_LIST_DISTRICTS: 20,
    ACTION_LIST_GRADE: 23,
    //----------nombre de errores---------------
    ERROR_EMPTY: 'Campo vacío.',
    ERROR_LENGTH: 'Debe contener minimo 8 números.',
    ERROR_FORMAT_EMAIL: 'Formato correcto: correo@prueba.com',
    ERROR_SELECT_EMPTY: 'Elija una opción.',
    ERROR_CHECK_TERMS: 'Debe aceptar Términos y Condiciones.',
    ERROR_PASSWORDS_NOT_EQUALS: 'Las contraseñas ingresadas no son iguales.',
    ERROR_PASSWORDS_EQUALS_PAST: 'La nueva contraseña es igual a la actual contraseña.',
    ERROR_PASSWORDS_NOT_MAYUS: "La contraseña debe contener al menos una mayúscula.",
    ERROR_PASSWORDS_NOT_MINUS: "La contraseña debe contener al menos una minúscula.",
    ERROR_PASSWORDS_NOT_NUMBERS: "La contraseña debe contener al menos un número.",
    ERROR_PASSWORDS_NOT_ALPHANUMERIC: "La contraseña debe contener al menos un caracter no alfanumérico",
    ERROR_PASSWORDS_NOT_LARGE: "La contraseña debe tener al menos 8 caracteres.",
    ERROR_CODE_VALID_SIZE_MINIMUM: "El código debe tener mínimo 5 caracteres.",
    //------------renders ----------------------
    RENDER_MY_BOOKS: 1,
    RENDER_MY_BOOKS_AGAINT: 11,
    RENDER_MY_CLASSROOMS: 2,
    RENDER_MY_CLASSROOM_BY_SECTION: 3,
    RENDER_MY_BOOK_DETAIL: 4,
    RENDER_MY_NOTIFICATIONS: 5,
    RENDER_EDIT_PERFIL: 55,
    //-------------NOMBRES DE ICONOS ACCION -------------------------------
    TITLE_SECCTION: 'Nueva Aula',
    TITLE_NOTIFICATION: 'Nueva Notificación',
    TITLE_SHOW_BOOK: 'Libro',
    TITLE_VIDEOCALL: 'Videoconferencia',
};

export default KeyValue