import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Redirect } from 'react-router-dom';
import Token from '../../config/Token';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UnauthorizedDialog(props) {

    const [returnLogin, setReturnLogin] = React.useState(false);

    const cleanCredentials = () =>{
        localStorage.setItem(Token.TOKEN, "");
        localStorage.setItem(Token.REFRESH_TOKEN,"");
        localStorage.setItem(Token.TYPE_USER ,"");
        localStorage.setItem(Token.NAME_USER,"");
        localStorage.setItem(Token.UNIQUE_NAME,"");
        localStorage.setItem(Token.IS_WELCOME,"");
        localStorage.setItem(Token.GIVEN_NAME,"");
        localStorage.clear();
    }

    const handleClose = () =>{
        cleanCredentials();
        setReturnLogin(true);
    }

    if(returnLogin){
        return <Redirect to="/login"/>
    }

    return (    
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            //onClose={props.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" style={{textAlign:"center"}}>
                {"¡Alerta!"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                {"Su sesión a caducado."}
            </DialogContentText>
            </DialogContent>
            <DialogActions>          
            <Button onClick={handleClose}  color="primary">
                Ok
            </Button>
            </DialogActions>
        </Dialog>
    );
}
