import React from 'react';
import KeyValue from '../../apis/KeyValue';
import './Select.css';

const SelectCustom = (props) => {
    //console.log('props')
    //console.log(props)
    return (
        <div className='row'>
            <div className='col-sm-12'>
                <div className={props.widthClass + ' containerCenter'}>
                    <label
                        style={{fontFamily:'Montserrat-Regular',color:'#1A1A1A'}}
                        className={ (props.flagError ? "colorError " : "")}
                    >{props.label}</label>
                </div>                            
            </div>
            <div className='col-sm-12'>
                <div className="form-group">                                
                    <select 
                        className={ (props.flagError ? "borderColorError " : " ")+" form-control " + props.widthClass + " containerCenter"}
                        onChange={props.onChange}
                        value={props.value}
                    >
                        <option style={{fontFamily:'Montserrat-Regular'}} key='24' value="0" >Seleccione...</option>
                        {
                            props.typeList === KeyValue.ACTION_LIST_SCHOOLS ? (
                                props.list.map(item =>
                                    <option style={{fontFamily:'Montserrat-Regular'}} key={item.ColegioId} value={item.ColegioId} >{item.NombreColegio}</option>
                                    )
                            ) : ('')
                        }
                        {
                            props.typeList === KeyValue.ACTION_LIST_GRADE ? (
                                props.list.map(item =>
                                    <option style={{fontFamily:'Montserrat-Regular'}} key={item.IdGrado} value={item.IdGrado} >{item.NombreGrado}</option>
                                    )
                            ) : ('')
                        }
                        {
                            props.typeList === KeyValue.ACTION_LIST_DEPARTMENTS ? (
                                
                                props.list.map(el =>
                                    <option style={{fontFamily:'Montserrat-Regular'}} key={el.DepartamentoCod} value={el.DepartamentoCod} >{el.NombreDepartamento}</option>
                                    )
                           ) : ('')
                        }
                        {
                            props.typeList === KeyValue.ACTION_LIST_PROVINCES ? (
                                props.list.map(el =>
                                    <option style={{fontFamily:'Montserrat-Regular'}} key={el.ProvinciaCod} value={el.ProvinciaCod} >{el.NombreProvincia}</option>
                                    )
                           ) : ('')
                        }
                        {
                            props.typeList === KeyValue.ACTION_LIST_DISTRICTS ? (
                                props.list.map(el =>
                                    <option style={{fontFamily:'Montserrat-Regular'}} key={el.DistritoCod} value={el.DistritoCod} >{el.NombreDistrito}</option>
                                    )
                           ) : ('')
                        }
                    </select>
                    <div className={(props.flagError ? 'showDiv': 'hideDiv') + " " + props.classNameError}>
                        <p className="selectColorError">{props.helperText}</p>
                    </div>
                </div>
            </div>                        
        </div> 
    )
}

export default SelectCustom
