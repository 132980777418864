import React from 'react'
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
import './Inputs.css';

const TextFieldCustom = withStyles({
    root: {
        '& label': {
            color: '#212222',
        },
        '& label.Mui-focused': {
            color: '#212222',
        },
        '& .MuiInput-underline': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
            fontFamily:'Montserrat-Medium !important',
        },
        '& .MuiInput-underline:before': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
            fontFamily:'Montserrat-Medium !important',
        },
        '& .MuiInput-underline:after': {
            borderColor: '#rgba(0, 0, 0, 0.12)',
            fontFamily:'Montserrat-Medium !important',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.12)',
              borderRadius:18,
        },
        '&:hover fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.12)',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.12)',
        }
      },
    },
})(TextField);

const InputTextOnlyBorder = (props) => {

  return (
    <div>
      <TextFieldCustom
        id="searchDNI"
        type={props.type}
        placeholder={props.label}
        //label={props.label}
        //rowsMax={1}
        //inputProps={{ maxLength: 2 }}
        error={props.error}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        //onKeyPress={props.onKeyPress}
        className={props.className + " widthCustom2"}
        variant="outlined"
        autoComplete="off"
        size="small"
        style={props.style}
        /*onKeyPress= {(e) =>{
          if(e.key === 'Enter'){
            {props.onKeyPress};
          }
        }}*/
        onInput = {(e) =>{
            e.target.value = e.target.value.toString().slice(0,props.maxLength)//Math.max(0, parseInt(e.target.value) ).toString().slice(0,8)//este codigo no permite poner ceros delante del numero
        }
    }
    />

      <div item xs={12} 
      className={(props.error? "showDiv txtColorError" : "hideDiv")+" MuiFormHelperText-root Mui-error MuiFormHelperText-marginDense"}
      style={{textAlign:'left'}}
      >
        <p style={{marginBottom:0,marginLeft:10}}>{props.messageHelperText}</p>
      </div>
    </div>
 
  );
}
export default InputTextOnlyBorder