import React from 'react';
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import ButtonSearch from '../buttons/ButtonSearch';
//import InputSearch from '../inputs/InputSearch';
import ButtonAdd from '../buttons/ButtonAdd';

export default function ValidateNroBookDialog(props) {
  const [open, setOpen] = React.useState(false);
  /*
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleActive = () =>{
    console.log('active')
  }
  */

  return (
    <div>
      <Dialog 
        id="dialogNroBooks" 
        open={props.open} 
        onClose={props.handleOut} 
        aria-labelledby="form-dialog-title"
      >
        
        <DialogContent>
          <Grid container style={{height:130,width:320}}>
            <Grid item xs={12} style={{textAlign:'center',marginTop:'auto',marginBottom:'auto'}}>
              <div>
                <p style={{marginBottom:0,color:'#333333',fontFamily:'Montserrat-Semibold',fontSize:'15pt'}}>Aún no cuenta con libros.</p>
              </div>              
            </Grid>
            <Grid item xs={6} style={{textAlign:'center',marginTop:25}}>
              <ButtonAdd 
                onClick = {props.handleClose}
              />
            </Grid>
            <Grid item xs={6} style={{textAlign:'center',marginTop:25}}>
              <ButtonSearch
                id={'idButtonOut'}
                name={'Salir'} 
                handleClick = {props.handleOut}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
            
        </DialogActions>
      </Dialog>
    </div>
  );
}