import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import './MyBooks.css';
import { Button, Grid, setRef } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
//import Typography from '@material-ui/core/Typography';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import UrlBase from '../../config/UrlBase';
import KeyValue from '../../apis/KeyValue';
import Endpoint from '../../apis/Endpoint';
import Token from '../../config/Token';
//import { Redirect } from 'react-router';
import Divider from '@material-ui/core/Divider';
import ButtonDesign from '../buttons/ButtonDesign';
import VisualizeContentType from '../../visualizeContentType/VisualizeContentType';
import Key from '../../apis/Key';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import {ToolbarContext} from '../../context/ToolbarProvider';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';
import UrlBase_Prod from '../../config/UrlBase_Prod'
//import { CodeSharp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  heightThemesBook:{
    height: `calc(100% - ${149}px)`,
    width: '80%',
    position: 'fixed'
  },
}));

export default function BookDetail(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState('1');
  const [arrListThemes, setArrListThemes] = React.useState([]);
  //const [codeFile, setCodeFile] = React.useState('')
  const [goViewContentType, setGoViewContentType] = React.useState(false);
  const [arrListChapters, setArrListChapters] = React.useState([]);
  const [nameTabTheme, setNameTabTheme] = React.useState('');
  const [degreeCourse, setDegreeCourse] = React.useState('');
  const [uriPDF, setUriPDF] = React.useState('');
  
  //const [temaId, setTemaId] = React.useState('');
  //------------------------flag vencimiento de token------------------------------
  const [showUnauthorized, setShowUnauthorized] = React.useState(false);

  /** flags para mostrar o no capitulos y temas por tipo de usuario */
  const [showChapter, setShowChapter] = React.useState(false);
  const [showThemes, setShowThemes] = React.useState(false);
  const [arrListChaptersStudent, setArrListChaptersStudent] =  React.useState([]);
  const [uriEnlace, setUriEnlace] = React.useState("https://google.com")
  //-------------------------------------------------------------------
  /** flags para recursos y contenidos */
  const [hideRecursos, setHideRecursos] = React.useState(true);
  const [hideContenido, setHideContenidos] = React.useState(true);
  const [hideMaterialTeacher,setHideMaterialTeacher] = React.useState(true);
  //-----------
  const [arrListResources, setArrListResources] = React.useState([]);
  const [arrListLinksVideoAudio, setArrLinksVideoAudio] = React.useState([]);
  const [arrListMaterialTeacher, setArrListMaterialTeacher] = React.useState([]);
  //-----------
  /** variable global con Context */
  const {toolbar,handleHideToolbar} = React.useContext(ToolbarContext);
  //----loader----
  const [loading, setLoading] = React.useState(false);
  //---message error-----
  const [show, setShow] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);  
  const gridRef = useRef();  
  //------------------
  //---------
  const handleClose = () => {
    setShow(false)       
  };
  const ShowDialogMessage = (message) =>{
      setShow(true);
      setMessageError(message);
  }
  //----------- impl method async ------------------------------------
  const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
      //const URL_BASE = UrlBase.URL_BASE_PORTAL_SERVICE;
      let URL_BASE  = "";
      if(process.env.NODE_ENV === "development"){
          URL_BASE  = UrlBase.URL_BASE_PORTAL_SERVICE;
      }
      if(process.env.NODE_ENV === "production"){
          URL_BASE  = UrlBase_Prod.URL_BASE_PORTAL_SERVICE;
      }

      const tokenLocalStorage = localStorage.getItem(Token.TOKEN)
      const paramsHeadersPost = {
      method:typeCall,
      mode: 'cors',
      headers: {
          'Accept':'application/json',
          'Authorization': 'Bearer ' + tokenLocalStorage,
          'Content-type': 'application/json'
      },
      body: JSON.stringify(obj)
      }
      const paramsHeadersGet = {
      method:typeCall,
      mode: 'cors',
      headers: {
          'Accept':'application/json',
          'Authorization': 'Bearer ' + tokenLocalStorage,
          'Content-type': 'application/json'
      }
      }
      const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
      const URL = URL_BASE+methodAction;    
      setLoading(true);
      try{
          fetch(URL,header).then(function(res){
            if(res.status === 401){
              return 401;
            }
              return res.json();
          }).then(function(response){    
              setLoading(false); 
              if(response === 401){
                setShowUnauthorized(true);

              }else if(response.Code === 0){
                SetConfigResponse(response,typeAction);                    

              }else if (response.Code === 1){
                errorCode1(response);
          
              }else if (response.Code === 2){
                errorCode2(response); 

              }else if (response.Code === 4){
                errorCode4(response);
              }
          }).catch((error) =>{
              setLoading(false);
              console.log(error);
          })
      }catch(e){
          setLoading(false);
          console.log(e);
      }                 
  }
  const asyncCallMethodGenerateKey = (typeCall,obj,methodAction,typeAction,url) =>{
    //const URL_BASE = url;
    let URL_BASE  = "";
    if(process.env.NODE_ENV === "development"){
        URL_BASE  = UrlBase.URL_BASE_SECURITY_SERVICE;
    }
    if(process.env.NODE_ENV === "production"){
        URL_BASE  = UrlBase_Prod.URL_BASE_SECURITY_SERVICE;
    }

    let headers = {
      'Accept':'application/json',
      //'Authorization': 'Bearer ' + tokenLocalStorage,
      'ApiKey': Key.API_KEY_SECURITY_SERVICE,
      'Content-type': 'application/json'
    };

    /*
    if (url === UrlBase.URL_BASE_SECURITY_SERVICE){
        headers = {
            'Accept':'application/json',
            //'Authorization': 'Bearer ' + tokenLocalStorage,
            'ApiKey': Key.API_KEY_SECURITY_SERVICE,
            'Content-type': 'application/json'
        }
    }*/
    /*
    else if (url === UrlBase.URL_BASE_FILES_SERVICE){
        headers = {
            'Accept':'application/json',
            'Content-type': 'application/json'
        }
    }*/
    const paramsHeadersPost = {
        method:typeCall,
        mode: 'cors',
        headers: headers,
        body: JSON.stringify(obj)
    }
    const header = paramsHeadersPost;
    const URL = URL_BASE+methodAction;    
    setLoading(true);
        try{
            fetch(URL,header).then(function(res){
              if(res.status === 401){
                return 401;
              }
                return res.json();
            }).then(function(response){    
            setLoading(false); 
            
              if(response === 401){
                setShowUnauthorized(true);
                
              }else if(response.Code === 0){
                SetConfigResponse(response,typeAction);                    

              }else if (response.Code === 1){
                  errorCode1(response);
          
              }else if (response.Code === 2){
                  errorCode2(response);        
              }
            }).catch((error) =>{
            setLoading(false);
            console.log(error);
            })
        }catch(e){
        setLoading(false);
        console.log(e);
        }                 
  }
  const SetConfigResponse = (response,typeAction)=>{
    if(typeAction === KeyValue.ACTION_GET_LIST_THEMES_BY_CHAPTER){
      ResponseListThemesByChapter(response);
    }else if(typeAction === KeyValue.ACTION_GET_LIST_CHAPTERS_BY_BOOK){
      ResponseListChaptersByBook(response);
    }else if(typeAction === KeyValue.ACTION_GENERATE_KEY){
      ResponseGenerateKey(response);
    }else if(typeAction === KeyValue.ACTION_GET_RESOURCES_BY_THEMES){
      ResponseGetResoursesByThemes(response);
    } else if (typeAction === KeyValue.ACTION_GET_RESOURCES_MATERIAL_TEACHER){
      ResponseMaterialTeacher(response);
    } else if (typeAction === KeyValue.ACTION_REGISTER_PROGRESS){
      ResponseRegisterProgress(response);
    }
  }
  const errorCode1 = (response) =>{
      var message = "";
      if(response.Data.StatusCode === 500){
          ShowDialogMessage(response.Data.Message);
      }
      else if(response.Data.Errors.length > 0){
          response.Data.Errors.forEach(element => {
              //console.log(element);
              message = `${element}.`
          });
          ShowDialogMessage(message);                        
      }
  }
  const errorCode2 = (response) =>{
      var message = "";
      if(response.Data.Errors.length > 0){
          response.Data.Errors.forEach(element => {
              //console.log(element);
              message = `${element.FieldName}. ${element.Message}. `
          });
          ShowDialogMessage(message);                        
      }
  }
  const errorCode4 = (response) => {
    ShowDialogMessage(`Status code: ${response.Data.StatusCode}. ${response.Data.Message}`);      
  }
  //-----------+-----------------------------------------------------
  const eventListner = React.useCallback(() => {
      //console.log(state); // use the stateful variable in event listener
  }, [props.codeBook]);

  React.useEffect(() => { 
    //console.log('use effect book detail')   
    //console.log('props.codeBook')
    //console.log(props.codeBook)
    if( props.codeBook !== undefined && props.codeBook !== null && props.codeBook!==""){
      GetChaptersByBook(props.codeBook);
    }
    /*
    const codeChapter = props.codeChapter;
    if(codeChapter !== undefined && codeChapter !== null && codeChapter !== ''){
      GetThemesByChapter(codeChapter)
    }*/
  },[eventListner])

  const GetChaptersByBook = (code) => {
    const methodAction = Endpoint.GET_LIST_CHAPTERS_BY_BOOK + code;
    asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_LIST_CHAPTERS_BY_BOOK);
  }
  const ResponseListChaptersByBook = (response) => {
    console.log(response.Data);
    setArrListChapters(response.Data);

    if(response.Data.length > 0){
      if(localStorage.getItem(Token.TYPE_USER) === KeyValue.TypeTeacher){
        setArrListChapters(response.Data)
        setStyleCardItemChapter(); 
      }
      if(localStorage.getItem(Token.TYPE_USER) === KeyValue.TypeStudent){
        const arrS = []
        response.Data.forEach(function(element){       
          if (element.SoloDocente){
            console.log('solo docente: true')            
          }else{
            console.log("solo docente: false")
            arrS.push(element);
          }
        })
        if (arrS.length > 0){
          setArrListChaptersStudent(arrS)
          setStyleCardItemChapter();          
        }
      }
      
      GetThemesByChapter(response.Data[0].Code)
      setNameTabTheme(response.Data[0].NombreCapitulo)
    }else{
      setShowChapter(false);
      setShowThemes(false);
      setArrListChapters([]);
      setArrListThemes([]);
      setNameTabTheme('');
      setDegreeCourse('');
    }
  }
  const setStyleCardItemChapter = () =>{
    var listItems = document.querySelectorAll('#idGrid div.MuiPaper-root');
    listItems[0].classList.add("my-bgColorSelect");
  }
  const GetThemesByChapter = (codeTheme) => {
    const methodAction = Endpoint.GET_LIST_THEMES_BY_CHAPTER + codeTheme;
    asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_LIST_THEMES_BY_CHAPTER);
  }
  const ResponseListThemesByChapter = (response) => {
    setDegreeCourse(response.Data.Titulo);
    setArrListThemes(response.Data.Result);
  }
  const visualizeContentType = (obj) => {
    RegisterProgress(obj.CodeTema);
    if (obj.Esenlace){
      //setUriEnlace(obj.UrlEnlace);
      var a = document.getElementById('idEnlace')
      a.setAttribute('target', "_blank");

      if(validateUrl(obj.UrlEnlace)){            
          a.setAttribute('href', obj.UrlEnlace);            
      }else{
          a.setAttribute('href', "http://"+obj.UrlEnlace);
      }
      a.click();

    }else {
      //setCodeFile(obj.CodeContenido);
      localStorage.setItem('code-contenido',obj.CodeContenido)
      //setTemaId(obj.IdTipoContenido);
      localStorage.setItem('code-tema',obj.CodeTema)
      const methodAction = Endpoint.GENERATE_KEY;
      asyncCallMethodGenerateKey('post',null,methodAction,KeyValue.ACTION_GENERATE_KEY,UrlBase.URL_BASE_SECURITY_SERVICE);    
    }    
  }
  const validateUrl = (str) =>{
    var tarea = str;
    if (tarea.indexOf("http://") == 0 || tarea.indexOf("https://") == 0) {
        return true;
    }
    return false;
  }
  /***REGISTRO DE PROGRESO***************** */
  const RegisterProgress = (codeTema) =>{
    const methodAction = Endpoint.REGISTER_PROGRESS;
    var obj = {
      TemaCode: codeTema
    };
    asyncCallMethod("post",obj,methodAction,KeyValue.ACTION_REGISTER_PROGRESS)
  }
  const ResponseRegisterProgress = (response) =>{
    if(response.Data){
      console.log("Avance progreso");
    }else{
      console.log("No se pudo registrar el progreso.")
    }
  }
  /**** */

  const ResponseGenerateKey = (response) =>{
    let urlbase = "";
    if(process.env.NODE_ENV === "development"){
      urlbase  = UrlBase.URL_BASE_FILES_SERVICE;
    }
    if(process.env.NODE_ENV === "production"){
      urlbase  = UrlBase_Prod.URL_BASE_FILES_SERVICE;
    }
    const pdf = urlbase + Endpoint.GET_CONTENT_BOOK + '?KeyAccess='+ response.Data + '&CodeFile=' + localStorage.getItem('code-contenido');
    //console.log(pdf);
    setUriPDF(pdf);
    GetResourceByThemes();
  }
  const GetResourceByThemes = () => {
    //localStorage.getItem('temaid');
    const methodAction = Endpoint.GET_RESOURCES_BY_THEMES + localStorage.getItem('code-tema');
    //console.log(methodAction); 
    asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_RESOURCES_BY_THEMES)
  }
  const ResponseGetResoursesByThemes = (response) =>{
    //console.log("recursos por tema")
    //console.log(response)
    if(response.Data.ListaRecursos === null || response.Data.ListaRecursos.length === 0){
      setArrListResources([]);
      setHideRecursos(true);
    } else{
      setHideRecursos(false);
      setArrListResources(response.Data.ListaRecursos);
    }

    if(response.Data.ListaEnlace === null || response.Data.ListaEnlace.length === 0 ){
      setArrLinksVideoAudio([]);
      setHideContenidos(true);
    }else {
      setHideContenidos(false);
      setArrLinksVideoAudio(response.Data.ListaEnlace);
    }

    GetMaterialTeacher();
  }
  const GetMaterialTeacher = () =>{
    const methodAction = Endpoint.GET_RESOURCES_MATERIAL_TEACHER  + props.codeBook;
    asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_RESOURCES_MATERIAL_TEACHER);
  }
  const ResponseMaterialTeacher = (response) =>{
    if(response.Data.length !== 0){
      setArrListMaterialTeacher(response.Data);
      setHideMaterialTeacher(false);
    }else{
      setArrListMaterialTeacher([]);
      setHideMaterialTeacher(true);
    }
    /**ocultar toolbar y renderizar visor pdf */
    handleHideToolbar(true);
    setGoViewContentType(true);
  }

  const handleReturnToBookDetail = () => {
    handleHideToolbar(false); 
    setGoViewContentType(false);
  }
  const changeChapter = (e, obj) =>{
    //console.log(obj);
    var listItems = document.querySelectorAll('#idGrid div.MuiPaper-root');
    listItems.forEach(function (item) {
      item.classList.remove("my-bgColorSelect");
    }); 
    var ele = e.target;
    ele.classList.add("my-bgColorSelect");

    setNameTabTheme(obj.NombreCapitulo);
    GetThemesByChapter(obj.Code);
  }
  //------------render --------------------------------
  //console.log('arrays chapters docente');
  //console.log(arrListChapters);
  //console.log('arrays chapters estudiante');
  //console.log(arrListChaptersStudent);
  return (
    <Grid container style={{overflow:'auto'}} >

      {/** BOTON DE ACCION: RETORNAR A VISTA ANTERIOR */}
      <Grid item xs={12} style={{marginBottom:10}} className={goViewContentType ? 'hideDiv' : 'showDiv'} >
        <div  
            style={{display:'flex',marginLeft:5,float:'left'}}
        >
          <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={props.handleReturn}
              //color="inherit"
              //style={{}}
          >             
              <ArrowBackIcon style={{color:'black'}} />                                
          </IconButton>
          <p 
              id="pback"
              style={{cursor:'pointer'}}
              onClick={props.handleReturn}
          >
              Volver
          </p>                    
        </div> 
      </Grid>

      <Grid item xs={12}>

        {/*** container de temas y capitulos */}
        <div  className={" heightContentThemsChapter"} style={{overflow:'auto'}}>

          {/** VISTA TEMAS */}
          <Grid container>          
            <Grid item md={3} xs={12}
              id="idGrid"               
              ref={gridRef} 
              className={(goViewContentType ? 'hideDiv' : 'showDiv') + " "}>
                <div className="height-themes" style={{overflow:'auto'}}>
                  <Grid container >
                    {
                      localStorage.getItem(Token.TYPE_USER) === (KeyValue.TypeStudent) ? (
                        arrListChaptersStudent.length > 0 ? (
                          arrListChaptersStudent.map(item =>
                              <Grid key={item.Code} item xs={12}>
                                <Card 
                                  className={classes.root + " cardChapter"} 
                                  onClick={(e) => changeChapter(e,item)}         
                                >
                                  {item.NombreCapitulo}                 
                                </Card>
                              </Grid> 
                            )
                        ):('')
                      ):(
                        arrListChapters.length > 0 ? (
                          arrListChapters.map(item =>                  
                            <Grid key={item.Code} item xs={12}>
                              <Card 
                                className={classes.root + " cardChapter"}
                                onClick={(e) => changeChapter(e,item)}         
                              >
                                {item.NombreCapitulo}                 
                              </Card>
                            </Grid> 
                            )
                        ):('')
                      )
                    }
                  </Grid>
                </div>
                
            </Grid>

            {/** VISTA CAPITULOS  **/}
            <Grid item md={8}  xs={12} className={goViewContentType ? 'hideDiv' : 'showDiv'}>
              
              <div className={classes.root} style={{borderRadius: 10}}>
                <TabContext value={value} style={{borderRadius: 25}}>
                  <AppBar position="static" style={{borderRadius: 25}}>
                    <TabList
                      id='idTabList'
                      //onChange={handleChange} 
                      aria-label="simple tabs example"
                      //style={{height:70}}
                      style={{borderRadius: 25}}
                      className={localStorage.getItem(Token.TYPE_USER) === KeyValue.TypeStudent ? 'cenefa-estudiante' : 'cenefa-docente'}
                    >
                      <Tab
                      id='tabMainId'
                      label={nameTabTheme} 
                      value="1"
                      />
                      
                    </TabList>
                  </AppBar>
                  <TabPanel value="1">
                      <Grid container >
                        <Grid item xs={12} style={{ marginBottom:0}}>
                            <Divider />
                            <ButtonDesign name={degreeCourse}/>                                                            
                        </Grid>
                        <Grid item xs={12} >
                          <div style={{overflow:'auto'}} className="height-chapter-responsive">
                            <Grid container >
                            {
                              arrListThemes.length > 0 ? (
                                arrListThemes.map(item =>                             
                                    <Grid key={item.IdTipoContenido} item md={9} xs={12} style={{paddingBottom:20}}>
                                      <Card /*className={classes.root}*/ style={{cursor:'pointer'}} variant="outlined" onClick={e => visualizeContentType(item)}>
                                        <CardContent style={{padding:10}} className="itemTemaCenefa">
                                          <Grid container>                            
                                            <Grid item xs={12} style={{marginTop:5,minHeight:23}}>
                                              <div>
                                                <h5 style={{textAlign:'left'}} className="styleItemChapter">
                                                  {item.NombreTema}
                                                </h5> 
                                              </div>

                                              
                                            </Grid>
                                          </Grid>                            
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                  )                
                                ): ('')
                            }
                          </Grid>
                          </div>
                          <a href={uriEnlace} target="_blank" id="idEnlace" style={{display:'none'}} />
                        </Grid>
                      </Grid>
                  </TabPanel>
                </TabContext>          
              </div>
            
            </Grid>
        
          </Grid>

        </div>
        
      </Grid>

      
      {/** COMPONENTE VISOR PDF */}
      <Grid item xs={12} className={goViewContentType ? 'showDiv' : 'hideDiv'}>
        <VisualizeContentType 
          handleReturn={handleReturnToBookDetail} 
          pdf={uriPDF} 
          listaRecursos={arrListResources}
          hideRecursos={hideRecursos}
          listContenidos={arrListLinksVideoAudio}
          hideContenido={hideContenido}
          listaMaterialDocente={arrListMaterialTeacher}
          hideMaterialDocente={hideMaterialTeacher}
          degreeCourse={degreeCourse}
          handleShowListBooksAgaint={props.handleShowListBooksAgaint}
          />
      </Grid>
      <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
      <Progress open={loading} />
      <UnauthorizedDialog open={showUnauthorized} />
    </Grid>
    
  );
}
