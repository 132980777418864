import React from 'react'
import PersonIcon from '@material-ui/icons/Person';
import './Icon.css'; 
 
const IconUser = (props) => {
    return (
        <div>
            {
                props.isFocus ? (
                    <PersonIcon id="idIconUser" />
                ):(
                    <PersonIcon id="idIconUser2" />
                )
            }
            
        </div>
    )
}

export default IconUser
