import React from 'react';
import { Grid } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import ButtonLogin from '../buttons/ButtonLogin';
import KeyValue from '../../apis/KeyValue';
import ReactGA from 'react-ga';
import ConstantKey from '../../config/ConstantKey';

export default function ChoiceProfile() {
    const [goLogin, setGoLogin] = React.useState(false);
    const [goRegister, setGoRegister] = React.useState(false);
    //---
    React.useEffect(() =>{   
        ReactGA.initialize(ConstantKey.REACT_GA_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);      
    },[])

    //---------- MANEJADORES DE EVENTOS ------------------
    
    const setProfileTeacher = () =>{
        localStorage.setItem(KeyValue.CHOICE_PROFILE,'DO');
        setGoRegister(true);        
    };
    const setProfileStudent = () =>{
        localStorage.setItem(KeyValue.CHOICE_PROFILE,'AL');
        setGoRegister(true);  
    };
    const handleReturnLogin = () =>{
        setGoLogin(true)
    }
    //----------- RENDERS ------------------

    if(goLogin){
        return <Redirect to="/login" />
    }
    if(goRegister){
        return <Redirect to="/Register" />
    }
  
    return (
        <div>
            <img 
                src={process.env.PUBLIC_URL + '/images/logo_SD_new.png'} 
                className="widthLogoStoDomingo"
                style={{marginLeft:'unset'}}
                alt="login"
            />        
            <div className="imageFull3">
                <div style={{backgroundColor:'transparent', marginLeft:'auto',marginRight:'auto',width:300,marginTop:100}}>

                    <Grid container>
                        <Grid item xs={12}>
                            <div style={{backgroundColor:'white', marginLeft:'auto',marginRight:'auto',width:300,height:315,borderRadius:11.5}}>
                                <Grid container justify="center" alignItems="center" spacing={1}>                            

                                    <Grid item xs={12} style={{marginTop:18}}>
                                        <h4 style={{fontFamily:'Montserrat-SemiBold',marginBottom:14,fontSize:'1.2rem'}}>Registro de usuario</h4> 
                                        <p style={{fontFamily:'Montserrat-Regular',color:'#1A1A1A',fontSize:'11pt',marginBottom:14}}>Selecciona tu perfil de usuario</p>
                                    </Grid>
                                    <Grid item xs={12} style={{marginBottom:14}}>
                                        <ButtonLogin
                                            id={'idBtnStudent'}
                                            name={'Alumno'}
                                            handleClick={setProfileStudent}            
                                        />
                                    </Grid>

                                    <Grid item xs={12} style={{marginBottom:14}}>
                                        <ButtonLogin
                                            id={'idBtnTeacher'}
                                            name={'Docente'}
                                            handleClick={setProfileTeacher}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12} style={{marginTop:35}}>
                                        <p style={{color:'#1A1A1A',fontFamily:'Montserrat-Regular'}}>¿Ya cuenta con un usuario? <span style={{textDecoration:'underline',cursor:'pointer',color: 'blue',fontFamily:'Montserrat-Regular'}} onClick={handleReturnLogin}>Ingresa</span></p>
                                                                       
                                    </Grid>
                                </Grid>        
                            </div>
                        </Grid>
                    </Grid>
                
                </div>
            </div>
        </div>
        
    );
}
