import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './Button.css';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  button: {
    //margin: theme.spacing(1),
    top:-25,
    position:'relative'
  },
  mTop: {
      marginTop:30,
  }
}));

const ButtonProfileDesign = (props) => {
  const classes = useStyles();

    return (
        <>
        <Divider className={classes.mTop} />       
        <label 
            className={classes.button + " custom-button-design font-size-family "}
            onClick={props.handleClick}
            >
            {props.name}
        </label>
        </>
        
    );
}
export default ButtonProfileDesign
