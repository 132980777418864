import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
//import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NotificationsIcon from '@material-ui/icons/Notifications';
//import MenuBookIcon from '@material-ui/icons/MenuBook';
import './Drawer.css'
import '../accordion/Accordion.css';
import MyBooks from '../mybooks/MyBooks';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MyNotifications from '../mynotifications/MyNotifications';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
//import MyClassroom from '../myClassroom/MyClassroom';
import UrlBase from '../../config/UrlBase';
import Key from '../../apis/Key';
import Endpoint from '../../apis/Endpoint';
import Token from '../../config/Token';
import KeyValue from '../../apis/KeyValue';
import { Redirect } from 'react-router';
//import Classroom from '../myClassroom/Classroom';
import { Grid } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import ButtonEdit from '../buttons/ButtonEdit';
import SectionDialog from '../dialogs/SectionDialog';
//import SectionIcon from '@material-ui/icons/ViewWeek'
//import NotificationsIcon from '@material-ui/icons/Notifications';
//import MenuBookIcon from '@material-ui/icons/MenuBook';
//import AddIcCallIcon from '@material-ui/icons/AddIcCall';
//import SectionIcon from '@material-ui/icons/GroupAdd';
import ButtonSearch from '../buttons/ButtonSearch';
import InputSearch from '../inputs/InputSearch';
import PersonIcon from '@material-ui/icons/Person';
import LinearProgress from '@material-ui/core/LinearProgress';
//import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import CheckCustom from '../inputs/CheckCustom';
import NotificationDialog from '../dialogs/NotificationDialog';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import BookDetail from '../mybooks/BookDetail';
//import AccordionSchool from '../accordion/AccordionSchool';
//import MenuIcon from '@material-ui/icons/Menu';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
//import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import StreamingCallDialog from '../dialogs/StreamingCallDialog';
import {ToolbarContext} from '../../context/ToolbarProvider';
//import ProfileDialog from '../dialogs/ProfileDialog';
import Profile from '../profile/Profile';
import PasswordDialog from '../dialogs/PasswordDialog';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';
import UrlBase_Prod from '../../config/UrlBase_Prod';
import ReactGA from 'react-ga';
import ConstantKey from '../../config/ConstantKey';

var drawerWidth = 230;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    //position:'fixed',
    //width:'96%',
    //height:'100%',
    //left:'4%',
    //top:'0%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    //height: `calc(100% - ${92}%)`,
    justifyContent: 'flex-end',
  },
  heightThemesBook:{
    height: `calc(100% - ${50}%)`,
  }
}));

const useStylesTabs = makeStyles((theme) => ({
  root: {
      width: '100%',
      backgroundColor: 'white',
  },
  rootTab: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
  },
  heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
  },
  secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function DrawerTeacher() {
  const classes = useStyles();
  const theme = useTheme();
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [param, setParam] = React.useState(KeyValue.RENDER_MY_BOOKS);
  const [anchorEl, setAnchorEl] = React.useState(null);
  //const [goClassroom, setGoClassroom] = React.useState(false)
  const [arrListClassrooms, setArrListClassrooms] = React.useState([]);
  const [showSeccion, setShowSection ] = React.useState(false);
  const [nameStudent, setNameStudent] = React.useState('');
  const [sectionSelected, setSectionSelected] = React.useState(null);
  //const [progressLineal, setProgressLineal] = React.useState(40);
  //const [checkStudent,setCheckStudent] = React.useState(false);
  const [arrListStudents, setArrListStudents] = React.useState([]);
  const [codeBookSection, setCodeBookSection] = React.useState('');
  const [showNotificationDialog, setShowNotificationDialog] = React.useState(false);
  const [titleNotification, setTitleNotification] = React.useState('');
  const [detailNotification, setDetailNotification] = React.useState('');
  const [nameSection,setNameSection] = React.useState('');
  const [listSections,setListSections] = React.useState([]);
  const [idSection, setIdSection] = React.useState(0);
  const [classRoomSelected, setClassroomSelected] = React.useState(null);
  const [schoolSelected, setSchoolSelected] = React.useState(null);
  const [flagResultNotification, setFlagResultNotification] = React.useState(false);
  const [flagNotFoundClassrooms, setFlagNotFoundClassrooms] = React.useState(false);
  const [openLinkStreaming, setOpenLinkStreaming] = React.useState(false);
  const [showFromBook, setShowFromBook] = React.useState(false);
  const [openEditProfile, setOpenEditProfile] = React.useState(false)
  const [editView, setEditView] = React.useState(0);
  const [initViewPassword, setInitViewPassword] = React.useState(0);
  const [initSection,setInitSection] = React.useState(1);
  //--context ----------
  const {toolbar,handleHideToolbar,flagCode,handleReturnFlagCode} = React.useContext(ToolbarContext);
  //--------------------
  const [openPassword, setOpenPassword]  = React.useState(false);
  //----tabs school------------------
  const classesTab = useStylesTabs();
  const [expanded, setExpanded] = React.useState(false);
  const [valueTab, setValueTab] = React.useState('1');
  const [codeBookFromClassroom, setCodeBookFromClassroom] = React.useState("0")
  const [codeBookTemp, setCodeBookTemp] = React.useState("0")

  const [flagNameErrorSection, setFlagNameErrorSection] = React.useState(false);
  const [nameSectionHelperText, setNameSectionHelperText] = React.useState('');

  const [flagSelectErrorSection, setFlagSelectErrorSection] = React.useState(false);
  const [selectSectionHelperText, setSelectSectionHelperText] = React.useState('');
  //-------flags notifications ----------------
  const [detailFlagError, setDetailFlagError] = React.useState(false);
  const [detailHelperText, setDetailHelpertText]= React.useState('');

  const [titleFlagError, setTitleFlagError]  = React.useState(false);
  const [titleHelperText, setTitleHelperText] = React.useState('');
  //------------------------flag vencimiento de token------------------------------
  const [showUnauthorized, setShowUnauthorized] = React.useState(false);
  //-----------------------flag render many times -------
  const [flagRenderMyBooks, setFlagRenderMyBooks] = React.useState(false);

  const handleChangeTab = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
  };
  const handleOpenStreamingLinks = () => {
    //console.log('click')
    setOpenLinkStreaming(true);
  }
  const handleClosePopupStreaming = () => {
    setOpenLinkStreaming(false);
  }
  const handleChangePassword = () =>{
    handleCloseMenu()
    setInitViewPassword(1)
    setOpenPassword(true)
  }
  const handleClosePassword = () =>{
    setInitViewPassword(0)
    setOpenPassword(false)
  }

  //----loader----
  const [loading, setLoading] = React.useState(false);
  //---message error-----
  const [show, setShow] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);    
  //---------
  const handleClose = () => {
      setShow(false)       
  };
  const ShowDialogMessage = (message) =>{
      setShow(true);
      setMessageError(message);
  }
  //----------- impl method async ------------------------------------
  const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
      //const URL_BASE = UrlBase.URL_BASE_PORTAL_SERVICE;
      let URL_BASE  = "";
      if(process.env.NODE_ENV === "development"){
          URL_BASE  = UrlBase.URL_BASE_PORTAL_SERVICE;
      }
      if(process.env.NODE_ENV === "production"){
          URL_BASE  = UrlBase_Prod.URL_BASE_PORTAL_SERVICE;
      }
      const tokenLocalStorage = localStorage.getItem(Token.TOKEN)
      const paramsHeadersPost = {
        method:typeCall,
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        },
        body: JSON.stringify(obj)
      }
      const paramsHeadersGet = {
        method:typeCall,
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        }
      }
      const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
      const URL = URL_BASE+methodAction;    
      setLoading(true);    
        try{
            fetch(URL,header).then(function(res){
              if(res.status === 401){
                return 401;
              }
              return res.json();
            }).then(function(response){    
              setLoading(false);

              if(response === 401){
                setShowUnauthorized(true);

              }else if(response.Code === 0){
                  SetConfigResponse(response,typeAction);                    

              }else if (response.Code === 1){
                  errorCode1(response);
          
              }else if (response.Code === 2){
                  errorCode2(response);
              }else if (response.Code === 4){

                errorCode4(response);
              }
            }).catch((error) =>{
              setLoading(false);
              console.log(error);
            })
        }catch(e){
          setLoading(false);
          console.log(e);
        }                 
  }
  const asyncCallMethodCloseSession = (typeCall,obj,methodAction,typeAction) =>{
    //const URL_BASE = UrlBase.URL_BASE_SECURITY_SERVICE;
    let URL_BASE  = "";
    if(process.env.NODE_ENV === "development"){
        URL_BASE  = UrlBase.URL_BASE_SECURITY_SERVICE;
    }
    if(process.env.NODE_ENV === "production"){
        URL_BASE  = UrlBase_Prod.URL_BASE_SECURITY_SERVICE;
    }

    const paramsHeadersPost = {
      method:typeCall,
      mode: 'cors',
      headers: {
          'Accept':'application/json',
          'ApiKey': Key.API_KEY_SECURITY_SERVICE,
          'Content-type': 'application/json'
      },
      body: JSON.stringify(obj)
    }
    const paramsHeadersGet = {
      method:typeCall,
      mode: 'cors',
      headers: {
          'Accept':'application/json',
          'ApiKey': Key.API_KEY_SECURITY_SERVICE,
          'Content-type': 'application/json'
      }
    }
    const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
    const URL = URL_BASE+methodAction;    
    setLoading(true);    
      try{
          fetch(URL,header).then(function(res){
              return res.json();
          }).then(function(response){    
            setLoading(false);
            ResponseLogout(response)
            /*              
            if(response.Code === 0){
                SetConfigResponse(response,typeAction);                    

            }else if (response.Code === 1){
                errorCode1(response);
        
            }else if (response.Code === 2){
                errorCode2(response);        
            }*/
          }).catch((error) =>{
            setLoading(false);
            console.log(error);
          })
      }catch(e){
        setLoading(false);
        console.log(e);
      }                 
  }
  const SetConfigResponse = (response,typeAction)=>{
    if(typeAction === KeyValue.ACTION_GET_CLASSROOM){
      ResponseListClassroom(response);
    }else if (typeAction === KeyValue.ACTION_GET_CLASSROOM_BY_SECTION){
      ResponseClassroomBySection(response);
    } else if (typeAction === KeyValue.ACTION_INSERT_NOTIFICATION){
      ResponseSendNotification(response);
    } else if (typeAction === KeyValue.ACTION_UPDATE_STUDENTS_SECTION){
      ResponseUpdateStudentsSection(response)
    } else if (typeAction === KeyValue.ACTION_GET_ALL_SECTIONS){
      ResponseGetAllSections(response);
    }
  }
  const errorCode1 = (response) =>{
      var message = "";
      if(response.Data.StatusCode === 500){
          ShowDialogMessage(response.Data.Message);
      }
      else if(response.Data.Errors.length > 0){
          response.Data.Errors.forEach(element => {
              //console.log(element);
              message = `${element}.`
          });
          ShowDialogMessage(message);                        
      }
  }
  const errorCode2 = (response) =>{
      var message = "";
      if(response.Data.Errors.length > 0){
          response.Data.Errors.forEach(element => {
              //console.log(element);
              message = message +  ` ${element.FieldName}. ${element.Message}. `
          });
          ShowDialogMessage(message);                        
      }
  }
  const errorCode4 = (response) =>{
    ShowDialogMessage(`StatusCode: ${response.Data.StatusCode}. ${response.Data.Message}`)
  }
  //--------------------------------------------------
  const handleRedirectToBookSelected = () => {    
    setCodeBookFromClassroom(codeBookTemp)
    handleReturnFlagCode(true);
  }
  const eventListner = React.useCallback(() => {
    //console.log(state); // use the stateful variable in event listener
  }, [flagCode]);

  React.useEffect(() =>{
    console.log("use effect drawer teacher")
       
    ReactGA.initialize(ConstantKey.REACT_GA_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
    
    if(flagCode){
      setParam(1)
    }else{
      GetlistBooks();
    }    
    //GetAllSections();
  },[eventListner]);
  
  //------------show list books---------------------------------------
  const GetlistBooks = () =>{

  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const setOptionView = (view) => {
    console.log("set option view ");
    console.log(view);
    handleHideToolbar(false)
    setParam(view);
    if (view === KeyValue.RENDER_MY_CLASSROOMS){
      GetAllSections();
      GetListClassroom();
    }
    if(view === KeyValue.RENDER_EDIT_PERFIL){
      handleCloseMenu();
      handleCloseEditProfile();
      setEditView(1)
    }
  }
  const handleShowListBooks = () =>{
    setFlagRenderMyBooks(!flagRenderMyBooks);
    setOptionView(KeyValue.RENDER_MY_BOOKS);

  }
  const handleShowListBooksAgaint = () =>{
    setFlagRenderMyBooks(!flagRenderMyBooks);
    setOptionView(KeyValue.RENDER_MY_BOOKS_AGAINT);
  }
  const handleCloseMenu = () =>{
    setAnchorEl(null);
  }
  const handleMenu = (event) => { 
    setAnchorEl(event.currentTarget);
  };
  const handleNameStudent = (e) =>{
    setNameStudent(e.target.value);
  }
  const handleSearchStudent = () => {
    var nameSt = " "
    if (nameStudent !== ""){
      nameSt = nameStudent;
    }
    GetClassroomBySection(classRoomSelected,nameSt);
  }
  //----------------cerrar sesión ------------
  const handleCloseSession = () => {
    const methodAction = Endpoint.LOGOUT;
    const obj = {
      Token: localStorage.getItem(Token.TOKEN),
      RefreshToken: localStorage.getItem(Token.REFRESH_TOKEN),
    }
    //console.log(obj);
    asyncCallMethodCloseSession('post',obj,methodAction,KeyValue.ACTION_LOGOUT);
  };
  const ResponseLogout = (response) => {
    setClearDataLocal();
    setReturnLogin(true)
  }
  const setClearDataLocal = () =>{
    localStorage.setItem(Token.TOKEN, '');
    localStorage.setItem(Token.REFRESH_TOKEN, '');
    localStorage.setItem(Token.TYPE_USER ,'');
    localStorage.setItem(Token.NAME_USER,'');
    localStorage.setItem(Token.UNIQUE_NAME,'');
    localStorage.clear();
  }
  //----------------lista de aulas ----------------

  const GetListClassroom = () =>{
    const methodAction = Endpoint.GET_LIST_CLASSROOM;
    asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_CLASSROOM);
  }
  const ResponseListClassroom = (response) =>{
    setArrListClassrooms(response.Data)

    if(response.Data.length > 0) {
      setFlagNotFoundClassrooms(false)
    }else{
      setFlagNotFoundClassrooms(true)
    }
  }  
  //------------------- aulas ----------------------------
  const handleGoClassroom = (classroomSelected,schoolSelected) =>{
    //console.log(classroomSelected);
    setSchoolSelected(schoolSelected);
    setClassroomSelected(classroomSelected); //para tener data del aula escogida
    GetClassroomBySection(classroomSelected," ");
    //console.log('code libro click');
    //console.log(classroomSelected.CodeLibro);
    setCodeBookTemp(classroomSelected.CodeLibro);
    setParam(3);
  }
  //------------------------aulas por seccion ----------------------------
  const GetClassroomBySection = (obj,nameStudent) =>{
    setSectionSelected(obj);
    const methodAction = Endpoint.GET_CLASSROOM_BY_SECTION
                          + nameStudent
                          + "/" + obj.IdColegio 
                          + "/" + obj.IdGradoInstruccion
                          + "/" + obj.IdCursoLibro
                          + "/" + obj.IdSeccion
                          + "/" + obj.IdLibro
                          
    asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_CLASSROOM_BY_SECTION);
  }
  const ResponseClassroomBySection = (response) => {  
    setCodeBookSection(response.Data.CodeLibro);

    response.Data.Result.forEach(element => {
      element.Selected = false;
    });

    setArrListStudents(response.Data.Result);
    //setArrStudents(response.Data.Result);
  }
  const onChangeCheckStudent = (event,item) => {
    //console.log(event.target.checked);
    //console.log(item);
    //setCheckStudent(!checkStudent);
    const arr = []
    arrListStudents.forEach(element => {
      if (element.CodeAlumno === item.CodeAlumno){
        element.Selected = event.target.checked;        
      }
      arr.push(element)
    });
    setArrListStudents(arr)
  }
  const GetStudentsChecked = (list) =>  {
    const listChecked = []
    list.forEach(element => {
      if(element.Selected){
        listChecked.push(element.CodeAlumno);
      }
    });
    return listChecked;
  }
  //------------------------notifications--------------------------------
  const handleShowNotificationDialog = () =>{
    setShowNotificationDialog(true);
  }
  const handleCloseNotificationDialog = () =>{
    setShowNotificationDialog(false);
    setFlagResultNotification(false);
    setTitleNotification('');
    setDetailNotification('');
  }
  const handleTitleNotification = (e) =>{
    //console.log(e.target.value)
    var text = e.target.value;
    if(text === ""){
      setTitleFlagError(true);
      setTitleHelperText(KeyValue.ERROR_EMPTY);
    }else{
      setTitleFlagError(false);
      setTitleHelperText('');
    }
    setTitleNotification(text)
  }
  const handleDetailNotification = (e) => {
    //console.log(e.target.value)
    var text = e.target.value;
    if(text === ""){
      setDetailFlagError(true);
      setDetailHelpertText(KeyValue.ERROR_EMPTY);
    }else{
      setDetailFlagError(false);
      setDetailHelpertText("");
    }
    setDetailNotification(text);
  }
  const handleSendNotification = () => {
    var countEmpty = 0;

    if(titleNotification === ""){
      setTitleFlagError(true)
      setTitleHelperText(KeyValue.ERROR_EMPTY);
      countEmpty++;
    }
    if(detailNotification === ""){
      setDetailFlagError(true);
      setDetailHelpertText(KeyValue.ERROR_EMPTY)
      countEmpty++;
    }

    if(countEmpty === 0 ){
      const methodAction = Endpoint.INSERT_NOTIFICATION;
      const notification = {
        Titulo: titleNotification,
        Mensaje: detailNotification,
        IdColegio: sectionSelected.IdColegio,
        IdGrado: sectionSelected.IdGradoInstruccion,
        IdSeccion: sectionSelected.IdSeccion,
        IdLibro: sectionSelected.IdLibro,
      }
      asyncCallMethod('post',notification,methodAction,KeyValue.ACTION_INSERT_NOTIFICATION);
    }
  }
  const ResponseSendNotification = (response) => {
    if(response.Data){
      //console.log('notificacion enviada con éxito')
      setFlagResultNotification(true);
      setTitleNotification('');
      setDetailNotification('');
    }else{
      handleCloseNotificationDialog();
      ShowDialogMessage(response.Message)      
    }
  }
  //-----------------------------secciones------------------------------------
  const handleCloseSection = () => {
    setInitSection(0)
    setShowSection(false)
    setNameSectionHelperText('')
    setFlagNameErrorSection(false);
    setFlagSelectErrorSection(false);
    setSelectSectionHelperText('');
  }
  const handleShowSection = () =>{
    setNameSection('')
    setInitSection(1)
    setShowSection(true)
  }  
  const handleNameSection = (e) =>{
    var text = e.target.value;
    if(text === ""){
      setFlagNameErrorSection(true);
      setNameSectionHelperText('Debe escribir un nombre para la seccion.')
    }else{
      setFlagNameErrorSection(false);
      setNameSectionHelperText('')
    }
    setNameSection(text);
  }
  const handleCreateSection = () =>{
    const listStudents = GetStudentsChecked(arrListStudents)
    if(listStudents.length > 0 ) {
      if(nameSection !== ''){
        const methodAction = Endpoint.UPDATE_STUDENTS_SECTION;
        const updateSection = {
          IdSeccion: 0,
          NombreSeccion: nameSection,
          ListaCodeAlumnos: GetStudentsChecked(arrListStudents) ,
        }
        asyncCallMethod('post',updateSection,methodAction,KeyValue.ACTION_UPDATE_STUDENTS_SECTION);
      }else {
        setFlagNameErrorSection(true);
        setNameSectionHelperText('Debe escribir un nombre para la seccion.')
      }
      
    }else{
      setFlagNameErrorSection(true);
      setNameSectionHelperText('No hay estudiantes seleccionados')
    }    
  }
  const ResponseUpdateStudentsSection = (response) => {
    if(response.Data){
      setShowSection(false)
      GetClassroomBySection(classRoomSelected, " ");
    }else{
      //console.log('ocurrió un error')
    }
  }
  const GetAllSections = ()  =>{
    const methodAction = Endpoint.GET_ALL_SECTIONS;
    asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_ALL_SECTIONS);
  }
  const ResponseGetAllSections = (response) =>{
    setListSections(response.Data);
  }
  const onChangeSection = (e) =>{
    var text = e.target.value;
    setSelectSectionHelperText('');
    setFlagSelectErrorSection(false);
    setIdSection(parseInt(text));
  }
  const assignSection = () =>{
    if(idSection !== 0 ){
      if(GetStudentsChecked(arrListStudents).length > 0 ){
        const methodAction = Endpoint.UPDATE_STUDENTS_SECTION;
        const updateSection = {
          IdSeccion: idSection,
          NombreSeccion: "",
          ListaCodeAlumnos: GetStudentsChecked(arrListStudents),
        }
        asyncCallMethod('post',updateSection,methodAction,KeyValue.ACTION_UPDATE_STUDENTS_SECTION);
      }else{
        setFlagSelectErrorSection(true)
        setSelectSectionHelperText('Debe seleccionar al menos un estudiante.');
      }      
    }else{
      setFlagSelectErrorSection(true)
      setSelectSectionHelperText('Debe seleccionar una seccion del combo.');
    }
  }
  const handleLast = () =>{
    setShowFromBook(true);
  }
  //----------------------------------edit profile------------------------------
  const handleCloseEditProfile = () =>{
    setOpenEditProfile(false)
  }
  const handleOpenEditProfile = () =>{
    setOpenEditProfile(true)
  }
  const handleRedirectToProfile = () => {
    setOptionView(KeyValue.RENDER_EDIT_PERFIL);
  }
   //--------------------------------------------------------------------------
  
  if(returnLogin) {
    return <Redirect to="/login" />
  }

 console.log('param')
 console.log(param)
  
  return (
    <div id={'idprincipal'} className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"/*
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}*/
        id="idAppBar"
        className={toolbar.hide ? 'hideDiv' : 'showDiv'}
      >
        <Toolbar>
          <IconButton
            id="idbuttonRightIcon"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <ChevronRightIcon />
          </IconButton>
            {
              theme.direction === 'ltr' ? (
                <img 
                  src={process.env.PUBLIC_URL + '/images/logo_SD_new.png'} 
                  className={clsx(classes.menuButton, open && classes.hide)+" widthLogoStoDomingo-AppBar"}
                  alt="login"
                  style={{marginLeft:10}}
                />
              ) : ('')
            }
            {true && (
              <div style={{width:'100%',textAlign:'left'}}>                
                  {
                    open ? (
                      <Grid container >
                        <Grid item xs={1} className="appBar-responsive">

                        </Grid>
                        <Grid item md={1} className="appBar-responsive">
                          {
                            param === KeyValue.RENDER_MY_CLASSROOM_BY_SECTION ? (
                              <IconButton 
                                id="idBackClassroom"
                                className={param === KeyValue.RENDER_MY_CLASSROOM_BY_SECTION ? 'showDiv' : 'hideDiv'}
                                onClick={() => setOptionView(KeyValue.RENDER_MY_CLASSROOMS)}
                                style={{marginTop:11}}
                              >
                                <ArrowBackIcon />
                              </IconButton>
                            ) : ('')
                          }

                        </Grid>
                        
                        <Grid item md={2} /*style={{height:68}}*/ className="appBar-responsive">
                          <img 
                            src={process.env.PUBLIC_URL + '/images/logo_2021.png'} 
                            className={"widthLogo2021"}
                            alt="login222"
                            style={{marginLeft:40,background: 'transparent',width:'100%',height:'96%'}}
                          />
                        </Grid>
                        <Grid item md={8} xs={12} className="backgroundAppBar height-top-toolbar">
                          <div  
                            style={{display:'flex',marginLeft:5,float:'right'}}>
                              <p 
                                id="pUsername"
                                onClick={handleMenu}
                                className="appBar-responsive"
                              >
                                {`${localStorage.getItem(Token.GIVEN_NAME)}`}
                              </p>
                            <IconButton
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={handleMenu}
                              //color="inherit"
                              //style={{}}
                            >             
                              <AccountCircleIcon className="iconAppBarColor" />                                
                            </IconButton>
                          </div>
                          {/**para mostrar boton videollamada en usuario: estudiante */}
                          <div className={(localStorage.getItem(Token.TYPE_USER) === KeyValue.TypeStudent  ? 'showDiv iconButtonToolbar' : 'hideDiv') + " "}>
                            <div>
                              <img                                
                                src={process.env.PUBLIC_URL + "/images/ico_blank_videostreaming.png"}
                                className="style-width-icon-action-toolbar" 
                                title={KeyValue.TITLE_VIDEOCALL}
                                onClick={handleOpenStreamingLinks}/>
                            </div>                            
                          </div>   
                          {/**inicio opciones perfil docente */}
                          <div className={(param === KeyValue.RENDER_MY_CLASSROOM_BY_SECTION ? 'showDiv iconButtonToolbar' : 'hideDiv') + " "}>
                            <div>
                              <img
                                src={process.env.PUBLIC_URL + "/images/ico_blank_videostreaming.png"}
                                className="style-width-icon-action-toolbar"
                                title={KeyValue.TITLE_VIDEOCALL}
                                onClick={handleOpenStreamingLinks} />
                            </div> 
                          </div> 
                          
                          <div className={(param === KeyValue.RENDER_MY_CLASSROOM_BY_SECTION  ? 'showDiv iconButtonToolbar' : 'hideDiv') + " "}>
                            <div>
                              <img
                                src={process.env.PUBLIC_URL + "/images/ico_blank_book.png"}
                                className="style-width-icon-action-toolbar" 
                                alt="book"
                                title={KeyValue.TITLE_SHOW_BOOK}
                                onClick={handleRedirectToBookSelected}
                                />
                            </div>
                          </div>
                          
                          <div   className={(param === KeyValue.RENDER_MY_CLASSROOM_BY_SECTION  ? 'showDiv iconButtonToolbar' : 'hideDiv') + " "}>
                            <div>
                              <img
                                src={process.env.PUBLIC_URL + "/images/ico_blank_notification.png"}
                                className="style-width-icon-action-toolbar" 
                                alt="notif"
                                title={KeyValue.TITLE_NOTIFICATION}
                                onClick={handleShowNotificationDialog}/>
                            </div>
                          </div>
                          
                          <div className={(param === KeyValue.RENDER_MY_CLASSROOM_BY_SECTION  ? 'showDiv iconButtonToolbar' : 'hideDiv') + " "}>
                            <div>
                              <img
                                src={process.env.PUBLIC_URL + "/images/ico_blank_section.png"}
                                className="style-width-icon-action-toolbar" 
                                title={KeyValue.TITLE_SECCTION}
                                onClick={handleShowSection}/>
                            </div> 
                          </div>
                          {/**FIN*/}
                          <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                          >  
                          <MenuItem className="fontMenuItem" onClick={e => setOptionView(KeyValue.RENDER_EDIT_PERFIL)}>Editar Perfil</MenuItem>
                          <MenuItem className="fontMenuItem" onClick={handleChangePassword}>Cambiar contraseña</MenuItem>              
                            <MenuItem className="fontMenuItem" onClick={handleCloseSession}>Cerrar Sesión</MenuItem>
                            
                          </Menu>                          
                        </Grid>                
                      </Grid>
                      
                    ) : (
                      <Grid container >
                        <Grid item xs={2} /*style={{height:68}}*/ className="appBar-responsive">
                          <img 
                            src={process.env.PUBLIC_URL + '/images/logo_2021.png'} 
                            className={"widthLogo2021"}
                            alt="login"
                            style={{marginLeft:40,background: 'transparent',width:'100%',height:'100%'}}
                          />
                        </Grid>
                        <Grid item xs={12} md={10} className="backgroundAppBar height-top-toolbar">
                          <div  style={{display:'flex',marginLeft:5,float:'right'}}>
                              <p 
                                id="pUsername"
                                onClick={handleMenu}
                                className="appBar-responsive"
                              >
                                {`${localStorage.getItem(Token.GIVEN_NAME)}`}
                              </p>
                            <IconButton
                              aria-label="account of current user"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              onClick={handleMenu}
                              label="hola"
                              //color="inherit"
                              //style={{}}
                            >             
                              <AccountCircleIcon className="iconAppBarColor" />                                
                            </IconButton>
                          </div>

                          <div 
                            onClick={handleOpenStreamingLinks}
                            className={(localStorage.getItem(Token.TYPE_USER) === KeyValue.TypeStudent  ? 'showDiv iconButtonToolbar' : 'hideDiv') + " "} 
                          >
                            <div>
                              <img
                                src={process.env.PUBLIC_URL + "/images/ico_blank_videostreaming.png"}
                                className="style-width-icon-action-toolbar"
                                title={KeyValue.TITLE_VIDEOCALL}/>
                            </div>                            
                          </div>                           
                          
                          <div className={(param === KeyValue.RENDER_MY_CLASSROOM_BY_SECTION  ? 'showDiv iconButtonToolbar' : 'hideDiv') + " "}>
                            <div>
                              <img
                                src={process.env.PUBLIC_URL + "/images/ico_blank_videostreaming.png"}
                                className="style-width-icon-action-toolbar" 
                                onClick={handleOpenStreamingLinks}
                                title={KeyValue.TITLE_VIDEOCALL}/>
                            </div>                            
                          </div> 

                          <div className={(param === KeyValue.RENDER_MY_CLASSROOM_BY_SECTION  ? 'showDiv iconButtonToolbar' : 'hideDiv') + " "}>
                            <div>
                            <img
                              src={process.env.PUBLIC_URL + "/images/ico_blank_book.png"}
                              className="style-width-icon-action-toolbar" 
                              alt="book"
                              title={KeyValue.TITLE_SHOW_BOOK}
                                onClick={handleRedirectToBookSelected}
                              />
                            </div>
                            
                          </div>
                          
                          <div 
                            onClick={handleShowNotificationDialog}
                            className={(param === KeyValue.RENDER_MY_CLASSROOM_BY_SECTION  ? 'showDiv iconButtonToolbar' : 'hideDiv') + " "}>
                            <div>
                            <img
                              src={process.env.PUBLIC_URL + "/images/ico_blank_notification.png"}
                              className="style-width-icon-action-toolbar" 
                              title={KeyValue.TITLE_NOTIFICATION}/>
                            </div>
                            
                          </div>
                          
                          <div 
                            onClick={handleShowSection}
                            className={(param === KeyValue.RENDER_MY_CLASSROOM_BY_SECTION  ? 'showDiv iconButtonToolbar' : 'hideDiv') + " "}>
                            <div>
                              <img
                                src={process.env.PUBLIC_URL + "/images/ico_blank_section.png"}
                                className="style-width-icon-action-toolbar" 
                                title={KeyValue.TITLE_SECCTION}/>
                            </div>                            
                          </div>

                          <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                          >    
                            <MenuItem className="fontMenuItem" onClick={e => setOptionView(KeyValue.RENDER_EDIT_PERFIL)}>Editar Perfil</MenuItem>
                            <MenuItem className="fontMenuItem" onClick={handleChangePassword}>Cambiar contraseña</MenuItem>                          
                            <MenuItem className="fontMenuItem" onClick={handleCloseSession}>Cerrar Sesión</MenuItem>
                            
                          </Menu>
                          
                        </Grid>
                
                      </Grid>
                    )
                  }
                  
              </div>
            )}          
        </Toolbar>
      </AppBar>
      
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.drawerHeader} >
          {
            open ? (
            <img 
              src={process.env.PUBLIC_URL + '/images/logo_SD_new.png'} 
              className="widthLogoStoDomingo2"
              alt="login"
              onClick={handleDrawerClose}
              style={{cursor:'pointer',marginRight:'auto',marginLeft:'auto'}}
            />
            ) : (
              <IconButton onClick={handleDrawerOpen} >
                <MenuIcon />
              </IconButton>
            )
          }
        </div>
        <Divider />
        <List style={{border:'0px solid blue'}}>
          <ListItem button key={'MisLibros'} onClick={flagRenderMyBooks? handleShowListBooks : handleShowListBooksAgaint}>
            <ListItemIcon>
              {<img src={process.env.PUBLIC_URL + "/images/ico_mis_libros.png"} style={{width:27}} />}
            </ListItemIcon>
            <ListItemText primary={'Mis libros'} className="itemMenuDrawer menuItemLetter" />
          </ListItem>
          <Divider />
          {
            localStorage.getItem(Token.TYPE_USER) === KeyValue.TypeTeacher? (
              <ListItem button key={'MisAulas'} onClick={() => setOptionView(KeyValue.RENDER_MY_CLASSROOMS)}>
                <ListItemIcon>
                  {<img src={process.env.PUBLIC_URL + "/images/ico_mis_aulas.png"} style={{width:27}} />}
                </ListItemIcon>
                <ListItemText primary={'Mis Aulas'} className="itemMenuDrawer menuItemLetter"/>
              </ListItem>
            ) : (
              <ListItem button key={'MisMensajes'} onClick={() => setOptionView(KeyValue.RENDER_MY_NOTIFICATIONS)}>
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary={'Mis Mensajes'} className="itemMenuDrawer menuItemLetter" />
              </ListItem>
            )
          }
          
        </List>
        <Divider />
      </Drawer>
      
      <main
        className={clsx(classes.content + " style-Left-screen2", {
          [classes.contentShift]: open,
        }) + (toolbar.hide ? ' hidePaddingWebviewer' : ' ')}
        style={{background:'#E6E6E6'}}
        onClick={handleDrawerClose}
      >
        <div className={toolbar.hide ? 'hideDiv' :  /*" drawerHeader drawerHeader-1"} /*/classes.drawerHeader} />

        {/** css style para pintar todo el body */}
        <div className="overlay"></div>

        {/** renderizar mis libros */
          param === KeyValue.RENDER_MY_BOOKS ? (
            <MyBooks 
              param = {param}
              showFromBook={showFromBook} 
              handleLast={handleLast} 
              codeBookFromClassroom={codeBookFromClassroom} 
              handleRedirectToProfile={handleRedirectToProfile}
              handleShowListBooksAgaint={handleShowListBooksAgaint}
              />
          ) :('')
        }
        {/** renderizar mis libros */
          param === KeyValue.RENDER_MY_BOOKS_AGAINT ? (
            <MyBooks 
              param = {param}
              showFromBook={showFromBook} 
              handleLast={handleLast} 
              codeBookFromClassroom={codeBookFromClassroom} 
              handleRedirectToProfile={handleRedirectToProfile}
              handleShowListBooksAgaint={handleShowListBooksAgaint}
              />
          ) :('')
        }

        {/**renderirzar mis aulas */
          param === KeyValue.RENDER_MY_CLASSROOMS ? (
            <div style={{overflow:'auto',height:509}}>
            <Grid container >
              {
                arrListClassrooms.length > 0 ? (
                  arrListClassrooms.map(item => 
                    <Grid item xs={12} md={7} >
                      
                      <div className={classesTab.rootTab + " divTabContext"} style={{margin: 20,borderRadius:10,background:'transparent'}}>
                        <TabContext value={valueTab} className="borderTabContext">
                              <AppBar position="static" className="tabHeader" >
                                  <Tab
                                      id='tabMainId'
                                      label={item.NombreColegio} 
                                      value="1"
                                      className="letterCapitalize"
                                  />                        
                              </AppBar>                    
                              
                              <TabPanel value="1" style={{padding:0}}>

                                  <div className={classesTab.root} style={{padding:10,borderRadius:5}}>
                                    {
                                      item.Filtro.length > 0 ? (
                                        item.Filtro.map(item1 =>
                                          <Accordion expanded={expanded === item1.IdUnico.toString()} onChange={handleChangeTab(item1.IdUnico.toString())}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header222"
                                                className="customClass2"
                                            >
                                                <h6 style={{paddingTop:5,marginBottom:0,fontFamily:'Montserrat-SemiBold'}}>{item1.NombreGrado} - {item1.NombreCurso} - {item1.Nivel}</h6>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container>
                                                    <Grid item xs={12} style={{marginBottom:15}} >
                                                        <Divider />
                                                    </Grid>
                                                    {
                                                      item1.Secciones.length > 0 ? (
                                                        item1.Secciones.filter(el => el.NombreSeccion !== "").map(obj =>
                                                          <Grid item xs={12} style={{textAlign:'left',margin:0}}>
                                                              <p className="styleSectionItem" onClick={() => handleGoClassroom(obj,item)}>
                                                                {`${obj.NombreGrado} - ${obj.NombreSeccion} - ${obj.NombreCurso} - ${obj.NombreNivel} (${obj.CantidadAlumnos} alumnos)`}</p><br/>
                                                          </Grid>
                                                        )
                                                      ):('')                                                      
                                                    }
                                                    <Grid item xs={12} style={{marginBottom:15}} ></Grid>
                                                    {
                                                      item1.Secciones.length > 0 ? (
                                                        item1.Secciones.filter(el => el.NombreSeccion === "").map(obj =>
                                                          <Grid item xs={12} style={{textAlign:'left',margin:0}}>
                                                              <p className="styleSectionItem" onClick={() => handleGoClassroom(obj,item)}>
                                                                {`${obj.NombreGrado} - ${obj.NombreCurso} - ${obj.NombreNivel} (${obj.CantidadAlumnos} alumno(s) pendiente(s))`}</p>
                                                          </Grid>
                                                        )
                                                      ):('')                                                      
                                                    }
                                                </Grid>
                                            </AccordionDetails>
                                          </Accordion>
                                          )
                                      ):('')
                                    }

                                  </div>                          
                              </TabPanel>                          
                          </TabContext>
                      </div>
                    </Grid>            
                    )
                ) : (<h4 className={flagNotFoundClassrooms? 'showDiv' : 'hideDiv'}>Sin aulas encontradas</h4>)
              }
              
            </Grid>
            </div>
          ) : ('')
        }
        {/**renderizar seccion por aula */
          param === KeyValue.RENDER_MY_CLASSROOM_BY_SECTION ? (
            <Grid container >
              {/** boton retroceder */}
              <Grid item xs={12}>
                <div  
                  style={{display:'flex',marginLeft:5,float:'left'}}
                >
                  <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => setOptionView(KeyValue.RENDER_MY_CLASSROOMS)}
                      label="hola"
                      //color="inherit"
                      //style={{}}
                  >             
                      <ArrowBackIcon style={{color:'black'}} />                                
                  </IconButton>
                  <p 
                      id="pback"
                      style={{cursor:'pointer'}}
                      onClick={() => setOptionView(KeyValue.RENDER_MY_CLASSROOMS)}
                  >
                      Volver
                  </p>                    
                </div> 
              </Grid>
              
              <Grid item xs={12}>
                {
                  sectionSelected !== null ? (
                    <div>
                      <h5 style={{textAlign:'left',fontFamily:'Montserrat-SemiBold',marginBottom:15,paddingLeft:8}}>{`${schoolSelected !== null ? schoolSelected.NombreColegio : ''} `}</h5>
                      <p style={{textAlign:'left',marginBottom:20,fontFamily:'Montserrat-Regular',paddingLeft:8}}>{`${sectionSelected.NombreGrado} ${sectionSelected.NombreSeccion} - ${sectionSelected.NombreCurso} (${arrListStudents.length > 0 ? arrListStudents.length : 'Sin'} alumnos)`}</p>
                    </div>
                    
                  ) : ('')
                }                
              </Grid>

              <Grid item xs={12} md={6} >
                <div style={{padding:8}}>
                  <InputSearch
                      //className={searchDniError? 'error-empty':''} 
                      label="Ingrese nombre del alumno"
                      type={'text'}
                      //value={'searchDni'}
                      onChange={handleNameStudent}  
                      //onKeyPress={handleKeyPressInputDNI}
                  />
                </div>                                                                                 
              </Grid>
              <Grid item xs={12} md={2} className="style-align-responsive-button-search-section">
                  <ButtonSearch
                      name={'Buscar'}
                      style={{display:'inlineBlock !important'}}
                      handleClick = {handleSearchStudent}
                  />  
              </Grid>

              <Grid item xs={12}>
                <div style={{overflow:'auto'}} className="height-only-section">
                  
                <Grid container>
                  {
                    arrListStudents.length > 0 ? (
                      arrListStudents.map(item =>
                      <Grid item xs={12} md={3} style={{padding: 20}}>
                        <Card  variant="outlined" style={{borderRadius:10}}>
                          <CardContent className="cardStudent" >                    
                            <div style={{width:0,textAlign:'right',position:'relative'}} /*onClick={() =>handleAddSelectedStudent(item)}*/ >
                              <CheckCustom 
                                handleChange={(e) =>onChangeCheckStudent(e,item)} 
                                //stateCheck={checkStudent}
                              />
                            </div>
                            {
                              item.UrlFoto !== null ? (
                              <img src={item.UrlFoto} alt="" className="iconStudent2" />
                              ):(<img src={process.env.PUBLIC_URL + "/images/ico-perfil.png"} alt="" className="iconStudent2" />)
                            }
                            
                            <p style={{margin: 0,fontFamily:"Montserrat-Semibold"}}>{`${item.Nombres} ${item.Apellidos}`}</p>
                            <p style={{margin: 0,fontFamily:"Montserrat-Regular"}}>{item.Email}</p>
                            <div className={classes.root2}>
                              <LinearProgressWithLabel value={item.PorcentajeProgresoAvanzado} />
                            </div>
                          </CardContent>
                        </Card>          
                      </Grid>)

                    ):('')
                  }

                </Grid>
              
                </div>
              </Grid>
            </Grid>
          ) : ('')
        }
        {/** renderizar mis mensajes para perfil estudiante */
          param === KeyValue.RENDER_MY_NOTIFICATIONS ? (
            <MyNotifications />
          ) : ('')
        }
        {/** renderizar edicion de perfil */
          param === KeyValue.RENDER_EDIT_PERFIL ? (
            <Profile init={editView} handleReturnBook={() => setOptionView(KeyValue.RENDER_MY_BOOKS)}/>
          ) : ('')
        }
      </main>
      <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
      <Progress open={loading} />
      <StreamingCallDialog open={openLinkStreaming} handleClose={handleClosePopupStreaming}/>
      
      <SectionDialog 
        open={showSeccion} 
        handleClose={handleCloseSection}
        nameSection={nameSection}
        handleNameSection = {handleNameSection}
        handleCreateSection= {handleCreateSection} 
        listSections={listSections}
        valueSection={idSection}
        onChangeSection={onChangeSection}
        assignSection = {assignSection}
        initSection={initSection}
        flagNameErrorSection={flagNameErrorSection}
        nameSectionHelperText={nameSectionHelperText}
        flagSelectErrorSection={flagSelectErrorSection}
        selectSectionHelperText={selectSectionHelperText}/>

      <NotificationDialog 
        open={showNotificationDialog} 
        handleClose={handleCloseNotificationDialog}
        onChangeTitle={handleTitleNotification}
        valueTitle={titleNotification}
        onChangeDetail={handleDetailNotification}
        valueDetail={detailNotification}
        sendNotification={handleSendNotification}
        flag={flagResultNotification}
        detailFlagError={detailFlagError}
        detailHelperText={detailHelperText}
        titleFlagError={titleFlagError}
        titleHelperText={titleHelperText}
      />
      <UnauthorizedDialog open={showUnauthorized} />
      <PasswordDialog open={openPassword} handleClose={handleClosePassword} init={initViewPassword}/>
    </div>
  );
}
