import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ConstantText from '../../config/ConstantText';

const Slider = () => {
    return (
        <Carousel autoPlay infiniteLoop >
            <div>
                <img src={ConstantText.LINK_SLIDER_1} /*src={process.env.PUBLIC_URL + '/images/pan__slider1-min.png'} */ />
                <p className="legend hideDiv">Legend 1</p>
            </div>
            <div>
                <img src={ConstantText.LINK_SLIDER_2} /*src={process.env.PUBLIC_URL + '/images/pan__slider2-min.png'} */ />
                <p className="legend hideDiv">Legend 2</p>
            </div>
            <div>
                <img src={ConstantText.LINK_SLIDER_3} /*src={process.env.PUBLIC_URL + '/images/pan__slider3-min.png'}*/  />
                <p className="legend hideDiv">Legend 2</p>
            </div>
        </Carousel>
    )
}

export default Slider
