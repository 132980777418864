import React from 'react';
import { Grid } from '@material-ui/core';
import '../login/Login.css'
import { Redirect } from 'react-router-dom';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
//import InputTextCustom from '../inputs/InputTextCustom';
//import ButtonRevealPassword from '../buttons/ButtonRevealPassword';
import IconUser from '../icons/iconUser';
import ButtonLogin from '../buttons/ButtonLogin';
import UrlBase from '../../config/UrlBase';
import Key from '../../apis/Key';
import Endpoint from '../../apis/Endpoint';
import Token from '../../config/Token';
import KeyValue from '../../apis/KeyValue';
import InputText from '../inputs/InputText';
import MailIcon from '../icons/IconMail';
import ButtonReturnRegister from '../buttons/ButtonReturnRegister';
import ButtonSearch from '../buttons/ButtonSearch';
//import RecoverPassword from "../dialogs/RecoverPassword";
import UrlBase_Prod from '../../config/UrlBase_Prod';
import ReactGA from 'react-ga';
import ConstantKey from '../../config/ConstantKey';

export default function ForgetPassword() {
    const [username, setUsername] = React.useState('');
    const [mail, setMail] = React.useState('');
    const [usernameError, setUsernameError] = React.useState(false);
    const [usernameHelperText, setUsernameHelperText] = React.useState(KeyValue.ERROR_EMPTY);
    const [mailError, setMailError] = React.useState(false);
    const [mailHelperText, setMailHelperText] = React.useState(KeyValue.ERROR_EMPTY);
    const [goLogin, setGoLogin] = React.useState(false);
    //const [goRegister, setGoRegister] = React.useState(false);
    const [goHome, setGoHome] = React.useState(false);
    //---
    //const [isRevealPassword, setIsRevealPassword] = React.useState(false)
    
    //----loader----
    const [loading, setLoading] = React.useState(false);
    //---message error-----
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);    
    //---------
    const handleClose = () => {
        setShow(false)
        setGoLogin(true)       
    };
    const ShowDialogMessage = (message) =>{
        setShow(true);
        setMessageError(message);
    }
    const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
        //const URL_BASE = UrlBase.URL_BASE_SECURITY_SERVICE;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_SECURITY_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_SECURITY_SERVICE;
        }

        const paramsHeadersPost = {
          method:typeCall,
          mode: 'cors',
          headers: {
              'Accept':'application/json',
              //'Authorization': 'Bearer ' + tokenLocalStorage,
              'ApiKey': Key.API_KEY_SECURITY_SERVICE,
              'Content-type': 'application/json'
          },
          body: JSON.stringify(obj)
        }
        const paramsHeadersGet = {
          method:typeCall,
          mode: 'cors',
          headers: {
              'Accept':'application/json',
              //'Authorization': 'Bearer ' + tokenLocalStorage,
              'ApiKey': Key.API_KEY_SECURITY_SERVICE,
              'Content-type': 'application/json'
          }
        }
        const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
        const URL = URL_BASE+methodAction;    
        setLoading(true);    
          try{
              fetch(URL,header).then(function(res){
                  return res.json();
              }).then(function(response){    
                setLoading(false);              
                if(response.Code === 0){
                    SetConfigResponse(response,typeAction);                    
    
                }else if (response.Code === 1){
                    errorCode1(response);
            
                }else if (response.Code === 2){
                    errorCode2(response);

                }else if (response.Code === 4){
                    errorCode4(response);

                }
              }).catch((error) =>{
                setLoading(false);
                console.log(error);
              })
          }catch(e){
            setLoading(false);
            console.log(e);
          }                 
    }
    const SetConfigResponse = (response,typeAction)=>{
        if(typeAction === KeyValue.ACTION_FORGET_PASSWORD){
            ResponseForgetPassword(response);
        }
    }
    const errorCode1 = (response) =>{
        var message = "";
        if(response.Data.StatusCode === 500){
            ShowDialogMessage(response.Data.Message);
        }
        else if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element}.`
            });
            ShowDialogMessage(message);                        
        }
    }
    const errorCode2 = (response) =>{
        var message = "";
        if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element.FieldName}. ${element.Message}. `
            });
            ShowDialogMessage(message);                        
        }
    }
    const errorCode4 = (response) =>{
        var message = `StatusCode: ${response.Data.StatusCode}. ${response.Data.Message}`;
        ShowDialogMessage(message);        
    }
    //------------- INIT APP ----------------------------
    React.useEffect(() =>{   
        ReactGA.initialize(ConstantKey.REACT_GA_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);      
      },[])

    //---------- MANEJADORES DE EVENTOS ------------------
    const handleChangeUsername = (event) => {
        var user = event.target.value;
        if(user !== ""){
            setUsername(user.toString());
            setUsernameError(false);
        }else{
            setUsername("");
            setUsernameError(true);
        }        
    };
    const handleChangeMail = (event) => {
        var text = event.target.value;
        if(text !== ""){
            setMail(text);
            setMailError(false);
        }else{
            setMail('');
            setMailHelperText(KeyValue.ERROR_EMPTY)
            setMailError(true);
        }        
    };
    const validateFields = () =>{            
        var countEmpty = 0;
        if(username === ""){
            setUsernameError(true);
            countEmpty++;
        }
        if(mail === ""){
            setMailError(true);
            countEmpty++;
        }

        if(!validateFormatEmail(mail)){
            setMailError(true);
            setMailHelperText("Formato incorrecto de correo.")
            countEmpty++;
        }

        if(countEmpty === 0){
            const obj = {
                Usuario: username,
                CorreoElectronico: mail,
                ApplicationId: 1,
            }
            PostForgetPassword(obj);
        }
    };
    const handleRedirectHome = () =>{
        setGoHome(true);
    };
    function validateFormatEmail (email) {
        let RegEx = /[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
        if(email.match(RegEx)){
            return true;
        }
        else{
          return false;
        }
    }
    //---------- FORGET PASSWORD -----------------------------------    
    const PostForgetPassword = (obj) =>{
        const methodAction = Endpoint.FORGET_PASSWORD;
        asyncCallMethod('post',obj,methodAction,KeyValue.ACTION_FORGET_PASSWORD);
    };
    const ResponseForgetPassword = (response) =>{
        //console.log('ResponseForgetPassword');
        //console.log(response);
        if (response.Data){
            ShowDialogMessage(response.Message); 
        }else{
            ShowDialogMessage(response.Message); 
        }
    };
    const handleReturnLogin = () =>{
        setGoLogin(true)
    }
    //----------- RENDERS ------------------

    if(goLogin){
        return <Redirect to="/login" />
    }    
    if(goHome){
        return <Redirect to="/" />
    }
  
    return (
        <div>
            <img 
                src={process.env.PUBLIC_URL + '/images/logo_SD_new.png'} 
                className="widthLogoStoDomingo"
                style={{marginLeft:'unset',cursor:'pointer'}}
                alt="login"
                onClick={handleRedirectHome}
            />
            <div className="imageFull">  
                
                <div id="divLogin" className="centerVertical-hijo" style={usernameError ? (mailError ? ({minHeight:400}): ({minHeight:380})) : (mailError ? ({minHeight:380}): ({minHeight:356}))}>
                    <Grid container justify="center" alignItems="center" spacing={1}>
                        <Grid item xs={12} style={{textAlign: "center",marginTop:10}}>                            
                            <h4 style={{color:'#333333',fontFamily:'Montserrat-SemiBold'}}>Recuperar contraseña</h4>
                        </Grid>
                        {/**mensaje de informacion de la contraseña */}
                        <Grid item xs={12} style={{marginBottom:0}}>
                            <p 
                                style={{
                                    fontFamily:'Montserrat-Regular',
                                    color:'#1A1A1A',
                                    fontSize:'11pt',
                                    marginLeft:'auto',
                                    marginRight:'auto',
                                    marginBottom:15,
                                    width:'69%',
                                    textAlign:'justify'}}
                                    >
                                        Ingrese su usuario y correo electrónico para enviar
                                        su nueva contraseña.
                                    </p>                                               
                        </Grid>

                        <Grid item xs={12}>
                            <InputText
                                flagError={usernameError}
                                label={"Usuario"}
                                helperText={usernameHelperText}
                                onChange={handleChangeUsername}
                                maxLength={50}
                            />
                            <IconUser isFocus={usernameError} />
                        </Grid>

                        <Grid item xs={12}>
                            <InputText
                                flagError={mailError}
                                label={"Correo"}
                                helperText={mailHelperText}
                                onChange={handleChangeMail}
                                maxLength={100}
                            />
                            <MailIcon isFocus={mailError} />
                        </Grid>

                        <Grid item xs={6} style={{marginTop:10}} >
                            <ButtonReturnRegister  //ButtonReturnRegister
                                name={'volver'}
                                handleClick={handleReturnLogin}                            
                            />  
                        </Grid>

                        <Grid item xs={6} style={{marginTop:10}} >
                            <ButtonSearch  //ButtonSearch
                                id={'idBtnContinue1'}
                                name={'Enviar'}
                                handleClick={validateFields}                            
                            />
                        </Grid>
                        <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
                        <Progress open={loading} />
                    </Grid>        
                </div>           

            </div>            
        </div>        
    );
}

