const Endpoint = {
    LOGIN: 'Auth/Login',
    LOGOUT: 'Auth/Logout',
    GET_LIST_BOOKS: 'libro/getlibros/',
    GET_LIST_CHAPTERS_BY_BOOK: 'libro/GetCapitulos/',
    GET_LIST_THEMES_BY_CHAPTER: 'libro/GetTemas/',
    GENERATE_KEY: 'Auth/GenerateKey',
    GET_CONTENT_BOOK: 'File/GetContentBook',
    GET_NOTIFICATIONS: 'Notificacion/GetNotificaciones',
    INSERT_NOTIFICATION: 'Notificacion/InsertarNotificacion',
    CREATE_USER: 'Auth/CreateUser',
    GET_ACTIVE_CODE: 'Libro/ValidateCodeActivation?CodeActivation=',
    GET_VALIDATE_CODE: 'Libro/ValidateCodeBook?CodeBook=',
    GET_LIST_CLASSROOM: 'Colegio/GetListaAulaColegio',
    GET_CLASSROOM_BY_SECTION: 'Colegio/GetListaColegioGrado/',
    GET_MESSAGE_WELCOME: 'Mensaje/GetMessageWelcome',
    UPDATE_FLAG_WELCOME: 'Mensaje/UpdateFlagWelcome',
    INSERT_NOTIFICATION: 'Notificacion/InsertarNotificacion',
    UPDATE_STUDENTS_SECTION: 'Colegio/UpdateAlumnosSeccion',
    GET_ALL_SECTIONS: 'Colegio/GetListaSecciones',
    GET_RESOURCES_BY_THEMES: 'Recurso/GetRecursos/',
    GET_RESOURCES_MATERIAL_TEACHER: 'Libro/GetMaterialDocente/',
    GET_DATA_PROFILE: 'Usuario/PerfilUsuario',
    UPDATE_PROFILE: 'Usuario/ActualizarPerfilUsuario',
    FILE_UPLOAD: "File/Upload",
    UPDATE_PASSWORD: "Auth/UpdatePasswordUser",
    FORGET_PASSWORD: "Auth/GetValidCorreoElectronico",
    //------------
    LIST_SCHOOLS: 'Colegio/GetListaColegio',
    LIST_TYPE_USER: 'Usuario/GetListaTipoUsuario',
    LIST_DISTRICT: 'Ubigeo/GetListaDistrito/',
    LIST_PROVINCE: 'Ubigeo/GetListaProvincia/',
    LIST_DEPARTMENT: 'Ubigeo/GetListaDepartamento',
    LIST_GRADE: 'Colegio/GetListaGrados',
    //-----
    REGISTER_PROGRESS: 'Progreso/RegistrarProgreso',
    //----
    GET_LOMO_BY_CODE: 'Usuario/LomoByCode/',
    POST_LOMO_BY_CODE: 'Usuario/SaveLomo',
    POST_VALID_DNI_USER: 'Auth/ValidDniRegister',
    POST_VALID_EMAIL_USER: 'Auth/ValidEmailRegister',
    //--------------
    GET_LOTTERY: 'Mensaje/GetLottery'
};

export default Endpoint