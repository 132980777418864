import React from 'react'
import { makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';

const useStyles_fab = makeStyles((theme) => ({
    align: {
        textAlign: "right"
    },
    root: {
        '& > *': {
        margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));


const ButtonAccess = (props) => {
    const classes_fab = useStyles_fab();
    return (
        <div className={classes_fab.root, classes_fab.align}>
            <Button
                id={'idBtnAccess'}
                variant="outlined" 
                color="primary"
                onClick={props.handleClick} 
                style={{width:200}}
            >        
                {props.name}
            </Button>
        </div>
    )
}

export default ButtonAccess
