import React from 'react'
import { makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles_fab = makeStyles((theme) => ({
    align: {
        textAlign: "center"
    },
    root: {
        '& > *': {
        margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));


const ButtonLogin = (props) => {
    const classes_fab = useStyles_fab();
    return (
        <div className={classes_fab.root, classes_fab.align}>
            <Button
                id={props.id}
                variant="contained"
                onClick={props.handleClick} 
                style={{width:129,textTransform:'capitalize',fontSize:'15pt',fontFamily:'Montserrat-Regular !important'}}
            >        
                {props.name}
            </Button>
        </div>
    )
}

export default ButtonLogin