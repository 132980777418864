import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
//import CardContent from '@material-ui/core/CardContent';
//import Card from '@material-ui/core/Card';
//import Typography from '@material-ui/core/Typography';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import UrlBase from '../../config/UrlBase';
import KeyValue from '../../apis/KeyValue';
import Endpoint from '../../apis/Endpoint';
import Token from '../../config/Token';
//import { Redirect } from 'react-router';
import '../accordion/Accordion.css';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
//import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Divider from '@material-ui/core/Divider';
import './MyNotifications.css';
import Avatar from '@material-ui/core/Avatar';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';
import UrlBase_Prod from '../../config/UrlBase_Prod'

const useStylesTab = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'white',
    },
    rootTab: {
        flexGrow: 1,
        //width: '80%',
        backgroundColor: theme.palette.background.paper,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const MyNotifications = () => {
    //const classes = useStyles();
    const [arrListNotifications, setArrListNotifications] = React.useState([]);
    const classesTab = useStylesTab();
    const [expanded, setExpanded] = React.useState(false);
    const [value, setValue] = React.useState('1');


    const handleChangeTab = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    //------------------------flag vencimiento de token------------------------------
    const [showUnauthorized, setShowUnauthorized] = React.useState(false);

    //----loader----
    const [loading, setLoading] = React.useState(false);
    //---message error-----
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);    
    //------------------
    const handleClose = () => {
        setShow(false)       
    };
    const ShowDialogMessage = (message) =>{
        setShow(true);
        setMessageError(message);
    }
    //----------- impl method async ------------------------------------
    const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
        //const URL_BASE = UrlBase.URL_BASE_PORTAL_SERVICE;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_PORTAL_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_PORTAL_SERVICE;
        }
        const tokenLocalStorage = localStorage.getItem(Token.TOKEN)
        const paramsHeadersPost = {
            method:typeCall,
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }
        const paramsHeadersGet = {
            method:typeCall,
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            }
        }
        const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
        const URL = URL_BASE+methodAction;    
        setLoading(true);
        try{
            fetch(URL,header).then(function(res){
                if(res.status === 401){
                    return 401;
                  }
                return res.json();
            }).then(function(response){    
                setLoading(false);
                
                if(response === 401){
                    setShowUnauthorized(true);
                    
                }else if(response.Code === 0){
                    SetConfigResponse(response,typeAction);                    

                }else if (response.Code === 1){
                    errorCode1(response);
            
                }else if (response.Code === 2){
                    errorCode2(response);        
                }
            }).catch((error) =>{
                setLoading(false);
                console.log(error);
            })
        }catch(e){
            setLoading(false);
            console.log(e);
        }                 
    }
    const SetConfigResponse = (response,typeAction)=>{
        if(typeAction === KeyValue.ACTION_GET_NOTIFICATIONS){
            ResponseListNotifications(response);
        }
    }
    const errorCode1 = (response) =>{
        var message = "";
        if(response.Data.StatusCode === 500){
            ShowDialogMessage(response.Data.Message);
        }
        else if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element}.`
            });
            ShowDialogMessage(message);                        
        }
    }
    const errorCode2 = (response) =>{
        var message = "";
        if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element.FieldName}. ${element.Message}. `
            });
            ShowDialogMessage(message);                        
        }
    }
    //------------------------------------------------------------------
    React.useEffect(() => {
        console.log('dimensiones')
        console.log(window.screen.height)
        console.log(window.screen.width)
        GetNotifications();
    },[])

    const GetNotifications = () =>{
        const methodAction = Endpoint.GET_NOTIFICATIONS;
        asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_NOTIFICATIONS);
    }
    const ResponseListNotifications = (response) =>{
        console.log('response');
        console.log(response);
        setArrListNotifications(response.Data)
    }
    const visualizeNotifications = (e) =>{

    }

    return (
        <Grid container style={{overflowY:'auto',overflowX:'hidden',marginTop:10}} className="height-notif">
            {
                arrListNotifications.length > 0 ? (
                    arrListNotifications.map(item => 
                                                 
                        <Grid item xs={12} md={10}>
                            <div className={classesTab.rootTab + " divTabContext"} style={{marginTop: 5,marginBottom:5,borderRadius:10,background:'transparent'}}>
                                <TabContext value={value} className="borderTabContext">
                                    <AppBar position="static" className="tabHeader" >
                                        <Tab
                                            //id='tabMainId'
                                            label={`${item.NombreGrado} ${item.NombreSeccion} - ${item.NombreCurso}`}
                                            //value="1"
                                            className="alignRigthIconMessage tabMainId"
                                            icon={<Avatar alt="test avatar" src={process.env.PUBLIC_URL + "/images/ico_mis_mensajes.png"}/>} />
                                        
                                                                 
                                    </AppBar>                    
                                    <TabPanel value="1" style={{padding:0}}>
                                        <div className={classesTab.root} style={{padding:10,borderRadius:5}}>  

                                            <Accordion expanded={expanded === false} onChange={handleChangeTab('panel1')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="accordionTt"
                                                    className="customClass"
                                                >
                                                    <Grid container >
                                                        <Grid item md={10} xs={12} style={{marginBottom:15,textAlign:'left'}} >
                                                            <h6 style={{paddingTop:5,marginBottom:0,fontFamily:'Montserrat-SemiBold'}}>{item.Titulo}</h6>
                                                        </Grid>
                                                        <Grid item md={2} xs={12} className="responsive-title-date-notif" >
                                                        <h6 style={{paddingTop:5,marginBottom:0,fontFamily:'Montserrat-SemiBold'}}>{item.CFechaCreacion}</h6>
                                                        </Grid>
                                                    </Grid>                                                    
                                                    
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container>
                                                        <Grid item xs={12} style={{marginBottom:15}} >
                                                            <Divider />
                                                        </Grid>
                                                        <Grid item xs={12} style={{textAlign:'left'}}>
                                                            <p style={{marginBottom:0,textAlign:'justify',fontFamily:'Montserrat-Regular'}}>{item.Mensaje}</p><br/>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>

                                            
                                        </div>
                                
                                    </TabPanel>
                                
                                </TabContext>
                            </div>
                        </Grid>
                                    
                    )
                ) : ('')
            }
            <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
            <Progress open={loading} />
            <UnauthorizedDialog open={showUnauthorized} />
        </Grid>
    )
}

export default MyNotifications
