    const ConstantText = {
        MESSAGE_WELCOME_TEACHER_1: 
            `   En esta plataforma tendrá acceso a todos los contenidos 
            que Editorial Bello Horizonte ha preparado para usted.`,
        MESSAGE_WELCOME_TEACHER_2: 
            `   Tener en cuenta que al trabajar con este contenido no quiere 
            decir que está autorizado a copiarlo o reproducirlo, por tanto, 
            la plataforma deberá aplicarse adecuadamente porque todos los 
            textos y materiales que abarca tiene como derecho de autor a 
            Editorial Bello Horizonte S.A.C.`,
        MESSAGE_WELCOME_TEACHER_3: 
            `   Usar este contenido inadecuadamente o sin la autorización de 
            su autor significaría que usted está cometiendo un delito, 
            por esta razón, agradeceremos no copiar ni reproducir la información.`,
        MESSAGE_WELCOME_TEACHER_4: 
            `   Al ingresar el código significa que usted ha leído, entendido y 
            está de acuerdo con este mensaje, por lo que se compromete a no 
            infringir de ninguna forma los derechos de autor de los contenidos.`,

        TITLE_TO_ACCESS_BOOK_TEACHER: 'Acceso a los libros',

        DETAIL_ACCESS_BOOK_TEACHER: 
            `   Recuerde que el código de su libro fue enviado a su correo personal. 
            Una vez ingresado y validado, usted dispondrá de todo su material hasta 
            el 31 de enero del 2023.`,
        MESSAGE_WELCOME_STUDENT_1: 
            `   En esta plataforma tendrás acceso a todos los 
            contenidos que Editorial Bello Horizonte ha preparado para ti.`,
        MESSAGE_WELCOME_STUDENT_2:
            `   Tener en cuenta que al trabajar con este contenido no quiere 
            decir que estas autorizado a copiarlo o reproducirlo, por tanto, 
            la plataforma deberá aplicarse adecuadamente porque todos los textos 
            y materiales que abarca tiene como derecho de autor
            a Editorial Bello Horizonte S.A.C.`,
        MESSAGE_WELCOME_STUDENT_3:
            `   Usar este contenido inadecuadamente o sin la autorización de su autor 
            significaría que estas cometiendo un delito, por esta razón, agradeceremos 
            no copiar ni reproducir la información.`,
        MESSAGE_WELCOME_STUDENT_4:
            `   Al ingresar el código significa que tú y tu apoderado han leído, 
            entendido y están de acuerdo con este mensaje, por lo que se comprometen 
            a no infringir de ninguna forma los derechos de autor de los contenidos.`,

        TITLE_TO_ACCESS_BOOK_STUDENT: 'Acceso a los libros',
        DETAIL_ACCESS_BOOK_STUDENT: `   Recuerda que para acceder a tus libros debes haber adquirido tus libros físicos, 
            los códigos están ubicados en la parte posterior de una tarjeta adherida a tu kit 
            y en el lomo de tu libro de actividades. Una vez ingresados y validados, dispondrás 
            de todo tu material hasta el 31 de enero del 2023.`,
        LINK_HOME_WHATSAPP: 'https://api.whatsapp.com/send?phone=51962705732',
        LINK_HOME_FACEBOOK: 'https://www.facebook.com/GrupoEditorialSantoDomingoOficial/',
        LINK_HOME_YOUTUBE: 'https://www.youtube.com/channel/UC0ZQkm5xB9t8Y3_RQsohOPg/featured',
        LINK_HOME_CORREO: 'plataformadigitalsd@gmail.com',
        
        LINK_SLIDER_1: 'https://stodmg-files-prd.s3.us-east-2.amazonaws.com/ImagesSystem/Images/slider_bh_01.png',
        LINK_SLIDER_2: 'https://stodmg-files-prd.s3.us-east-2.amazonaws.com/ImagesSystem/Images/slider_bh_02.png',
        LINK_SLIDER_3: 'https://stodmg-files-prd.s3.us-east-2.amazonaws.com/ImagesSystem/Images/slider_bh_03.png',
        
    }
    export default ConstantText;