import React from 'react';
import { Grid } from '@material-ui/core';
import './Login.css'
import { Redirect } from 'react-router-dom';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import InputTextCustom from '../inputs/InputTextCustom';
import ButtonRevealPassword from '../buttons/ButtonRevealPassword';
import IconUser from '../icons/iconUser';
import ButtonLogin from '../buttons/ButtonLogin';
import UrlBase from '../../config/UrlBase';
import UrlBase_Prod from '../../config/UrlBase_Prod'
import Key from '../../apis/Key';
import Endpoint from '../../apis/Endpoint';
import Token from '../../config/Token';
import KeyValue from '../../apis/KeyValue';
import InputText from '../inputs/InputText';
import InputPassword from '../inputs/InputPassword';
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';
import ConstantKey from '../../config/ConstantKey';


export default function Login() {
    let history = useHistory();
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [usernameError, setUsernameError] = React.useState(false);
    const [usernameHelperText, setUsernameHelperText] = React.useState(KeyValue.ERROR_EMPTY);
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordHelperText, setPasswordHelperText] = React.useState(KeyValue.ERROR_EMPTY);
    const [goMain, setGoMain] = React.useState(false);
    const [goRegister, setGoRegister] = React.useState(false);
    const [goHome, setGoHome] = React.useState(false);
    //---
    const [isRevealPassword, setIsRevealPassword] = React.useState(false)
    
    //----loader----
    const [loading, setLoading] = React.useState(false);
    //---message error-----
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);    
    //---------
    const handleClose = () => {
        setShow(false)       
    };
    const ShowDialogMessage = (message) =>{
        setShow(true);
        setMessageError(message);
    }
    const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_SECURITY_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_SECURITY_SERVICE;
        }
        //const URL_BASE = UrlBase.URL_BASE_SECURITY_SERVICE;
        const paramsHeadersPost = {
          method:typeCall,
          mode: 'cors',
          headers: {
              'Accept':'application/json',
              //'Authorization': 'Bearer ' + tokenLocalStorage,
              'ApiKey': Key.API_KEY_SECURITY_SERVICE,
              'Content-type': 'application/json'
          },
          body: JSON.stringify(obj)
        }
        const paramsHeadersGet = {
          method:typeCall,
          mode: 'cors',
          headers: {
              'Accept':'application/json',
              //'Authorization': 'Bearer ' + tokenLocalStorage,
              'ApiKey': Key.API_KEY_SECURITY_SERVICE,
              'Content-type': 'application/json'
          }
        }
        const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
        const URL = URL_BASE+methodAction;    
        setLoading(true);    
          try{
              fetch(URL,header).then(function(res){
                  return res.json();
              }).then(function(response){    
                setLoading(false);              
                if(response.Code === 0){
                    SetConfigResponse(response,typeAction);                    
    
                }else if (response.Code === 1){
                    errorCode1(response);
            
                }else if (response.Code === 2){
                    errorCode2(response);        
                }
              }).catch((error) =>{
                setLoading(false);
                console.log(error);
              })
          }catch(e){
            setLoading(false);
            console.log(e);
          }                 
    }
    const SetConfigResponse = (response,typeAction)=>{
        if(typeAction === 1){
            ResponseLogin(response);
        }
    }
    const errorCode1 = (response) =>{
        var message = "";
        if(response.Data.StatusCode === 500){
            ShowDialogMessage(response.Data.Message);
        }
        else if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element}.`
            });
            ShowDialogMessage(message);                        
        }
    }
    const errorCode2 = (response) =>{
        var message = "";
        if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element.FieldName}. ${element.Message}. `
            });
            ShowDialogMessage(message);                        
        }
    }
    //-----------INIT APP -----------------------------
    React.useEffect(() =>{
        localStorage.clear();
          
        ReactGA.initialize(ConstantKey.REACT_GA_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);      
    },[])
    //---------- MANEJADORES DE EVENTOS ------------------
    const handleChangeUsername = (event) => {
        var user = event.target.value;
        if(user !== ""){
            setUsername(user.toString());
            setUsernameError(false);
        }else{
            setUsername("");
            setUsernameError(true);
        }        
    };
    const handleChangePassword = (event) => {
        var pass = event.target.value;
        if(pass !== ""){
            setPassword(pass);
            setPasswordError(false);
        }else{
            setPassword('');
            setPasswordError(true);
        }        
    };
    const togglePassword = () =>{
        setIsRevealPassword(!isRevealPassword);
    };
    const validateFields = () =>{
        //setGoMain(true);
        
        var countEmpty = 0;
        if(username === ""){
            setUsernameError(true);
            countEmpty++;
        }
        if(password === ""){
            setPasswordError(true);
            countEmpty++;
        }
        if(countEmpty === 0){
            const obj = {
                UserLogin: username,
                Password: password,
                AplicationId: 1,
            }
            PostLogin(obj);
        }
    }; 
    const handleRegister = () => {
        setGoRegister(true);
    };
    const handleRedirectHome = () =>{
        setGoHome(true);
    };
    const handleRedirectForgetPassword = () =>{
        history.push("/forgetPassword");
    }
    //---------- DO LOGIN -----------------------------------    
    const PostLogin = (obj) =>{
        const methodAction = Endpoint.LOGIN;
        asyncCallMethod('post',obj,methodAction,1);
    };
    const ResponseLogin = (response) =>{
        //console.log(response);
        if (response.Data.Success){
            setDataLocal(response);
            setGoMain(true);
        }else{
            let message = '';
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element}. `
            });
            ShowDialogMessage(message); 
        }
    };
    const setDataLocal = (response) =>{
        //console.log(response);
        localStorage.setItem(Token.TOKEN, response.Data.Token);
        localStorage.setItem(Token.REFRESH_TOKEN, response.Data.RefreshToken);

        const tokenDecode = parseJwt(response.Data.Token);
        //console.log(tokenDecode);
        localStorage.setItem(Token.TYPE_USER ,tokenDecode.TipoUsuario);
        localStorage.setItem(Token.NAME_USER,tokenDecode.sub);
        localStorage.setItem(Token.UNIQUE_NAME,tokenDecode.unique_name);
        localStorage.setItem(Token.IS_WELCOME,tokenDecode.IsWelcome);
        localStorage.setItem(Token.GIVEN_NAME,tokenDecode.given_name);
    };
    const parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        //console.log(base64)
        //console.log(b64DecodeUnicode(base64));
        return JSON.parse(b64DecodeUnicode(base64));
    };
    const b64DecodeUnicode = (str)  => {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }
    //----------- RENDERS ------------------

    if(goMain){
        return <Redirect to="/Main" />
    }
    if(goRegister){
        return <Redirect to="/choiceProfile" />
    }
    if(goHome){
        return <Redirect to="/" />
    }
    //console.log("process.env.NODE_ENV")
    //console.log(process.env.NODE_ENV)
  
    return (
        <div>
            <img 
                src={process.env.PUBLIC_URL + '/images/logo_SD_new.png'} 
                className="widthLogoStoDomingo"
                style={{marginLeft:'unset',cursor:'pointer'}}
                alt="login"
                onClick={handleRedirectHome}
            />
            <div className="imageFull">  
                <Grid container>                
                    <Grid item xs={12}>
                        <div id="divLogin" className="centerVertical-hijo">
                            <Grid container justify="center" alignItems="center" spacing={1}>
                                <Grid item xs={12} style={{textAlign: "center",marginTop:10}}>                            
                                    <h4 style={{color:'#333333',fontFamily:'Montserrat-SemiBold'}}>Ingreso</h4>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputText
                                        flagError={usernameError}
                                        label={"Usuario"}
                                        helperText={usernameHelperText}
                                        onChange={handleChangeUsername}
                                        maxLength={40}
                                    />
                                    <IconUser isFocus={usernameError} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputPassword
                                        id={"idPassLogin"}
                                        className={"divCenterPassword"}
                                        label="Contraseña"
                                        onChange={handleChangePassword}
                                        passwordError={passwordError}
                                        passwordHelperText={passwordHelperText}
                                    />
                                </Grid>

                                <Grid item xs={12} style={{marginTop:10}}>
                                    <p style={{textDecoration:'underline', color:'#115ed1',cursor:'pointer', fontFamily:'Montserrat-Regular'}} onClick={handleRedirectForgetPassword}>¿Olvidaste tu contraseña?</p>
                                </Grid>

                                <Grid item xs={12} >
                                    <ButtonLogin
                                        id={'idBtnLogin'}
                                        name={'Entrar'}
                                        handleClick={validateFields}            
                                    />
                                </Grid>
                                <Grid item xs={12} style={usernameError ? (passwordError ? ({marginTop:5}) : ({marginTop:18})) : (passwordError ? ({marginTop:5}) : ({marginTop:38}))}>
                                    <p style={{color:'#1A1A1A',fontFamily:'Montserrat-Regular'}}>¿Eres nuevo en el sitio? <span style={{textDecoration:'underline',cursor:'pointer',color: '#115ed1',fontFamily:'Montserrat-Regular'}} onClick={handleRegister}>Regístrate</span></p>
                                </Grid>
                                <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
                                <Progress open={loading} />
                            </Grid>        
                        </div>
                    </Grid>
                </Grid>            
            </div>            
        </div>        
    );
}
