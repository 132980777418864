import React from 'react'
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import ButtonSearch from '../buttons/ButtonSearch';
import TextField from '@material-ui/core/TextField';
import CheckCustom from '../inputs/CheckCustom';
import './Register.css';
import { Redirect } from 'react-router-dom';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import UrlBase from '../../config/UrlBase';
import Key from '../../apis/Key';
import Endpoint from '../../apis/Endpoint';
import ButtonReturnRegister from '../buttons/ButtonReturnRegister';
import KeyValue from '../../apis/KeyValue';
import InputPassword from '../inputs/InputPassword';
import InputText from '../inputs/InputText';
import SelectCustom from '../select/SelectCustom';
import InputNumber from '../inputs/InputNumber';
import UrlBase_Prod from '../../config/UrlBase_Prod';
import ReactGA from 'react-ga';
import ConstantKey from '../../config/ConstantKey';

const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },    
  });

const Register = () => {
    const classes = useStyles();

    const [terms,setTerms] = React.useState(false)
    const [name, setName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [numDoc, setNumDoc] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [schoolId, setSchoolId] = React.useState('0')
    const [departmentId, setDepartmentId] = React.useState('0')
    const [provinceId, setProvinceId] = React.useState('0')
    const [districtId, setDistrictId] = React.useState('0')
    const [typeUser, setTypeUser] = React.useState('')
    const [userName, setUserName] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [confirmPassword,setConfirmPassword] = React.useState('')
    const [arrListTypeUsers, setArrListTypeUsers] = React.useState([]);
    const [arrListDepartment, setArrListDepartment] = React.useState([]);
    const [arrListProvince, setArrListProvince] = React.useState([]);
    const [arrListDistrict, setArrListDistrict] = React.useState([])
    const [arrListSchools, setArrListSchools] = React.useState([]);
    const [goViewWelcomeUser, setGoViewWelcomeUser] = React.useState(false);
    const [returnLogin, setReturnLogin] = React.useState(false);
    const [selectedTypeUser, setSelectedTypeUser] = React.useState("");
    //-----------------------------------------------------------------
    const [nameFlagError, setNameFlagError] = React.useState(false);
    const [nameHelperText, setNameHelperText] = React.useState('');

    const [lastnameFlagError, setLastnameFlagError] = React.useState(false);
    const [lastnameHelperText, setLastnameHelperText] = React.useState('');

    const [docFlagError, setDocFlagError] = React.useState(false);
    const [docHelperText, setDocHelperText] = React.useState('');

    const [emailFlagError, setEmailFlagError] = React.useState(false);
    const [emailHelperText, setEmailHelperText] = React.useState('');

    const [schoolFlagError, setSchoolFlagError] = React.useState(false);
    const [schoolHelperText, setSchoolHelperText] = React.useState('');

    const [dptoFlagError, setDptoFlagError] = React.useState(false);
    const [dptoHelperText, setDptoHelperText] = React.useState('');

    const [provinceFlagError, setProvinceFlagError] = React.useState(false);
    const [provinceHelperText, setProvinceHelperText] = React.useState('');

    const [districtFlagError, setDistrictFlagError] = React.useState(false);
    const [districtHelperText, setDistrictHelperText] = React.useState('');

    const [nameUserFlagError, setNameUserFlagError] = React.useState(false);
    const [nameUserHelperText, setNameUserHelperText] = React.useState('');

    const [passwordFlagError, setPasswordFlagError] = React.useState(false);
    const [passwordHelperText, setPasswordHelperText] = React.useState('');

    const [passwordConfirmFlagError, setPasswordConfirmFlagError] = React.useState(false);
    const [passwordConfirmHelperText, setPasswordConfirmHelperText] = React.useState('');

    const [flagValidateErrorPassword, setFlagValidateErrorPassword] = React.useState(false);
    const [messageGlobalError, setMessageGlobalError] = React.useState('');

    //----loader----
    const [loading, setLoading] = React.useState(false);
    //---message error-----
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);    
    //---------
    const [hideBlock_1, setHideBlock_1] = React.useState(false);
    const [hideBlock_2, setHideBlock_2] = React.useState(true);
    const handleClose = () => {
        setShow(false)       
    };
    const ShowDialogMessage = (message) =>{
        setShow(true);
        setMessageError(message);
    }

    //----- config async method ------------------
    const asyncCallMethod = (typeCall,obj,methodAction,typeAction,urlBase,key) =>{
        const URL_BASE = urlBase;
        //const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN_LOYALTY)    
        //console.log(tokenLocalStorage);
        const paramsHeadersPost = {
          method:typeCall,
          mode: 'cors',
          headers: {
              'Accept':'application/json',
              //'Authorization': 'Bearer ' + tokenLocalStorage,
              'ApiKey': key,
              'Content-type': 'application/json'
          },
          body: JSON.stringify(obj)
        }
        const paramsHeadersGet = {
          method:typeCall,
          mode: 'cors',
          headers: {
              'Accept':'application/json',
              //'Authorization': 'Bearer ' + tokenLocalStorage,
              'ApiKey': key,
              'Content-type': 'application/json'
          }
        }
        const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
        const URL = URL_BASE+methodAction;    
        setLoading(true);    
          try{
              fetch(URL,header).then(function(res){
                  return res.json();
              }).then(function(response){    
                setLoading(false);              
                if(response.Code === 0){
                    SetConfigResponse(response,typeAction);                    
    
                }else if (response.Code === 1){
                    errorCode1(response);
            
                }else if (response.Code === 2){
                    errorCode2(response);        
                }
              }).catch((error) =>{
                setLoading(false);
                console.log(error);
              })
          }catch(e){
            setLoading(false);
            console.log(e);
          }      
    }
    const SetConfigResponse = (response,typeAction)=>{        
        if(typeAction === 1){
            ResponseListTypeUser(response.Data);
        }else if(typeAction === 2){
            ResponseListSchools(response.Data);
        }else if(typeAction === 3){
            ResponseListDepartment(response.Data);
        }else if(typeAction === 4){
            ResponseListProvinces(response.Data);
        }else if(typeAction === 5){
            ResponseListDistrict(response.Data);
        }else if (typeAction === 6)  {
            ResponseRegisterUser(response)
        }else if (typeAction === 7)  {
            ResponseValidEmailRegister(response)
        }else if (typeAction === 8)  {
            ResponseValidDniRegister(response)
        }
    }
    const errorCode1 = (response) =>{
        var message = "";
        if(response.Data.StatusCode === 500){
            ShowDialogMessage(response.Data.Message);
        }
        else if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element}.`
            });
            ShowDialogMessage(message);                        
        }
    }
    const errorCode2 = (response) =>{
        var message = "";
        if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element.FieldName}. ${element.Message}. `
            });
            ShowDialogMessage(message);                        
        }
    }
    //-------------Iniciando----------
    React.useEffect(()=>{
        ReactGA.initialize(ConstantKey.REACT_GA_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search); 
        GetListSchools()
    },[])

    const GetListSchools = () =>{
        const methodAction = Endpoint.LIST_SCHOOLS;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_PORTAL_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_PORTAL_SERVICE;
        }
        asyncCallMethod('get',null,methodAction,2,URL_BASE,Key.API_KEY_PORTAL_SERVICE);
    }
    const ResponseListSchools = (response) => {
        console.log(response)
        setArrListSchools(response.ListaColegios)
        GetListDepartment();
    }
    const GetListDepartment = () => {
        const methodAction = Endpoint.LIST_DEPARTMENT;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_SECURITY_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_SECURITY_SERVICE;
        }
        asyncCallMethod('get',null,methodAction,3,URL_BASE,Key.API_KEY_SECURITY_SERVICE);
    }
    const ResponseListDepartment = (response) =>{
        
        console.log(response);
        setArrListDepartment(response.ListaUbiDepartamentos)
        GetListTypeUsers();
    }
    const GetListTypeUsers = () =>{
        const methodAction = Endpoint.LIST_TYPE_USER;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_PORTAL_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_PORTAL_SERVICE;
        }
        asyncCallMethod('get',null,methodAction,1,URL_BASE,Key.API_KEY_PORTAL_SERVICE);
    }
    const ResponseListTypeUser = (response) => {
        console.log(response)
        setArrListTypeUsers(response.TiposUsuarios)
        setSelectedTypeUser(localStorage.getItem(KeyValue.CHOICE_PROFILE))
    }
    const GetListProvinces = (id) => {
        if(id < 10){
            id = '0' + id
        }
        const methodAction = Endpoint.LIST_PROVINCE + id;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_SECURITY_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_SECURITY_SERVICE;
        }
        asyncCallMethod('get',null,methodAction,4,URL_BASE,Key.API_KEY_SECURITY_SERVICE);
    }
    const GetListDistricts = (id) => {
        const methodAction = Endpoint.LIST_DISTRICT + id;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_SECURITY_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_SECURITY_SERVICE;
        }
        asyncCallMethod('get',null,methodAction,5,URL_BASE,Key.API_KEY_SECURITY_SERVICE);
    }
    const ResponseListProvinces = (response) => {
        //console.log('response provincias')
        //console.log(response)
        setArrListProvince(response.ListaUbiProvincias)
    }
    const ResponseListDistrict = (response) => {
        setArrListDistrict(response.ListaUbiDistritos)
    }
    const PostRegisterUser = (obj) => {
        const methodAction = Endpoint.CREATE_USER;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_SECURITY_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_SECURITY_SERVICE;
        }
        asyncCallMethod('post',obj,methodAction,6,URL_BASE,Key.API_KEY_SECURITY_SERVICE);
    }
    const ValidEmailRegister = (obj) => {
        const methodAction = Endpoint.POST_VALID_EMAIL_USER;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_SECURITY_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_SECURITY_SERVICE;
        }
        asyncCallMethod('post',obj,methodAction,7,URL_BASE,Key.API_KEY_SECURITY_SERVICE);
    }
    const ValidDniRegister = (obj) => {
        const methodAction = Endpoint.POST_VALID_DNI_USER;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_SECURITY_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_SECURITY_SERVICE;
        }
        asyncCallMethod('post',obj,methodAction,8,URL_BASE,Key.API_KEY_SECURITY_SERVICE);
    }
    const ResponseRegisterUser = (response) => {
        console.log(response);
        if(response.Data.Success){
            setGoViewWelcomeUser(true)
        }else{
            let message = '';
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element}. `
            });
            ShowDialogMessage(message); 
        }
    }
    const ResponseValidDniRegister = (response) => {
        console.log(response);
        if(response.Data.Success){
            var obj = {
                Email: email
            }
            ValidEmailRegister(obj)
        }else{
            let message = '';
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element}. `
            });
            ShowDialogMessage(message); 
        }
    }
    const ResponseValidEmailRegister = (response) => {
        console.log(response);
        if(response.Data.Success){
            setHideBlock_1(true)
            setHideBlock_2(false)
        }else{
            let message = '';
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element}. `
            });
            ShowDialogMessage(message); 
        }
    }
    //-----------manejadores de evento ----------------------
    const handleCreateUser = () => {
        
        var countEmpty = 0;
        if(userName === ""){
            setNameUserFlagError(true);
            setNameUserHelperText(KeyValue.ERROR_EMPTY)
            countEmpty++;
        }

        if(password === ""){
            setPasswordFlagError(true);
            setPasswordHelperText(KeyValue.ERROR_EMPTY)
            countEmpty++;
        }

        if(confirmPassword === ""){
            setPasswordConfirmFlagError(true);
            setPasswordConfirmHelperText(KeyValue.ERROR_EMPTY)
            countEmpty++;
        }

        if(terms === false){
            ShowDialogMessage(KeyValue.ERROR_CHECK_TERMS)
            countEmpty++;
        }

        if(countEmpty === 0){

            if(password !== confirmPassword){
                setFlagValidateErrorPassword(true);
                setMessageGlobalError(KeyValue.ERROR_PASSWORDS_NOT_EQUALS)
                countEmpty++;
            }else if (!foundCapitalLetters(password)) {
                setFlagValidateErrorPassword(true);
                setMessageGlobalError(KeyValue.ERROR_PASSWORDS_NOT_MAYUS)
                countEmpty++;
    
            }else if (!foundLowerCase(password)) {
                setFlagValidateErrorPassword(true);
                setMessageGlobalError(KeyValue.ERROR_PASSWORDS_NOT_MINUS)
                countEmpty++;
    
            }else if (!foundNumbers(password)) {
                setFlagValidateErrorPassword(true);
                setMessageGlobalError(KeyValue.ERROR_PASSWORDS_NOT_NUMBERS)
                countEmpty++;
    
            }else if (!foundNotAlphaNumeric(password)) {
                setFlagValidateErrorPassword(true);
                setMessageGlobalError(KeyValue.ERROR_PASSWORDS_NOT_ALPHANUMERIC)
                countEmpty++;
    
            }else if (!minLengthString(password)) {
                setFlagValidateErrorPassword(true);
                setMessageGlobalError(KeyValue.ERROR_PASSWORDS_NOT_LARGE)
                countEmpty++;
            }

            if(countEmpty === 0){

                const obj = {
                    Name: name,
                    LastName: lastName,
                    NumDoc: numDoc.toString(),
                    Email: email,
                    SchoolId: schoolId.toString(),
                    DepartmentId: departmentId.toString(),
                    ProvinceId: provinceId.toString(),
                    DistrictId: districtId.toString(),
                    TypeUser: localStorage.getItem(KeyValue.CHOICE_PROFILE).toString(),
                    UserName: userName,
                    Password: password,
                    ConfirmPassword: confirmPassword,
                    Terms: terms
                }
                console.log(obj);
                PostRegisterUser(obj);
            }            
        }        
    }
    const onChangeName = (e) =>{
        var text = e.target.value;
        if (text === ""){
            setNameFlagError(true);
            setNameHelperText(KeyValue.ERROR_EMPTY)
        }else{
            setNameFlagError(false);
            setNameHelperText("")
        }
        setName(text)
    }
    const onChangeLastname = (e) => {
        var text = e.target.value;
        if (text === ""){
            setLastnameFlagError(true);
            setLastnameHelperText(KeyValue.ERROR_EMPTY)
        }else{
            setLastnameFlagError(false);
            setLastnameHelperText("")
        }
        setLastName(text)
    }
    const onChangeNroDocument = (e) => {
        var text = e.target.value;
        if (text === ""){
            setDocFlagError(true);
            setDocHelperText(KeyValue.ERROR_EMPTY)
        }else {
            setDocFlagError(false);
            setDocHelperText("")
        }
        setNumDoc(text)
    }
    const onChangeMail = (e) => {
        var text = e.target.value;
        if (text === ""){
            setEmailFlagError(true);
            setEmailHelperText(KeyValue.ERROR_EMPTY)
        }else{
            setEmailFlagError(false);
            setEmailHelperText("")
        }
        setEmail(text)
    }
    const onChangeSchool = (e) => {
        var text = e.target.value;
        if (text === "0"){
            setSchoolFlagError(true);
            setSchoolHelperText(KeyValue.ERROR_SELECT_EMPTY)
        }else{
            setSchoolFlagError(false);
            setSchoolHelperText("")
        }
        setSchoolId(text)
    }
    const onChangeDepartament = (e) => {
        var text = e.target.value;
        if (text === "0"){
            setDptoFlagError(true);
            setDptoHelperText(KeyValue.ERROR_SELECT_EMPTY)
        }else{
            setDptoFlagError(false);
            setDptoHelperText("")
        }
        setDepartmentId(text)
        GetListProvinces(parseInt(text))
    }
    const onChangeProvincia = (e) => {
        var text = e.target.value;
        if (text === "0"){
            setProvinceFlagError(true);
            setProvinceHelperText(KeyValue.ERROR_SELECT_EMPTY)
        }else{
            setProvinceFlagError(false);
            setProvinceHelperText("")
        }
        setProvinceId(text)
        GetListDistricts(text)
    }
    const onChangeDistrit = (e) => {
        var text = e.target.value;
        if (text === "0"){
            setDistrictFlagError(true);
            setDistrictHelperText(KeyValue.ERROR_SELECT_EMPTY)
        }else{
            setDistrictFlagError(false);
            setDistrictHelperText("")
        }
        setDistrictId(text)
    }
    const onChangeTypeUser = (e) => {
        setTypeUser(e.target.value)
    }
    const onChangeNameUser = (e) => {
        var text = e.target.value;
        if (text === ""){
            setNameUserFlagError(true);
            setNameUserHelperText(KeyValue.ERROR_EMPTY)
        }else{
            setNameUserFlagError(false);
            setNameUserHelperText('')
        }
        setUserName(text)
    }
    const onChangePassword = (e) => {
        var text = e.target.value;
        if (text === ""){
            setPasswordFlagError(true);
            setPasswordHelperText(KeyValue.ERROR_EMPTY)
        }else{
            setPasswordFlagError(false);
            setPasswordHelperText('')
        }
        setFlagValidateErrorPassword(false)
        setMessageGlobalError('')
        setPassword(text)
    }
    const onChangeRepeatPassword = (e) => {
        var text = e.target.value;
        if (text === ""){
            setPasswordConfirmFlagError(true);
            setPasswordConfirmHelperText(KeyValue.ERROR_EMPTY)
        }else{
            setPasswordConfirmFlagError(false);
            setPasswordConfirmHelperText('')
        }
        setFlagValidateErrorPassword(false)
        setMessageGlobalError('')
        setConfirmPassword(text)
    }
    const onChangeCheckConditions = () => {
        setTerms(!terms)
    }    
    const handleReturnLogin = () => {
        setReturnLogin(true);
    }
    const handleNext = () => {
        var countEmpty = 0;
        if(name === ""){
            setNameFlagError(true);
            setNameHelperText(KeyValue.ERROR_EMPTY)
            countEmpty++;
        }
        if(lastName === ""){
            setLastnameFlagError(true);
            setLastnameHelperText(KeyValue.ERROR_EMPTY)
            countEmpty++;
        }
        if(numDoc === ""){
            setDocFlagError(true);
            setDocHelperText(KeyValue.ERROR_EMPTY)
            countEmpty++;
        }else if (numDoc.length < 8){
            setDocFlagError(true);
            setDocHelperText(KeyValue.ERROR_LENGTH)
            countEmpty++;
        }

        if(email === ""){
            setEmailFlagError(true);
            setEmailHelperText(KeyValue.ERROR_EMPTY)
            countEmpty++;
        }else if (!validateFormatEmail(email)){
            setEmailFlagError(true);
            setEmailHelperText(KeyValue.ERROR_FORMAT_EMAIL)
            countEmpty++;
        }

        if(schoolId === "0"){
            setSchoolFlagError(true);
            setSchoolHelperText(KeyValue.ERROR_SELECT_EMPTY)
            countEmpty++;
        }
        if(departmentId === "0"){
            setDptoFlagError(true);
            setDptoHelperText(KeyValue.ERROR_SELECT_EMPTY);
            countEmpty++;
        }
        if(provinceId === "0"){
            setProvinceFlagError(true);
            setProvinceHelperText(KeyValue.ERROR_SELECT_EMPTY);
            countEmpty++;
        }
        if(districtId === "0"){
            setDistrictFlagError(true);
            setDistrictHelperText(KeyValue.ERROR_SELECT_EMPTY);
            countEmpty++;
        }
        if(countEmpty === 0){
            var obj = {
                Dni: numDoc.toString()
            }
            ValidDniRegister(obj);
        }
    }
    const validateFormatEmail = (email) => {
        let RegEx = /[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
        if(email.match(RegEx)){
            return true
        }
        return false;
    }
    //tiene al menos una letra mayuscula??
    const foundCapitalLetters = (text) =>{
        let capitalLetters="ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
        for(var i=0; i<text.length; i++){
            if (capitalLetters.indexOf(text.charAt(i),0)!=-1){
                return true;
            }
        }
        return false;
    }
    //tiene al menos una letra minuscula??
    const foundLowerCase = (text) =>{
        let lowerCase="abcdefghijklmnñopqrstuvwxyz";
        for(var i=0; i<text.length; i++){
            if (lowerCase.indexOf(text.charAt(i),0)!=-1){
                return true;
            }
        }
        return 0;
    }
    //debe contener al menos un numero
    const foundNumbers = (text) =>{
        let numbers = "0123456789";
        for(var i=0; i<text.length; i++){
            if (numbers.indexOf(text.charAt(i),0)!=-1){
               return true;
            }
        }
        return false;
    }
    //debe contener al menos un caracter no alfanumerico
    const foundNotAlphaNumeric = (text) =>{
        let letters = /^[0-9a-zA-Z]+$/;
        if(!text.match(letters)){      
            return true;
        }
        return false;
    }
    //debe tener al menos 8 caracteres
    const minLengthString = (text) =>{
        if(text.length >= 8){
            return true;
        }
        return false;
    }

    const handleNextTwo = () => {
        setHideBlock_1(false)
        setHideBlock_2(true)
    }
    //-------------------------Render ----------------------------------
    //console.log(selectedTypeUser)

    if(returnLogin){
        return <Redirect to="/choiceProfile" />
    }
    if(goViewWelcomeUser){
        return <Redirect to="/welcome" />
    }

    return (
        <div >
            <img 
                src={process.env.PUBLIC_URL + '/images/logo_SD_new.png'} 
                className="widthLogoStoDomingo"
                style={{marginLeft:'unset'}}
                alt="login"
            />
            <div className="imageFull4">

                <Grid container >

                    <Grid item xs={12}> 
                        <Grid container >

                            {/** contenedor bloque 1 */}
                            <Grid item xs={12} className={(hideBlock_1 ? 'hideDiv' : ' showDiv')} >                    
                                <Card className={classes.root + " style-width-card-1  style-mTop-register"} id="card1"  >
                                    <CardContent>
                                        <Grid container >
                                            <Grid item xs={12} style={{marginBottom:10}}>
                                                <Grid container  >
                                                    {/** texto advertencia en caso sea alumno */}
                                                    <Grid item xs={12} style={{textAlign:'center'}}>
                                                        <h4 style={{fontFamily:'Montserrat-SemiBold',color:'#1A1A1A'}}>Datos de usuario</h4>
                                                        {
                                                            localStorage.getItem(KeyValue.CHOICE_PROFILE) === KeyValue.TypeStudent ? (
                                                                <p style={{fontFamily:'Montserrat-Regular',color:'#1A1A1A',marginBottom:0}}>
                                                                    Recuerda que si eres menor de 13 años, tus datos personales
                                                                    deben ser ingresados por tu apoderado.
                                                                </p >
                                                            ):('')
                                                        }
                                                        
                                                    </Grid>
                                                    
                                                    <Grid item xs={12}>
                                                        <Grid container style={{overflowY:'auto',maxHeight:310,overflowX:'hidden'}}>

                                                            {/** input nombres */}
                                                            <Grid item xs={12} md={6} style={{marginBottom:20}}>
                                                                <InputText
                                                                    flagError={nameFlagError}
                                                                    label={"Nombres"}
                                                                    helperText={nameHelperText}
                                                                    onChange={onChangeName}
                                                                    maxLength={30}
                                                                />                                        
                                                            </Grid>

                                                            {/** input apellidos */}
                                                            <Grid item xs={12} md={6} style={{marginBottom:20}}>
                                                                <InputText
                                                                    flagError={lastnameFlagError}
                                                                    label={"Apellidos"}
                                                                    helperText={lastnameHelperText}
                                                                    onChange={onChangeLastname}
                                                                    maxLength={30}
                                                                />
                                                            </Grid>
                                                            
                                                            {/** input tipo de documento*/}
                                                            <Grid item xs={12} md={6} style={{marginBottom:20}}>
                                                                <InputNumber
                                                                    flagError={docFlagError}
                                                                    label={"DNI/Carné de Extranjería"}
                                                                    helperText={docHelperText}
                                                                    onChange={onChangeNroDocument}
                                                                    maxLength={12}
                                                                />                                      
                                                            </Grid>
                                                            
                                                            {/** input email */}
                                                            <Grid item xs={12} md={6} style={{marginBottom:20}}>
                                                                <InputText
                                                                    flagError={emailFlagError}
                                                                    label={"Email"}
                                                                    helperText={emailHelperText}
                                                                    onChange={onChangeMail}
                                                                    maxLength={128}
                                                                />                                      
                                                            </Grid>
                                                            
                                                            {/** combo de colegios */}
                                                            <Grid item xs={12} md={6} style={{marginBottom:10}}>
                                                                <SelectCustom
                                                                    widthClass={'widthInputTextRegister'}
                                                                    label={"Colegio"}
                                                                    flagError={schoolFlagError}
                                                                    value={schoolId}
                                                                    onChange={onChangeSchool}
                                                                    typeList={KeyValue.ACTION_LIST_SCHOOLS}
                                                                    list={arrListSchools}
                                                                    helperText={schoolHelperText}
                                                                    classNameError={"divError"}
                                                                />
                                                            </Grid>
                                                            
                                                            {/** combo de departamentos */}
                                                            <Grid item xs={12} md={6} style={{marginBottom:10}}>
                                                                <SelectCustom
                                                                    widthClass={'widthInputTextRegister'}
                                                                    label={"Departamento"}
                                                                    flagError={dptoFlagError}
                                                                    value={departmentId}
                                                                    onChange={onChangeDepartament}
                                                                    typeList={KeyValue.ACTION_LIST_DEPARTMENTS}
                                                                    list={arrListDepartment}
                                                                    helperText={dptoHelperText}
                                                                    classNameError={"divError"}
                                                                />
                                                                                                
                                                            </Grid>

                                                            {/** combo de provincias */}
                                                            <Grid item xs={12} md={6} style={{marginBottom:10}}>
                                                                <SelectCustom
                                                                    widthClass={'widthInputTextRegister'}
                                                                    label={"Provincia"}
                                                                    flagError={provinceFlagError}
                                                                    value={provinceId}
                                                                    onChange={onChangeProvincia}
                                                                    typeList={KeyValue.ACTION_LIST_PROVINCES}
                                                                    list={arrListProvince}
                                                                    helperText={provinceHelperText}
                                                                    classNameError={"divError"}
                                                                />
                                                                <div className='row hideDiv'>
                                                                    <div className='col-sm-12'>
                                                                        <div className='widthInputTextRegister containerCenter'>
                                                                            <label>Provincia</label>
                                                                        </div>                            
                                                                    </div>
                                                                    <div className='col-sm-12'>
                                                                        <div className="form-group">                                
                                                                            <select 
                                                                                className="form-control widthInputTextRegister containerCenter"
                                                                                onChange={onChangeProvincia}
                                                                            >
                                                                                <option key='24' value="0" >Seleccione...</option>
                                                                                {
                                                                                    arrListProvince.map((el,key) =>
                                                                                    <option key={el.ProvinciaCod} value={el.ProvinciaCod} >{el.NombreProvincia}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>                        
                                                                </div>                                 
                                                            </Grid>
                                                            
                                                            {/** combo de distritos */}
                                                            <Grid item xs={12} md={6} style={{marginBottom:10}}>
                                                                <SelectCustom
                                                                    widthClass={'widthInputTextRegister'}
                                                                    label={"Distrito"}
                                                                    flagError={districtFlagError}
                                                                    value={districtId}
                                                                    onChange={onChangeDistrit}
                                                                    typeList={KeyValue.ACTION_LIST_DISTRICTS}
                                                                    list={arrListDistrict}
                                                                    helperText={districtHelperText}
                                                                    classNameError={"divError"}
                                                                />
                                                                <div className='row hideDiv'>
                                                                    <div className='col-sm-12'>
                                                                        <div className='widthInputTextRegister containerCenter'>
                                                                            <label>Distrito</label>
                                                                        </div>                            
                                                                    </div>
                                                                    <div className='col-sm-12'>
                                                                        <div className="form-group">                                
                                                                            <select 
                                                                                className="form-control widthInputTextRegister containerCenter"
                                                                                onChange={onChangeDistrit}
                                                                            >
                                                                                <option key='24' value="0" >Seleccione...</option>
                                                                                {
                                                                                    arrListDistrict.map((el,key) =>
                                                                                    <option key={el.DistritoCod} value={el.DistritoCod} >{el.NombreDistrito}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>                        
                                                                </div>                                 
                                                            </Grid>
                                                    
                                                        </Grid>
                                                    </Grid>
                                                    

                                                </Grid>
                                            </Grid>
                                            
                                            {/** botón volver a eleccion de tipo de usuario*/}
                                            <Grid item xs={6} md={6}>
                                                <ButtonReturnRegister
                                                    name={'Anterior'}
                                                    handleClick={handleReturnLogin}                            
                                                />  
                                            </Grid>
                                            
                                            {/** botón continuar */}
                                            <Grid item xs={6} md={6}>
                                                <ButtonSearch
                                                    id={'idBtnContinue1'}
                                                    name={'Siguiente'}
                                                    handleClick={handleNext}                            
                                                />
                                            </Grid>
                                        
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </Grid>
                            
                            {/** contenedor bloque 2 */}
                            <Grid item xs={12} className={hideBlock_2? 'hideDiv': 'showDiv'} >
                                <Card className={classes.root  + " style-width-card-1  style-mTop-register"} id="card2" >
                                    <CardContent>
                                        <Grid container>
                                            
                                            <Grid item xs={12} style={{textAlign:'center'}}>
                                                <h4 style={{fontFamily:'Montserrat-SemiBold',color:'#1A1A1A'}}>Perfil de usuario</h4>
                                            </Grid>
                                            
                                            <Grid item xs={12}>
                                                <Grid container style={{overflowY:'auto',maxHeight:310,overflowX:'hidden'}}>
                                                    {/** tipo de usuario */}
                                                    <Grid item xs={12} md={6}>
                                                        <div className='row'>
                                                            <div className='col-sm-12'>
                                                                <div className='widthInputTextRegister containerCenter'>
                                                                    <label style={{fontFamily:'Montserrat-Regular',color:'#1A1A1A'}}>Tipo de Usuario</label>
                                                                </div>                            
                                                            </div>
                                                            <div className='col-sm-12'>
                                                                <div className="form-group">                                
                                                                    <select 
                                                                        className="form-control widthInputTextRegister containerCenter"
                                                                        onChange={onChangeTypeUser}
                                                                        value={selectedTypeUser}
                                                                        disabled
                                                                    >
                                                                        <option style={{fontFamily:'Montserrat-Regular'}} key='24' value="" >Seleccione...</option>
                                                                        {
                                                                            arrListTypeUsers.map((el,key) =>
                                                                            <option style={{fontFamily:'Montserrat-Regular'}} key={el.TipoUsuarioCod} value={el.TipoUsuarioCod} >{el.TipoUsuarioNom}</option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>                        
                                                        </div>                                        
                                                    </Grid>
                                                    
                                                    {/** nombre de usuario */}
                                                    <Grid item xs={12} md={6} style={{marginTop: 15}}>
                                                        <InputText
                                                            flagError={nameUserFlagError}
                                                            label={"Nombre de Usuario"}
                                                            helperText={nameUserHelperText}
                                                            onChange={onChangeNameUser}
                                                            maxLength={20}
                                                        />
                                                    </Grid>
                                                    
                                                    {/** Texto sobre Seguridad de contraseña */}
                                                    <Grid item xs={12} style={{textAlign:'left',marginTop: 15}}>
                                                        <p className="style-width-txt-ps-security style-txt-password-security">
                                                            La contraseña debe tener al menos una minúscula, una mayúscula, un número, un caracter no alfanumérico, y debe contener al menos 8 caracteres.
                                                        </p>
                                                    
                                                    </Grid>

                                                    {/** Contraseña */}
                                                    <Grid item xs={12} md={6} className='marginTopField'>
                                                        <InputPassword
                                                            className={"divCenterPassword"}
                                                            id={"idPass"}
                                                            label="Contraseña"
                                                            onChange={onChangePassword}
                                                            passwordError={passwordFlagError}
                                                            passwordHelperText={passwordHelperText}
                                                        />                                       
                                                    </Grid>

                                                    {/** Confirmar Contraseña */}
                                                    <Grid item xs={12} md={6} className='marginTopField'>
                                                        <InputPassword
                                                            className={"divCenterPassword"}
                                                            id={"idConfirmPass"}
                                                            label="Repetir contraseña"
                                                            onChange={onChangeRepeatPassword}
                                                            passwordError={passwordConfirmFlagError}
                                                            passwordHelperText={passwordConfirmHelperText}
                                                        />
                                                                                            
                                                    </Grid>

                                                    <Grid item xs={12} 
                                                        className={(flagValidateErrorPassword? "showDiv txtColorError" : "hideDiv")+" MuiFormHelperText-root Mui-error MuiFormHelperText-marginDense"}
                                                        style={{textAlign:'center'}}
                                                        >
                                                        <p style={{marginBottom:0,marginTop:10}}>{messageGlobalError}</p>
                                                    </Grid>
                                                    
                                                    {/** check Aceptar terminos y condiciones */}
                                                    <Grid item xs={12} className='marginTopField' style={{textAlign:'left'}}>
                                                        <div>
                                                            <CheckCustom 
                                                                handleChange={onChangeCheckConditions} 
                                                                stateCheck={terms}
                                                                name={"Acepto condiciones de uso."}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </Grid>
                                            
                                            {/**boton anterior */}
                                            <Grid item xs={4} md={6} style={{marginTop: 15}}>
                                                <ButtonReturnRegister
                                                    name={'Anterior'}
                                                    handleClick={handleNextTwo}                            
                                                />
                                            </Grid>
                                            
                                            {/**boton crear usuario */}
                                            <Grid item xs={8} md={6} style={{marginTop: 15}} className="style-align-responsive-btn-create-user">
                                                <ButtonSearch
                                                    id={'idBtnCreateUser'}
                                                    name={'Crear usuario'}
                                                    handleClick={handleCreateUser}                            
                                                />
                                            </Grid>
                                            
                                        </Grid>

                                    </CardContent>
                                </Card>
                                

                            </Grid>
                            
                                   
                        
                        </Grid>
                    </Grid> 
                    
                    <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
                    <Progress open={loading} />
                </Grid>
    
            </div>
        </div>
    )
}

export default Register
