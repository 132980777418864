import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
//import Card from '@material-ui/core/Card';
//import { makeStyles } from '@material-ui/core/styles';
//import CardContent from '@material-ui/core/CardContent';
import ButtonSearch from '../buttons/ButtonSearch';
//import TextField from '@material-ui/core/TextField';
//import CheckCustom from '../inputs/CheckCustom';
import '../register/Register.css';
//import { Redirect } from 'react-router-dom';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import UrlBase from '../../config/UrlBase';
import Endpoint from '../../apis/Endpoint';
//import ButtonReturnRegister from '../buttons/ButtonReturnRegister';
import KeyValue from '../../apis/KeyValue';
import Token from '../../config/Token';
import InputPassword from '../inputs/InputPassword';
//import InputText from '../inputs/InputText';
//import SelectCustom from '../select/SelectCustom';
//import InputNumber from '../inputs/InputNumber';
import Slide from '@material-ui/core/Slide';
import ButtonCancel from '../buttons/ButtonCancel';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';
import UrlBase_Prod from '../../config/UrlBase_Prod'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


export default function PasswordDialog(props) {

    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordHelpertext, setPasswordHelperText] = React.useState('')

    const [newPassword, setNewPassword] = React.useState('');
    const [newPasswordError, setNewPasswordError] = React.useState(false);
    const [newPasswordHelperText, setNewPasswordHelperText] = React.useState('');

    const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
    const [confirmNewPasswordError, setConfirmNewPasswordError] = React.useState(false);
    const [confirmNewPasswordHelperText, setConfirmNewPasswordHelperText] = React.useState(false);
    
    const [flagValidateErrorPassword, setFlagValidateErrorPassword] = React.useState(false);
    const [messageGlobalError, setMessageGlobalError] = React.useState('');
    //----loader----
    const [loading, setLoading] = React.useState(false);
    //---message error-----
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);    
    //---------
    const [hideBlock, setHideBlock] = React.useState(false);
    //------------------------flag vencimiento de token------------------------------
    const [showUnauthorized, setShowUnauthorized] = React.useState(false);
    //--------------

    const handleClose = () => {
        setShow(false)       
    };
    const ShowDialogMessage = (message) =>{
        setShow(true);
        setMessageError(message);
    }
    //----- config async method ------------------
    const asyncCallMethod_SecurityService = (typeCall,obj,methodAction,typeAction) =>{
        //const URL_BASE = UrlBase.URL_BASE_SECURITY_SERVICE;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_SECURITY_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_SECURITY_SERVICE;
        }
        const tokenLocalStorage = localStorage.getItem(Token.TOKEN)
        const paramsHeadersPost = {
        method:typeCall,
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        },
        body: JSON.stringify(obj)
        }
        const paramsHeadersGet = {
        method:typeCall,
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        }
        }
        const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
        const URL = URL_BASE+methodAction;    
        setLoading(true);
        try{
            fetch(URL,header).then(function(res){
                if(res.status === 401){
                    return 401;
                  }
                return res.json();
            }).then(function(response){    
                setLoading(false);

                if(response === 401){
                    setShowUnauthorized(true);
                    
                }else if(response.Code === 0){
                    SetConfigResponse(response,typeAction);                    
  
                }else if (response.Code === 1){
                    errorCode1(response);
            
                }else if (response.Code === 2){
                    errorCode2(response);        
                }else if (response.Code === 4){
                    errorCode4(response);        
                }
            }).catch((error) =>{
                setLoading(false);
                console.log(error);
            })
        }catch(e){
            setLoading(false);
            console.log(e);
        }                 
    }
    const SetConfigResponse = (response,typeAction)=>{
      if(typeAction === KeyValue.ACTION_UPDATE_PASSWORD){
        ResponseUpdatePassword(response);
      }
    }
    const errorCode1 = (response) =>{
        var message = "";
        if(response.Data.StatusCode === 500){
            ShowDialogMessage(response.Data.Message);

        } else if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = message + ` ${element}.`
            });
            ShowDialogMessage(message);                        
        }
    }
    const errorCode2 = (response) =>{
        var message = "";
        if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = message + ` ${element.FieldName}. ${element.Message}. `
            });
            console.log('message error code 2')
            console.log(message)
            ShowDialogMessage(message);                        
        }
    }
    const errorCode4 = (response) =>{
        ShowDialogMessage("StatusCode: " + response.Data.StatusCode + ", Message: " + response.Data.Message);
    }
    //-------------Iniciando--------------------------------------
    const eventListner = React.useCallback(() => {
        //console.log(state); // use the stateful variable in event listener
    }, [props.init]);

    React.useEffect(()=>{
        //console.log("use effect password dialog")
        //console.log(props.init)
        if(props.init === 1){
            initFields();
        }
    },[eventListner]);
    const initFields = () =>{
        setPassword('')
        setPasswordError(false)
        setPasswordHelperText('')

        setNewPassword('')
        setNewPasswordError(false)
        setNewPasswordHelperText('')

        setConfirmNewPassword('')
        setConfirmNewPasswordError(false)
        setConfirmNewPasswordHelperText('')

        setFlagValidateErrorPassword(false)
        setMessageGlobalError('')
        setHideBlock(false)        
    }
    //-------------------------  POST CHANGE PASSWORD -------------------
    const PostChangePassword = (obj) =>{
        const methodAction = Endpoint.UPDATE_PASSWORD;
        asyncCallMethod_SecurityService("post",obj,methodAction,KeyValue.ACTION_UPDATE_PASSWORD);
    }
    const ResponseUpdatePassword = (response) =>{
        console.log(response);
        if (response.Data){
            setHideBlock(true);
        }else{            
            ShowDialogMessage(response.Data.Message); 
        }
    }
    //-------------------------------EVENTS--------------------------
    const handlerPassword = (e) =>{
        var text = e.target.value;
        if(text !== ""){
            setPasswordHelperText('');
            setPasswordError(false);
        }else{
            setPasswordHelperText(KeyValue.ERROR_EMPTY)
            setPasswordError(true);
        }
        setMessageGlobalError('') 
        setFlagValidateErrorPassword(false);
        setPassword(text);
    }
    const handlerNewPassword = (e) =>{
        var text = e.target.value;
        if(text !== ""){
            setNewPasswordHelperText('');
            setNewPasswordError(false);
        }else{
            setNewPasswordHelperText(KeyValue.ERROR_EMPTY)
            setNewPasswordError(true);
        } 
        setMessageGlobalError('') 
        setFlagValidateErrorPassword(false);
        setNewPassword(text);
    }
    const handlerConfirmNewPassword = (e) =>{
        var text = e.target.value;
        if(text !== ""){
            setConfirmNewPasswordHelperText('');
            setConfirmNewPasswordError(false);
        }else{
            setConfirmNewPasswordHelperText(KeyValue.ERROR_EMPTY)
            setConfirmNewPasswordError(true);
        } 
        setMessageGlobalError('') 
        setFlagValidateErrorPassword(false);
        setConfirmNewPassword(text);
    }
    //------------------validate fields -----------------------
    const validateFields = () =>{
        var countEmpty = 0;
        if(password === ""){
            setPasswordError(true);
            setPasswordHelperText(KeyValue.ERROR_EMPTY);
            countEmpty++;
        }
        if(newPassword === ""){
            setNewPasswordError(true);
            setNewPasswordHelperText(KeyValue.ERROR_EMPTY);
            countEmpty++;
        }
        if(confirmNewPassword === ""){
            setConfirmNewPasswordError(true);
            setConfirmNewPasswordHelperText(KeyValue.ERROR_EMPTY);
            countEmpty++;
        }
        
        if(countEmpty === 0){

            if(newPassword !== confirmNewPassword){
                setFlagValidateErrorPassword(true);
                setMessageGlobalError(KeyValue.ERROR_PASSWORDS_NOT_EQUALS)
                countEmpty++;
            }else if (password === newPassword) {
                setFlagValidateErrorPassword(true);
                setMessageGlobalError(KeyValue.ERROR_PASSWORDS_EQUALS_PAST)
                countEmpty++;
    
            }else if (!foundCapitalLetters(newPassword)) {
                setFlagValidateErrorPassword(true);
                setMessageGlobalError(KeyValue.ERROR_PASSWORDS_NOT_MAYUS)
                countEmpty++;
    
            }else if (!foundLowerCase(newPassword)) {
                setFlagValidateErrorPassword(true);
                setMessageGlobalError(KeyValue.ERROR_PASSWORDS_NOT_MINUS)
                countEmpty++;
    
            }else if (!foundNumbers(newPassword)) {
                setFlagValidateErrorPassword(true);
                setMessageGlobalError(KeyValue.ERROR_PASSWORDS_NOT_NUMBERS)
                countEmpty++;
    
            }else if (!foundNotAlphaNumeric(newPassword)) {
                setFlagValidateErrorPassword(true);
                setMessageGlobalError(KeyValue.ERROR_PASSWORDS_NOT_ALPHANUMERIC)
                countEmpty++;
    
            }else if (!minLengthString(newPassword)) {
                setFlagValidateErrorPassword(true);
                setMessageGlobalError(KeyValue.ERROR_PASSWORDS_NOT_LARGE)
                countEmpty++;
            }

            if(countEmpty === 0){
                var obj = {
                    ActualContraseña: password,
                    NuevaContraseña: newPassword,
                    ConfirmarContraseña: confirmNewPassword
                }
                PostChangePassword(obj);
            }            
        }
    }
    //tiene al menos una letra mayuscula??
    const foundCapitalLetters = (text) =>{
        let capitalLetters="ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
        for(var i=0; i<text.length; i++){
            if (capitalLetters.indexOf(text.charAt(i),0)!=-1){
                return true;
            }
        }
        return false;
    }
    //tiene al menos una letra minuscula??
    const foundLowerCase = (text) =>{
        let lowerCase="abcdefghijklmnñopqrstuvwxyz";
        for(var i=0; i<text.length; i++){
            if (lowerCase.indexOf(text.charAt(i),0)!=-1){
                return true;
            }
        }
        return 0;
    }
    //debe contener al menos un numero
    const foundNumbers = (text) =>{
        let numbers = "0123456789";
        for(var i=0; i<text.length; i++){
            if (numbers.indexOf(text.charAt(i),0)!=-1){
               return true;
            }
        }
        return false;
    }
    //debe contener al menos un caracter no alfanumerico
    const foundNotAlphaNumeric = (text) =>{
        let letters = /^[0-9a-zA-Z]+$/;
        if(!text.match(letters)){      
            return true;
        }
        return false;
    }
    //debe tener al menos 8 caracteres
    const minLengthString = (text) =>{
        if(text.length >= 8){
            return true;
        }
        return false;
    }
    //---------------------------------------------------------
    

    
    return (    
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-change-password"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle 
            id="alert-dialog-change-password" 
            style={{textAlign:"center"}}
            className={hideBlock ? 'hideDiv' : 'showDiv'}
            >
                {"Cambio de contraseña"}
            </DialogTitle>
            <DialogContent >

                <Grid container /*style={{width:500}}*/ >

                    <Grid item xs={12}  className={hideBlock ? 'hideDiv' : 'showDiv'}>
                        <Grid container>
                            {/**input contraseña actual */}
                            <Grid item xs={12} style={{textAlign:'center',marginBottom:15}}>
                                <InputPassword
                                    className={"divCenterPassword2"}
                                    id={"idPassAnt"}
                                    value={password}
                                    label="Contraseña actual"
                                    onChange={handlerPassword}
                                    passwordError={passwordError}
                                    passwordHelperText={passwordHelpertext}
                                />                                               
                            </Grid>
                            
                            {/**mensaje de informacion de la contraseña */}
                            <Grid item xs={12} style={{marginBottom:0}}>
                                <p
                                    className="width-p-password"
                                    style={{
                                        fontFamily:'Montserrat-Regular',
                                        color:'#1A1A1A',
                                        fontSize:'11pt',
                                        marginLeft:'auto',
                                        marginRight:'auto',
                                        marginBottom:15,
                                        //width:404,
                                        textAlign:'justify'}}
                                        >
                                            La nueva contraseña debe tener al menos una minúscula, 
                                            una mayúscula, un número, un caracter no alfanumérico, 
                                            y debe contener al menos 8 caracteres.
                                        </p>                                               
                            </Grid>

                            {/**input contraseña nueva */}
                            <Grid item xs={12} style={{textAlign:'center',marginBottom:15}} >
                                <InputPassword
                                    className={"divCenterPassword2"}
                                    id={"idPass"}
                                    value={newPassword}
                                    label="Contraseña nueva"
                                    onChange={handlerNewPassword}
                                    passwordError={newPasswordError}
                                    passwordHelperText={newPasswordHelperText}
                                />                                               
                            </Grid>
                            
                            {/**input nueva contraseña confirmar */}
                            <Grid item xs={12} style={{textAlign:'center',marginBottom:15}}>
                                <InputPassword
                                    className={"divCenterPassword2"}
                                    id={"idConfirmPass"}
                                    value={confirmNewPassword}
                                    label="Repetir contraseña nueva"
                                    onChange={handlerConfirmNewPassword}
                                    passwordError={confirmNewPasswordError}
                                    passwordHelperText={confirmNewPasswordHelperText}
                                /> 
                            </Grid>

                            <Grid item xs={12} 
                                className={(flagValidateErrorPassword? "showDiv txtColorError" : "hideDiv")+" MuiFormHelperText-root Mui-error MuiFormHelperText-marginDense"}
                                style={{textAlign:'center'}}
                                >
                                <p>{messageGlobalError}</p>
                            </Grid>

                            {/** botón cancelar*/}
                            <Grid item md={6} xs={12} style={{marginBottom: 12,textAlign:'center'}}>
                                <ButtonCancel
                                    className={'styleWidthButtonCancel'}
                                    name={'Cancelar'}
                                    handleClick={props.handleClose}                            
                                />
                            </Grid>

                            {/** botón actualizar contraseña*/}
                            <Grid item md={6} xs={12} style={{marginBottom: 12,textAlign:'center'}}>
                                <ButtonSearch
                                    className={'styleWidthButton'}
                                    name={'Actualizar Clave'}
                                    handleClick={validateFields}                            
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12}  className={hideBlock ? 'showDiv' : 'hideDiv'}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid item xs={12} style={{textAlign:'center'}}>
                                    <h4 style={{color:'#333333',fontFamily:'Montserrat-SemiBold'}}>
                                        ¡Genial!
                                    </h4>               
                                </Grid>
                                <Grid item xs={12} style={{textAlign:'center'}}>
                                    <p 
                                        style={
                                            {
                                                color:'#808080',
                                                fontFamily:'Montserrat-Regular',
                                            }}>
                                        Se cambió correctamente tu contraseña.
                                    </p>               
                                </Grid>
                                <Grid item  xs={12} style={{textAlign:'center'}}>  
                                    <ButtonSearch
                                        className={'styleWidthButton'}
                                        name={'cerrar'}
                                        handleClick = {props.handleClose}
                                    />                                      
                                </Grid>
                            </Grid>                    
                        </Grid>                        
                    </Grid>
                    
                </Grid>            
            </DialogContent>
            <DialogActions style={{display:'none'}}>          
                <Button onClick={props.handleClose}  color="primary">
                    Ok
                </Button>
            </DialogActions>
            <UnauthorizedDialog open={showUnauthorized} />
            <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
            <Progress open={loading} />
        </Dialog>
    );
}
