import React from 'react';
import './App.css';
import LoginApp from './components/login/Login';
import Drawer from './components/drawerNavigation/DrawerMain';
import HomeApp from './components/home/Home';
//import DrawerBook from './components/drawerNavigation/DrawerBook';
import RegisterApp from './components/register/Register';
import WelcomeApp from './components/register/WelcomeUser';
import VisualizeContentTypeApp from './visualizeContentType/VisualizeContentType';
import ChoiceProfileApp from './components/choiceProfile/ChoiceProfile';
//import DrawerClassroomApp from './components/drawerNavigation/DrawerClassroom';
import {BrowserRouter as Router,Switch, Route} from 'react-router-dom';
import ToolbarProvider from './context/ToolbarProvider';
import ForgetPassword from './components/forgetPassword/ForgetPassword';
//import ReactGA from 'react-ga';
//import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import ConstantKey from './config/ConstantKey';

function App() { 

  React.useEffect(() =>{   
    ReactGA.initialize(ConstantKey.REACT_GA_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);      
  },[])

  return (
    <ToolbarProvider>
      
      <div className="App">
        <Router >
          <Switch>      
              <Route path={`/`} exact component={HomeApp} />
              <Route path={`/login`}  component={LoginApp} />
              <Route path={`/Main`}  component={Drawer} />
              <Route path={`/Register`}  component={RegisterApp} />
              <Route path={`/welcome`}  component={WelcomeApp} />
              <Route path={`/contentType`}  component={VisualizeContentTypeApp} />
              <Route path={`/choiceProfile`}  component={ChoiceProfileApp} />
              <Route path={`/forgetPassword`}  component={ForgetPassword} />
          </Switch>
        </Router>
      </div>  
    </ToolbarProvider>      
  );
}

export default App;