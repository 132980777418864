import React from 'react'
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
//import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import ButtonSearch from '../buttons/ButtonSearch';
import ButtonSearchBook from '../buttons/ButtonSearchBook';
import InputTextOnlyBorder from '../inputs/InputTextOnlyBorder';
//import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import BorderColorSharpIcon from '@material-ui/icons/BorderColorSharp';
import '../dialogs/Dialog.css';
import Endpoint from '../../apis/Endpoint';
import KeyValue from '../../apis/KeyValue';
import Token from '../../config/Token';
import UrlBase from '../../config/UrlBase';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
//import InformationIcon from '@material-ui/icons/HelpOutline';
//import Tooltip from '@material-ui/core/Tooltip';
//import ButtonAdd from '../buttons/ButtonAdd';
//import ImagePreview from '../imagePreview/ImagePreview';

import ButtonDesign from '../buttons/ButtonDesign';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Key from '../../apis/Key';
import SelectCustom from '../select/SelectCustom';
//import ButtonReturnRegister from '../buttons/ButtonReturnRegister';
import './Profile.css';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';
import ButtonProfile from '../buttons/ButtonProfile';
import ButtonProfileDesign from '../buttons/ButtonProfileDesign';
import UrlBase_Prod from '../../config/UrlBase_Prod';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditLomoBook from '../dialogs/EditLomoBook';
import InformationIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import Swal from 'sweetalert2';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#ffff',
      color: "black",
      borderBottom: '3px solid #dee2e6',
      borderRight: '2px solid #dee2e6',
      fontWeight: 600,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        },
}));

const useStyles_card = makeStyles((theme) => ({
    root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    }
}));

const Profile = (props) => {
    //const classes = useStyles();
    const classesCard = useStyles_card();
    const classes = useStyles();
    //------------------------flag vencimiento de token------------------------------
    const [showUnauthorized, setShowUnauthorized] = React.useState(false);
    
    const [lastname, setLastname] = React.useState('');
    const [name, setName] = React.useState('');
    const [cellphone, setCellphone] = React.useState('');
    const [Email, setEmail] = React.useState('');
    const [ListLibros, setListLibros] = React.useState([]);
    const [TypeUser, setTypeUser] = React.useState('');
    const [uriPhoto, setUriPhoto] = React.useState("");
    const [resultUpdate, setResultUpdate] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    //----------------------------------------------------------
    const [EmailFlagError, setEmailFlagError] = React.useState(false);
    const [EmailHelperText, setEmailHelperText] = React.useState('');
    //----------------------------------------------------------
    const [cellphoneFlagError, setCellphoneFlagError] = React.useState(false);
    const [cellphoneHelperText, setCellphoneHelperText] = React.useState('');
    //---------VARIABLES PARA COLEGIO --------------------------
    const [arrListSchools, setArrListSchools] = React.useState([]);
    const [schoolId, setSchoolId] = React.useState('0');
    const [schoolFlagError, setSchoolFlagError] = React.useState(false);
    const [schoolHelperText, setSchoolHelperText] = React.useState('');
    //--------- VARIABLES PARA COMBO DE GRADOS ---------------------
    const [arrListGrade, setArrListGrade] = React.useState([]);
    const [gradeId, setGradeId] = React.useState('0');
    const [gradeFlagError, setGradeFlagError] = React.useState(false);
    const [gradeHelperText, setGradeHelperText] = React.useState('');
    //-------------------------VARIABLES PARA SUBIDA DE FOTO-----------------------------
    const [file1, setFile1] = React.useState(null);
    const [fileUrl, setFileUrl] = React.useState(null);    
    var imgPreview;
    //---------------------CONSTANTES PARA REUTILIZAR -----------------------------------    
    //----loader----
    const [loading, setLoading] = React.useState(false);
    //---message error-----
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);    
    //---message error-----
    const [showParticiple, setShowParticiple] = React.useState(false);
    const [messageErrorParticiple, setMessageErrorParticiple] = React.useState(false);    
    //------------------EDITAR LOMO--------------------
    const [showActivate,setShowActivate] = React.useState(false);
    const [initOptions, setInitOptions] = React.useState(0);
    const [CodeLomo, setCodeLomo] = React.useState('');

    const [goMain, setGoMain] = React.useState(false);

    //-------------footer promotion----------------
    const [showFlagPromotion, setShowFlagPromotion] = React.useState(false);
    const [urlFooterPromotion, setUrlFooterPromotion] = React.useState("");

    const handleClose = () => {
        setShow(false)       
    };
    const ShowDialogMessage = (message) =>{
        setShow(true);
        setMessageError(message);
    }

    const handleCloseParticiple = () => {
        setShowParticiple(false)       
    };
    const ShowDialogMessageParticiple = (message) =>{
        setShowParticiple(true);
        setMessageErrorParticiple(message);
    }

    //----------- impl method async ------------------------------------
    const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
        //const URL_BASE = UrlBase.URL_BASE_PORTAL_SERVICE;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_PORTAL_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_PORTAL_SERVICE;
        }
        const tokenLocalStorage = localStorage.getItem(Token.TOKEN)
        const paramsHeadersPost = {
        method:typeCall,
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        },
        body: JSON.stringify(obj)
        }
        const paramsHeadersGet = {
        method:typeCall,
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        }
        }
        const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
        const URL = URL_BASE+methodAction;    
        setLoading(true);
        try{
            fetch(URL,header).then(function(res){
                if(res.status === 401){
                    return 401;
                  }
                return res.json();
            }).then(function(response){    
                setLoading(false);

                if(response === 401){
                    setShowUnauthorized(true);
                    
                }else if(response.Code === 0){
                    SetConfigResponse(response,typeAction);                    

                }else if (response.Code === 1){
                    errorCode1(response);
            
                }else if (response.Code === 2){
                    errorCode2(response);        
                }
            }).catch((error) =>{
                setLoading(false);
                console.log(error);
            })
        }catch(e){
            setLoading(false);
            console.log(e);
        }                 
    }
    const asyncCallMethod_WithApiKey = (typeCall,obj,methodAction,typeAction,urlBase,key) =>{
        //const URL_BASE = urlBase;
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_PORTAL_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_PORTAL_SERVICE;
        }
        const paramsHeadersPost = {
          method:typeCall,
          mode: 'cors',
          headers: {
              'Accept':'application/json',
              'ApiKey': key,
              'Content-type': 'application/json'
          },
          body: JSON.stringify(obj)
        }
        const paramsHeadersGet = {
          method:typeCall,
          mode: 'cors',
          headers: {
              'Accept':'application/json',
              'ApiKey': key,
              'Content-type': 'application/json'
          }
        }
        const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
        const URL = URL_BASE+methodAction;    
        setLoading(true);    
          try{
              fetch(URL,header).then(function(res){
                if(res.status === 401){
                    return 401;
                  }
                  return res.json();
              }).then(function(response){    
                setLoading(false);

                if(response === 401){
                setShowUnauthorized(true);
                
                }else if(response.Code === 0){
                    SetConfigResponse(response,typeAction);                    
    
                }else if (response.Code === 1){
                    errorCode1(response);
            
                }else if (response.Code === 2){
                    errorCode2(response);        
                }
              }).catch((error) =>{
                setLoading(false);
                console.log(error);
              })
          }catch(e){
            setLoading(false);
            console.log(e);
          }      
    }
    const SetConfigResponse = (response,typeAction)=>{
        if (typeAction === KeyValue.ACTION_LIST_SCHOOLS){
            ResponseListSchools(response);
        }else if (typeAction === KeyValue.ACTION_LIST_GRADE){
            ResponseListGrades(response);
        }else if (typeAction === KeyValue.ACTION_GET_DATA_PROFILE){
            ResponseGetDataProfile(response);
        }else if (typeAction === KeyValue.ACTION_UPDATE_PROFILE){
            ResponseUpdateProfile(response);
        }
    }
    const errorCode1 = (response) =>{
        var message = "";
        if(response.Data.StatusCode === 500){
            ShowDialogMessage(response.Data.Message);
        }
        else if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element}.`
            });
            ShowDialogMessage(message);                        
        }
    }
    const errorCode2 = (response) =>{
        var message = "";
        if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element.FieldName}. ${element.Message}. `
            });
            ShowDialogMessage(message);                        
        }
    }
    //---------------------------------------------------------------------------------------
    const eventListner = React.useCallback(() => {
        //console.log(state); // use the stateful variable in event listener
    }, [props.init]);

    React.useEffect(()=>{
        setShowFlagPromotion(localStorage.getItem("flag-show-promotion"));
        setUrlFooterPromotion(localStorage.getItem("url-footer-promotion"));
        if(props.init === 1){
            initFields();
            GetListSchools();
        }
    },[eventListner]);

    const initFields = () =>{
    }
    //----------------------- eventos on change-----------------------------
    const onChangeSchool = (e) => {
        var text = e.target.value;
        if (text === "0"){
            setSchoolFlagError(true);
            setSchoolHelperText(KeyValue.ERROR_SELECT_EMPTY)
        }else{
            setSchoolFlagError(false);
            setSchoolHelperText("")
        }
        setSchoolId(text)
    }
    const onChangeGrade = (e) => {
        var text = e.target.value;
        if (text === "0"){
            setGradeFlagError(true);
            setGradeHelperText(KeyValue.ERROR_SELECT_EMPTY)
        }else{
            setGradeFlagError(false);
            setGradeHelperText("")
        }
        setGradeId(text)
    }
    const handleLastname = (e) =>{
        
    }
    const handleName = (e) =>{
        
    }
    const handleCellphone = (e) =>{
        var text = e.target.value.toString();
        if(text === ""){
            setCellphoneFlagError(true)
            setCellphoneHelperText(KeyValue.ERROR_EMPTY);
        }else{
            setCellphoneFlagError(false)
            setCellphoneHelperText('');
        }
        setCellphone(text)
    }
    const handleClickEdit = (e) =>{
        console.log(e.currentTarget.id);
        setCodeLomo(e.currentTarget.id);
        setShowActivate(true);
        setInitOptions(1);
    }
    const handleEmail = (e) =>{
        var text = e.target.value.toString();
        if(text === ""){
            setEmailFlagError(true)
            setEmailHelperText(KeyValue.ERROR_EMPTY);
        }else{
            setEmailFlagError(false)
            setEmailHelperText('');
        }
        setEmail(text)
    }
    const handleReturnBook = () => {

    }
    const UploadSingleFile = e => {
        setUriPhoto(window.URL.createObjectURL(e.target.files[0]))
        setFile1(e.target.files[0])
    }
    //---------------------------lista colegios-----------------------------
    const GetListSchools = () => {
        const methodAction = Endpoint.LIST_SCHOOLS;
        asyncCallMethod_WithApiKey('get',null,methodAction,KeyValue.ACTION_LIST_SCHOOLS,UrlBase.URL_BASE_PORTAL_SERVICE,Key.API_KEY_PORTAL_SERVICE);
    }
    const ResponseListSchools = (response) => {
        console.log(response)
        setArrListSchools(response.Data.ListaColegios)
        
        if(localStorage.getItem(Token.TYPE_USER) === KeyValue.TypeStudent){
            GetListGrade();
        }else{
            GetDataProfile();
        }
    }
    //---------------------------lista de grados-----------------------------
    const GetListGrade = () =>{
        const methodAction = Endpoint.LIST_GRADE;
        asyncCallMethod('get',null,methodAction,KeyValue.ACTION_LIST_GRADE);
    }
    const ResponseListGrades = (response) => {
        console.log(response);
        setArrListGrade(response.Data);
        GetDataProfile();
    }
    //---------------------------obtener datos de usuario --------------------------    
    const GetDataProfile = () =>{
        const methodAction = Endpoint.GET_DATA_PROFILE;
        asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_DATA_PROFILE);
    }
    const ResponseGetDataProfile = (response) => {
        console.log(response);
        setName(response.Data.Nombres);
        setLastname(response.Data.Apellidos);
        setSchoolId(response.Data.IdColegio);
        setGradeId(response.Data.IdGrado);
        setUriPhoto(response.Data.UrlFoto);
        setCellphone(response.Data.Celular);
        setEmail(response.Data.Email);
        setTypeUser(response.Data.TipoUsuario);
        setListLibros(response.Data.Libros);
        console.log(response.Data.Libros);
    }
    //----------------------------ACTUALIZAR DATOS -----------------------------------
    const handleUpdateProfile = () =>{
        var countEmpty = 0;

        if(schoolId === "0"){
            setSchoolFlagError(true);
            setSchoolHelperText(KeyValue.ERROR_SELECT_EMPTY)
            countEmpty++;
        }
        if(cellphone === ""){
            setCellphoneFlagError(true);
            setCellphoneHelperText(KeyValue.ERROR_EMPTY)
            countEmpty++;

        }else if (cellphone.charAt(0) !== "9"){
            setCellphoneFlagError(true);
            setCellphoneHelperText('Debe comenzar con el número 9.')
            countEmpty++;

        }else if (cellphone.length < 9){
            setCellphoneFlagError(true);
            setCellphoneHelperText('Debe tener 9 números.')
            countEmpty++;
        }
        /*
        if(gradeId === "0"){
            setGradeFlagError(true);
            setGradeHelperText(KeyValue.ERROR_SELECT_EMPTY);
            countEmpty++;
        }
        */
        if(uriPhoto === "" || uriPhoto === null){
            ShowDialogMessage('Falta cargar su foto de perfil.')
            countEmpty++;
        }

        if(Email === ""){
            setEmailFlagError(true);
            setEmailHelperText(KeyValue.ERROR_EMPTY);
        }
        else if(!validateFormatEmail(Email)){
            ShowDialogMessage('Correo con formato incorrecto.')
            countEmpty++;
        }

        if(countEmpty === 0){
            
            validateEmptyPhoto();
            //setResultUpdate(true)
            //UpdateProfile(obj)
        }
    }

    function validateFormatEmail (email) {
        let RegEx = /[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
        if(email.match(RegEx)){
            return true;
        }
        else{
          return false;
        }
    }

    const handleParticipleLotery = () => {
        var CompleteCodes = true
        var MessageNombreLibro = ''
        var FindError = false

        ListLibros.forEach(element => {
            if (!element.CodigoLomoCorrecto && !FindError){
                console.log("Codigo de Lomo Incorrecto");
                MessageNombreLibro = "Por favor coloque el Código de Validación correcto para el Libro: " + element.Libro + " (" + element.CodigoActivacion + ")"
                CompleteCodes = false
                FindError = true
            }
        });

        if (!CompleteCodes) {
            ShowDialogMessageParticiple(MessageNombreLibro)
        }else{
            Swal.fire(
                '¡Felicitaciones!',
                'Ya estas participando del sorteo.',
                'success',
              ).then((result) => {
                window.location.reload(false)
                //setGoMain(true)
              })
        }
    }

    const handleCloseActivate = () => {
        setCodeLomo('');
        setShowActivate(false);
        GetDataProfile();
        setInitOptions(0);
    }

    const UpdateProfile = (obj) => {
        const methodAction = Endpoint.UPDATE_PROFILE;
        asyncCallMethod('post',obj,methodAction,KeyValue.ACTION_UPDATE_PROFILE);
    }
    const ResponseUpdateProfile = (response) => {
        //console.log('respues del editado de perfil');
        //console.log(response);
        Swal.fire(
            'Hecho',
            'Se Guardó Satisfactoriamente.',
            'success',
          ).then((result) => {
            console.log("Actualizado")
          })
        //setResultUpdate(true);
    }
    //----------------------------SUBIDA DE FOTO ---------------------------------
    const validateEmptyPhoto = () =>{
        if (file1 !== null){
            UploadPhoto();
        }else{
            PostCreateObjProfile(uriPhoto);
        }
    }
    const UploadPhoto = () =>{
        const blob = file1;
        var nameFile = CreateGuid() + ".png"
        const formDataToUpload = new FormData();
        formDataToUpload.append('FileImage', blob,nameFile);
        let URL_BASE  = "";
        if(process.env.NODE_ENV === "development"){
            URL_BASE  = UrlBase.URL_BASE_FILES_SERVICE;
        }
        if(process.env.NODE_ENV === "production"){
            URL_BASE  = UrlBase_Prod.URL_BASE_FILES_SERVICE;
        }

        const URL = URL_BASE + Endpoint.FILE_UPLOAD;
        setLoading(true);
        try{
            fetch(URL,{
                method:'post',
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem(Token.TOKEN),
                },
                body: formDataToUpload

            }).then(function(res){
                if(res.status === 401){
                    return 401;
                }
                return res.json();
            }).then(function(response){  
                setLoading(false);            
                console.log(response);

                if (response !== 401){
                    PostCreateObjProfile(response.Data.PathFile);                    
                    
                }else{
                    console.log('error 401');
                }               

            }).catch((error) =>{
                console.log(error);
            })
        }catch(e){
            console.log(e);
        }
    }
    const PostCreateObjProfile = (uri_photo) =>{
        const obj = {
            Name: name,
            LastName: lastname,
            IdColegio: parseInt(schoolId),
            IdGrado: parseInt(gradeId),
            Phone: cellphone,
            Url: uri_photo,
            Email: Email
        }   
        UpdateProfile(obj);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const CreateGuid = ()=> {  
        const _p8 = (s) => {  
           var p = (Math.random().toString(16)+"000000000").substr(2,8);  
           return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;  
        }  
        return _p8() + _p8(true) + _p8(true) + _p8();  
    }
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    //---------------------------------RENDERS------------------------------------
    
    //console.log('id colegio');
    //console.log(capitalizeFirstLetter("esta es una oración de perfil"));
    ///////////////////------------------Tabla--------------------
    function createData(name, calories, fat, carbs) {
        return { name, calories, fat, carbs };
    }
    const rows = [
        createData('1', 'Edwin', '561615616', 'Activo'),
    ];
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    if(goMain){
        window.reload()
    }

    return (
        <Grid container style={{marginTop:10}} className="scrolling-card height-profile-2">
            <Grid item xs={12} md={3}></Grid>
            <Grid item xs={12} md={6} className={(resultUpdate ? 'hideDiv' : 'showDiv') + " width-profile-2"}> 
                <Card className={classesCard.root} style={{borderRadius:15}} variant="outlined" >
                    <CardContent style={{padding:10}}>                        
                        
                        {/** bloque de data perfil usuario */}
                        <Grid container className="containerDiv width-profile-2" style={{overflowX:'hidden',overflowY:'auto'}}>
                            
                            {/**boton celeste titulo*/}
                            <Grid item xs={12} style={{ marginBottom:0,marginTop:5,height:46}}>                        
                                
                                <ButtonProfileDesign name={capitalizeFirstLetter('Datos de perfil')}/>                                                            
                            </Grid>
                            
                            <Grid item xs={12}>
                                <Grid container className="height-grid-profile22"  >
                                    {/** foto de perfil */}
                                    <Grid item md={6} xs={12} style={{padding:20,display:'flex',alignItems:'center'}}>
                                        <div style={{marginRight:'auto',marginLeft:'auto'}}>
                                            <form encType="multipart/form-data">
                                                <div className="form-group preview">
                                                    {
                                                        uriPhoto !== "" ? (
                                                            uriPhoto !== null ? (
                                                                <img src={uriPhoto} alt='' style={{width:218}} />
                                                            ):(
                                                                <img src={`${process.env.PUBLIC_URL}/images/ico-perfil.png`} alt='' style={{width:218}} />
                                                            )
                                                        ):(
                                                            <img src={`${process.env.PUBLIC_URL}/images/ico-perfil.png`} alt='' style={{width:218}} />
                                                        )
                                                    }
                                                    
                                                </div> 
                                                <div className="form-group">
                                                    <label for="file-upload" className="custom-file-upload">
                                                        Seleccione su foto
                                                    </label>
                                                    <input id="file-upload" type="file" className="form-control file " onChange={e => UploadSingleFile(e)} />
                                                </div>
                                            </form >
                                        </div>
                                    </Grid>
                                        
                                    {/** datos del usuario */}
                                    <Grid item md={6} xs={12} >
                                        <Grid container style={{paddingRight:20,paddingLeft:20,marginTop:10}} >
                                            
                                            {/**apellidos */}
                                            <Grid item xs={12} style={{textAlign:'left',marginBottom:10}}>
                                                <p style={{fontFamily:'Montserrat-Regular',marginBottom:0 }}>
                                                    Apellidos
                                                </p> 
                                                <InputTextOnlyBorder
                                                    label={'Ingrese apellidos'}
                                                    value={lastname}
                                                    disabled={true}
                                                    onChange={handleLastname}
                                                    type={'text'}
                                                    maxLength={20}
                                                />
                                            </Grid>
                                            
                                            {/**nombres */}
                                            <Grid item xs={12} style={{textAlign:'left',marginBottom:10}}>
                                                <p style={{fontFamily:'Montserrat-Regular',marginBottom:0 }}>
                                                    Nombres
                                                </p> 
                                                <InputTextOnlyBorder
                                                    label={'Ingrese nombres'}
                                                    value={name}
                                                    disabled={true}
                                                    onChange={handleName}
                                                    type={'text'}
                                                    maxLength={20}
                                                />
                                            </Grid>
                                            
                                            {/**nro de celular */}
                                            <Grid item xs={12} style={{textAlign:'left',marginBottom:10}}>
                                                <p style={{fontFamily:'Montserrat-Regular',marginBottom:0 }}>
                                                    Nro Celular
                                                </p> 
                                                <InputTextOnlyBorder
                                                    label={'Ingrese celular'}
                                                    value={cellphone}
                                                    error={cellphoneFlagError}
                                                    messageHelperText={cellphoneHelperText}
                                                    onChange={handleCellphone}
                                                    type={'number'}
                                                    maxLength={9}
                                                />
                                            </Grid>

                                            {/**Correo */}
                                            <Grid item xs={12} style={{textAlign:'left',marginBottom:10}}>
                                                <p style={{fontFamily:'Montserrat-Regular',marginBottom:0 }}>
                                                    Email
                                                </p> 
                                                <InputTextOnlyBorder
                                                    label={'Ingrese correo'}
                                                    value={Email}
                                                    error={EmailFlagError}
                                                    messageHelperText={EmailHelperText}
                                                    onChange={handleEmail}
                                                    type={'text'}
                                                    maxLength={128}
                                                />
                                            </Grid>
                                            
                                            {/** colegio */}
                                            <Grid item xs={12} style={{marginTop:'auto',marginBottom:'auto'}}>
                                                <SelectCustom
                                                    widthClass={'all100x'}
                                                    label={"Colegio"}
                                                    flagError={schoolFlagError}
                                                    value={schoolId}
                                                    onChange={onChangeSchool}
                                                    typeList={KeyValue.ACTION_LIST_SCHOOLS}
                                                    list={arrListSchools}
                                                    helperText={schoolHelperText}
                                                    classNameError={"divError2"}
                                                />
                                            </Grid>
                                            {/** grado, en caso sea estudiante */}
                                            {/*
                                                localStorage.getItem(Token.TYPE_USER) === KeyValue.TypeStudent ? (
                                                    <Grid item xs={12} style={{marginTop:'auto',marginBottom:'auto'}}>
                                                        <SelectCustom
                                                            widthClass={'all100x'}
                                                            label={"Grado"}
                                                            flagError={gradeFlagError}
                                                            value={gradeId}
                                                            onChange={onChangeGrade}
                                                            typeList={KeyValue.ACTION_LIST_GRADE}
                                                            list={arrListGrade}
                                                            helperText={gradeHelperText}
                                                        />
                                                    </Grid>
                                                ):('')
                                            */}
                                            {/** boton actualizar datos */}
                                            <Grid item xs={12} style={{textAlign:'center'}}>
                                                <ButtonProfile name={capitalizeFirstLetter('Actualizar datos')} handleClick={handleUpdateProfile} />  
                                            </Grid>

                                            
                                        </Grid>                                                           
                                    </Grid>

                                    <Grid item xs={12}>
                                        {
                                            TypeUser == 'AL'?(
                                                <Grid container className="hideDiv22">
                                                    {/**boton celeste titulo*/}
                                                    <Grid item xs={12} style={{ marginBottom:10,marginTop:10,height:46}}>
                                                        <ButtonProfileDesign name={capitalizeFirstLetter('Libros Ingresados')}/>                                                            
                                                    </Grid>
                                                    <Grid item xs={12} style={{overflowX:'auto'}}>
                                                            <TableContainer component={Paper} >
                                                                <Table className={classes.table, 'table-borde'} aria-label="customized table">
                                                                    <TableHead>
                                                                    <TableRow style={{border:'2px solid #dee2e6'}}>
                                                                        <StyledTableCell align="left">Libro</StyledTableCell>
                                                                        <StyledTableCell align="left">Código de Activación</StyledTableCell>
                                                                        <StyledTableCell align="left" colSpan="2">Código de Validación
                                                                            <Tooltip 
                                                                            title="Código ubicado en el lomo de tu &#13;libro de actividad, Workbook o &#13;Psicomotricidad." 
                                                                            placement="right"
                                                                            style={{fontFamily:'Montserrat-Regular'}}
                                                                            >
                                                                                <InformationIcon style={{color:'#e67474'}} />
                                                                            </Tooltip>
                                                                    </StyledTableCell>
                                                                    </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {
                                                                        ListLibros.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key) =>
                                                                        <StyledTableRow>
                                                                            <StyledTableCell align="left" className="table-border-right">
                                                                            {element.Libro}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell align="left" className="table-border-right">{element.CodigoActivacion}</StyledTableCell>
                                                                            {
                                                                                element.CodigoLomoCorrecto == true ? (
                                                                                    <StyledTableCell align="left" className="table-border-right">
                                                                                        {element.CodigoLomoLibro}
                                                                                    </StyledTableCell>
                                                                                ):(
                                                                                    <StyledTableCell align="left" className="table-border-right" style={{color:'red'}}>
                                                                                        {element.CodigoLomoLibro}
                                                                                    </StyledTableCell>
                                                                                )
                                                                            }
                                                                            {/* <StyledTableCell align="left" className="table-border-right">
                                                                                {
                                                                                    element.CodigoLomoCorrecto == true ? (
                                                                                        <img src={process.env.PUBLIC_URL + '/images/ICO-43.png'} style={{width:30}}/>
                                                                                    ):(
                                                                                        <img src={process.env.PUBLIC_URL + '/images/ICO-41.png'} style={{width:30}}/>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    element.CodigoLomoCorrecto == true ? (
                                                                                        <Button id={element.CodigoActivacion} onClick={(e) => handleClickEdit(e)} style={{minWidth:40}}>
                                                                                            <img src={process.env.PUBLIC_URL + '/images/ICO-42.png'} style={{width:30}}/>
                                                                                        </Button>
                                                                                    ):(
                                                                                        <Button id={element.CodigoActivacion}
                                                                                        onClick={(e) => handleClickEdit(e)} style={{minWidth:40}}>
                                                                                            <Tooltip 
                                                                                                title="Código incorrecto. Clic aquí para actualizar." 
                                                                                                placement="right"
                                                                                                style={{fontFamily:'Montserrat-Regular'}}
                                                                                            >
                                                                                                <img src={process.env.PUBLIC_URL + '/images/ICO-42.png'} style={{width:30}}/>
                                                                                            </Tooltip>
                                                                                        </Button>
                                                                                    )
                                                                                }
                                                                            </StyledTableCell> */}
                                                                        </StyledTableRow>
                                                                        )}
                                                                        {emptyRows > 0 && (
                                                                        <TableRow style={{ height: 1 * emptyRows }}>
                                                                        <TableCell colSpan={6} style={{display:'none'}}/>
                                                                        </TableRow>
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                    
                                                            </TableContainer>
                                                            
                                                    </Grid>
                                                    <Grid>
                                                        <TablePagination
                                                                rowsPerPageOptions={[5, 10, 15]}
                                                                component="div"
                                                                count={ListLibros.length}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page}
                                                                onChangePage={handleChangePage}
                                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                            />
                                                    </Grid>
                                                    {
                                                        false ? (
                                                            <div style={{objectFit:'contain',textAlign:'left'}}>
                                                        <span style={{color:"red"}}>*</span><span style={{fontFamily:'Montserrat-Regular',fontSize:'10pt'}}> Recuerde que para participar del sorteo deben estar todos los campos correctamente completos.</span>
                                                        <Grid item xs={12} style={{textAlign:'center'}}>
                                                        <ButtonSearchBook
                                                                    id={'idBtnParticiple'}
                                                                    name={capitalizeFirstLetter('Participar del sorteo')}
                                                                    style={{display:'inlineBlock !important', textTransform: 'none'}}
                                                                    handleClick = {handleParticipleLotery}
                                                        />  
                                                        </Grid>
                                                            <img className="contain" src={urlFooterPromotion} style={{width:"100%"}} alt="footer" />
                                                            </div>
                                                        ):('')
                                                    }
                                                </Grid>                        
                                            ):(
                                                <Grid></Grid>
                                            )
                                        }
                                    </Grid>
                                             
                                </Grid>
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} className={resultUpdate ? 'showDiv' : 'hideDiv'}> 
                <Card className={classesCard.root} style={{borderRadius:25}} variant="outlined">
                    <CardContent style={{padding:10}} className="containerDiv">
                        
                        {/** bloque de resultado de la actualizacion de perfil */}
                        <Grid container className={resultUpdate ? 'showDiv vertical-center ' : 'hideDiv'}>  
                            <Grid item xs={12} style={{textAlign:'center'}}>
                                <h4 style={{color:'#333333',fontFamily:'Montserrat-SemiBold'}}>
                                    ¡Genial!
                                </h4>               
                            </Grid>
                            <Grid item xs={12} style={{textAlign:'center'}}>
                                <p style={{color:'#808080',fontFamily:'Montserrat-Regular'}}>
                                    Se actualizaron correctamente tus datos.
                                </p>               
                            </Grid>
                            <Grid item  xs={12} style={{textAlign:'center'}}>                            
                                <ButtonSearch
                                    name={'Volver'}
                                    handleClick={props.handleReturnBook}
                                />                                          
                            </Grid>
                        </Grid>
                    
                    </CardContent>
                </Card>
            </Grid>            
            <Grid item xs={12} md={3}></Grid>
            <UnauthorizedDialog open={showUnauthorized} />
            <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
            <AlertDialog open={showParticiple} message={messageErrorParticiple} handleClose={handleCloseParticiple}/>
            <Progress open={loading} />             
            <EditLomoBook open={showActivate} handleClose={handleCloseActivate} id={CodeLomo} init={initOptions}/>
        </Grid>
    )
}

export default Profile
