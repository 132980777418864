import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './Button.css';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const ButtonReturnRegister = (props) => {
  const classes = useStyles();
  return (
    <Button
        id='btnReturnRegister'
        variant="contained" 
        onClick={props.handleClick}
        className={classes.button + " letterCapitalize style-width-responsive-button-register"}
        //style={{width:'unset'}}
    >
      {props.name}
    </Button>
  );
}

export default ButtonReturnRegister