const Token = {
    TOKEN: 'TOKEN',
    REFRESH_TOKEN: 'REFRESH-TOKEN',
    TYPE_USER: 'TipoUsuario',
    NAME_USER: 'sub',
    UNIQUE_NAME: 'unique_name',
    IS_WELCOME: 'is_welcome',
    GIVEN_NAME: 'given_name',
};

export default Token