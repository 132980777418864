import React from 'react'
import KeyValue from '../../apis/KeyValue';
//import DrawerCustomStudent from './DrawerStudent';
import DrawerTeacher from './DrawerTeacher';
import Token from '../../config/Token';
import ToolbarProvider from '../../context/ToolbarProvider';

import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

//ReactGA.initialize('UA-195320645-1');

const DrawerMain = () => {
    //const typeUser = localStorage.getItem(Token.TYPE_USER);
    /*
    React.useEffect(() =>{   
        ReactGA.pageview(window.location.pathname + window.location.search);        
    })
    */
    return (
        <ToolbarProvider> 
         <DrawerTeacher />
            {/*
                typeUser === KeyValue.TypeStudent ? (
                    <DrawerCustomStudent />
                ) : (
                <DrawerTeacher />
                )*/
            }
        </ToolbarProvider>
    )
}

export default DrawerMain
//export default withRouter(DrawerMain)
