import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import ButtonSearch from '../buttons/ButtonSearch';
import InputSearch from '../inputs/InputSearch';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import './Dialog.css';
import Endpoint from '../../apis/Endpoint';
import KeyValue from '../../apis/KeyValue';
import Token from '../../config/Token';
import UrlBase from '../../config/UrlBase';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ButtonAssignSection from '../buttons/ButtonAssignSection';

function TabPanel(props) {

    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
}
  
const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      //width: '100%',
    },
}));

const SectionDialog = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    //const [nameSection,setNameSection] = React.useState('');

    //-------------------------------------------------------------
    const eventListner = React.useCallback(() => {
        //console.log(state); // use the stateful variable in event listener
      }, [props.initSection]);
    React.useEffect(()=>{
        if(props.initSection === 1){
            setValue(0);
        }
    },[eventListner])   

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    //----------------------

    return (
        <div>
            <Dialog 
            open={props.open} 
            onClose={props.handleClose} 
            aria-labelledby="form-dialog-title"
            >
                
                    <div className={classes.root + " width-responsive-section"}>
                        <AppBar position="static" color="default">
                            <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            >
                                <Tab label="Crear Sección" {...a11yProps(0)} />
                                <Tab label="Asignar a Sección Existente" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction} >                            
                                <Grid container style={{minHeight:86}}>
                                    <Grid item xs={12} >
                                        <p style={{marginBottom:5,fontFamily:'Montserrat-Regular'}}> Crea un nombre para la sección</p>
                                    </Grid>
                                    <Grid item md={6} xs={12} >
                                        <InputSearch
                                            label={'Ingrese nombre'}
                                            value={props.nameSection}
                                            onChange={props.handleNameSection}
                                            className={" padding-input-txt"}
                                            error={props.flagNameErrorSection}
                                            type={'text'}
                                        />
                                                                                                            
                                    </Grid>
                                    <Grid item md={6}  xs={12} style={{textAlign:'center'}}>
                                        <ButtonSearch
                                            name={'Crear Sección'}
                                            className=" padding-input-txt"
                                            handleClick={props.handleCreateSection}
                                        />  
                                    </Grid> 
                                    <Grid item xs={12} style={{textAlign:'left'}}>
                                        <div className={(props.flagNameErrorSection ? 'showDiv': 'hideDiv') + " divError55"}>
                                            <p
                                                className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
                                                style={{marginBottom:0,color:'#f44336',fontSize:'0.75rem',marginLeft:18,fontFamily:'Montserrat-Regular'}}>{props.nameSectionHelperText}</p>
                                        </div>    
                                    </Grid> 
                                
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <Grid container style={{minHeight:86}}>
                                
                                    <Grid item xs={12}>
                                        <p style={{marginBottom:5,fontFamily:'Montserrat-Regular'}}>Elije una sección</p>
                                    </Grid>

                                    <Grid item md={6} xs={12}>                                                                      
                                        <select 
                                            className="form-control padding-input-txt margin-button-style"
                                            onChange={props.onChangeSection}
                                            value={props.valueSection}
                                            style={{width:'100%'}}
                                        >
                                            <option key='24' value="0" >Seleccione...</option>
                                            {
                                                props.listSections.map((el,key) =>
                                                <option key={el.IdSeccion} value={el.IdSeccion} >{el.NombreSeccion}</option>
                                                )
                                            }
                                        </select>                                       
                                    </Grid>
                                    <Grid item md={6} xs={12} style={{textAlign:'center'}}>
                                        <ButtonAssignSection
                                            name={'Asignar sección'}
                                            //className={"margin-button-style"}
                                            handleClick={props.assignSection}
                                        />  
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{textAlign:'left'}} className={(props.flagSelectErrorSection ? 'showDiv': 'hideDiv') + " divError55"}>
                                        <p
                                                className="MuiFormHelperText-root divError55 MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
                                                style={{marginBottom:0,color:'#f44336',fontSize:'0.75rem',marginLeft:18,fontFamily:'Montserrat-Regular'}}>{props.selectSectionHelperText}</p>
                                        {/*
                                            <div className={(props.flagSelectErrorSection ? 'showDiv': 'hideDiv') + " divError55"}>
                                            <p
                                                className="MuiFormHelperText-root divError55 MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
                                                style={{marginBottom:0,color:'#f44336',fontSize:'0.75rem',marginLeft:18,fontFamily:'Montserrat-Regular'}}>{props.selectSectionHelperText}</p>
                                        </div>
                                        */}
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </SwipeableViews>
                    </div>
                    
                
            </Dialog>                
        </div>
    )
}

export default SectionDialog
