/** desarrollo */
/*
const UrlBase = {
    URL_BASE_SECURITY_SERVICE: 'http://3.14.236.75/api/',
    URL_BASE_PORTAL_SERVICE: 'http://3.14.236.75:81/api/',
    URL_BASE_FILES_SERVICE: 'http://3.14.236.75:82/api/',
};
*/
/**produccion */

const UrlBase_Prod = {
    URL_BASE_SECURITY_SERVICE: 'https://apiservice.entornodigitalbh.com/api/', //URL_BASE: 'http://3.14.83.212/api/',
    URL_BASE_PORTAL_SERVICE: 'https://apiservice81.entornodigitalbh.com/api/', ///URL_BASE_PORT_1 : 'http://3.14.83.212:81/api/'
    URL_BASE_FILES_SERVICE: 'https://apiservice82.entornodigitalbh.com/api/'
};

export default UrlBase_Prod