import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import './font/caviarDreams/CaviarDreams_Bold.ttf';
import './font/CaviarDreams.ttf';
import './font/monserrat/Montserrat-Black.ttf';
import './font/monserrat/Montserrat-Bold.ttf';
import './font/monserrat/Montserrat-Regular.ttf';
import './font/monserrat/Montserrat-SemiBold.ttf';
import App from './App';
import reportWebVitals from './reportWebVitals';
import RouteChangeTracker from './RouteChangeTracker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
