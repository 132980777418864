import React from 'react'
import { Redirect } from 'react-router'
import ButtonSearch from '../buttons/ButtonSearch';
import { Grid } from '@material-ui/core';
import ButtonReturnRegister from '../buttons/ButtonReturnRegister';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import ReactGA from 'react-ga';
import ConstantKey from '../../config/ConstantKey';

const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },    
  });

const WelcomeUser = () => {
    const classes = useStyles();
    const [goLogin, setGoLogin] = React.useState(false);

    React.useEffect(() =>{   
        ReactGA.initialize(ConstantKey.REACT_GA_TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);      
      },[])

    const handleReturnLogin = () => {
        setGoLogin(true)
    }

    if(goLogin){
        return <Redirect to="/login" />
    }

    return (
        <div>
            <img 
                src={process.env.PUBLIC_URL + '/images/logo_SD_new.png'} 
                className="widthLogoStoDomingo"
                style={{marginLeft:'unset'}}
                alt="login"
            />  
            <div className="imageFull5">
            <Card className={classes.root  + " style-width-card-1  style-mTop-welcome-card"} id="cardWelcome" /*style={{marginTop:180}}*/>
                <CardContent>
                    <Grid container   >
                        <Grid item xs={12} style={{textAlign:'center'}}>
                            <h4 style={{color:'#333333',fontFamily:'Montserrat-SemiBold'}}>
                                ¡Felicidades!
                            </h4>               
                        </Grid>
                        <Grid item xs={12} style={{textAlign:'center'}}>
                            <p style={{color:'#808080',fontFamily:'Montserrat-Regular'}}>
                                Revise su correo electrónico para la confirmacion de su usuario. Si no logra encontrar el mensaje es posible que haya sido enviado a la carpeta SPAM o similar.
                            </p>               
                        </Grid>
                        <Grid item  xs={6} md={6} style={{textAlign:'center'}}>                            
                            <ButtonReturnRegister
                                name={'Volver'}
                                handleClick={handleReturnLogin}
                            />                                          
                        </Grid>
                        <Grid item  xs={6} md={6} style={{textAlign:'center'}}> 
                            <ButtonSearch
                                id={'idBtnIngresar'}
                                name={'Ingresar'}
                                handleClick={handleReturnLogin}                            
                            />                                        
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
                                    
                
            </div>
        </div>
    )
}

export default WelcomeUser
