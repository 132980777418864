import React from 'react'
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
import './Inputs.css';

const TextFieldCustom = withStyles({
    root: {
        '& label': {
            color: '#212222',
        },
        '& label.Mui-focused': {
            color: '#212222',
        },
        '& .MuiInput-underline': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
            fontFamily:'Montserrat-Medium !important',
        },
        '& .MuiInput-underline:before': {
            borderColor: 'rgba(0, 0, 0, 0.12)',
            fontFamily:'Montserrat-Medium !important',
        },
        '& .MuiInput-underline:after': {
            borderColor: '#rgba(0, 0, 0, 0.12)',
            fontFamily:'Montserrat-Medium !important',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.12)',
              borderRadius:18,
        },
        '&:hover fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.12)',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.12)',
        }
      },
    },
})(TextField);

const InputCodeValidate = (props) => {

  return (
    <TextFieldCustom 
        id="searchDNI"
        type={props.type}
        placeholder={props.label}
        //label={props.label}
        //rowsMax={1}
        //inputProps={{ maxLength: 2 }}
        error={props.error}
        helperText={props.helperText}
        value={props.value}
        onChange={props.onChange}
        //onKeyPress={props.onKeyPress}
        className={props.className + " widthCustom2"}
        variant="outlined"
        autoComplete="off"
        size="small"
        onKeyPress= {(e) =>{
          if (props.type == "text"){
            if (!((e.charCode >= 65 && e.charCode <= 90) || (e.charCode >= 97 && e.charCode <= 122))) {
              e.preventDefault()
            }
          }
        }}
        onInput = {(e) =>{
            e.target.value = e.target.value.toString().slice(0,props.maxLength)
          }
        }
    /> 
  );
}
export default InputCodeValidate
