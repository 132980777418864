import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import './Button.css';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const ButtonAdd = (props) => {
  const classes = useStyles();

  return (      
    <Button
      variant="contained"
      //color="default"
      onClick = {props.onClick}
      className={classes.button + " colorButtonApp letterCapitalize "}
      endIcon={<img src={process.env.PUBLIC_URL + "/images/add_book.png"} style={{width:27}} />}      
    >
      Agregar
    </Button>
  )
}

export default ButtonAdd
