import React from 'react';
import InputTextArea from '../inputs/InputTextArea';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import ButtonSearch from '../buttons/ButtonSearch';
import InputSearch from '../inputs/InputSearch';
import './Dialog.css';

const NotificationDialog = (props) => {
    
    const eventListener = React.useCallback(()=>{
    },[props.flag,props.initNotification])  
   
    React.useEffect(() => {
        if(props.initNotification){
            
        }
    },[eventListener])

    return (
        <div id="idNotification">
            <Dialog  open={props.open} onClose={props.handleClose} aria-labelledby="id-dialog-notification">
                <DialogTitle 
                    id="id-dialog-notification"
                    style={{textAlign:'center'}}
                >
                    {props.flag? '¡Enhorabuena!':'Nueva Notificación'}
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} > 
                            <p 
                                style={{marginBottom:5,fontFamily:'Montserrat-Regular'}}
                                className={props.flag? 'hideDiv':'showDiv'}
                                >Pon un título a tu notificación:</p>
                        </Grid>
                        <Grid item xs={12} className={props.flag? 'hideDiv':'showDiv'}>
                            <InputSearch
                                label={'Agrega un título...'}
                                onChange={props.onChangeTitle}
                                value={props.valueTitle}
                                error={props.titleFlagError}
                                helperText={props.titleHelperText}
                                type={'text'}
                            />                                 
                        </Grid>
                        <Grid item xs={12} style={{marginTop:15}} className={props.flag? 'hideDiv':'showDiv'}>
                            <p style={{marginBottom:5,fontFamily:'Montserrat-Regular'}}>Describe tu notificación:</p>
                        </Grid>
                        <Grid item xs={12} className={props.flag? 'hideDiv':'showDiv'}>
                            <InputTextArea 
                                placeholder="Agrega una descripción..."
                                value={props.valueDetail}
                                onChange={props.onChangeDetail}
                                flagError={props.detailFlagError}
                                helperText={props.detailHelperText}
                            />
                        </Grid>
                        <Grid item xs={12}className={props.flag? 'showDiv':'hideDiv'} style={{textAlign:'center'}}>
                            <p>Tu notificación fue enviada correctamente.</p>
                        </Grid>
                        <Grid item xs={12} style={{textAlign:'center'}}>
                            <ButtonSearch
                                name={props.flag? 'Cerrar':'Enviar notificación'}
                                handleClick={props.flag ? props.handleClose : props.sendNotification}
                            /> 
                        </Grid>                        
                    </Grid>            
                </DialogContent>
            </Dialog>    
        </div>
        
    )
}

export default NotificationDialog
