import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});



const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PromotionDialogs(props) {
  //const classes = styles();
/*
      const [open, setOpen] = React.useState(false);

      const handleClickOpen = () => {
      setOpen(true);
      };
      const handleClose = () => {
      setOpen(false);
      };*/
      

      const handleRedirectPromotion = () =>{
        console.log("redireccionar")
      }

      return (
            <div>
                  <Dialog 
                        onClose={props.handleClose} 
                        aria-labelledby="customized-dialog-title" 
                        open={props.open}
                  >
                    {/** 
                        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                              13323
                        </DialogTitle>*/}
                          <IconButton className="closePromotion" onClick={props.handleClose}>
                            <CloseIcon />
                          </IconButton>
                          <img 
                            src={props.promotion !== null && props.promotion.UrlPromotion} 
                            alt="image"
                            style={{cursor:'pointer'}}
                            onClick={props.handleRedirectToProfile}
                          />
                        
                        {/***
                        <DialogContent id="customized-dialog-title" onClose={props.handleClose}>
                              <Typography gutterBottom>
                                    {props.promotion !== null && props.promotion.UrlPromotion}
                              </Typography>
                              <Typography gutterBottom>
                                    {props.promotion !== null &&props.promotion.UrlFooterPromotion}
                              </Typography>
                              <Typography gutterBottom>
                                    {props.promotion !== null &&props.promotion.StatusPromotion}
                              </Typography>
                        </DialogContent> */}
                  </Dialog>
            </div>
      );
}
