import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import './MyBooks.css';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '80%',
    marginLeft:'auto',
    marginRight: 'auto',
  },
  media: {
    height: 0,
    paddingTop: '141.25%',//'56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function Book(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <div className="contentDivImgBook" style={{alignItems: 'center'}}>
        <div>
          <img
            className={"img-portada-book boxCustomImg"}
            src={props.detail.UrlPortada}
            title={props.detail.Titulo}
          />
          <p style={{marginBottom:0,fontFamily:'Montserrat-SemiBold',fontSize:'12pt'}}>{props.detail.Titulo}</p>
          <p style={{fontFamily:'Montserrat-Regular',fontSize:'10pt'}}>{`${props.detail.NombreCurso} - ${props.detail.NombreNivel}`}</p>
        </div> 
      </div>
    </div>    
  );
}
