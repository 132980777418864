import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './Button.css';
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
})); 
const ButtonSearch = (props) => {
  const classes = useStyles();

  return (    
        <Button
            id={props.id}
            variant="contained" 
            onClick={props.handleClick}
            className={classes.button + " " +props.className+ " colorButtonApp letterCapitalize style-width-responsive-button-continue-1"}
        >
        {props.name}
      </Button>
  );
}

export default ButtonSearch
